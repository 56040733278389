<div class="container_toolbar" nz-row nzJustify="space-between" nzAlign="middle">
  <app-tabs
    #tabs
    (changeTab)="onChangeTab(tabs.id)"
    [listTab]="listTab"
    nz-col
    nzXs="24"
    nzMd="12"
  ></app-tabs>
  <div class="item_right" nz-col nzXs="24" nzMd="12">
    <app-input-search></app-input-search>

    <ng-container *ngIf="isBtnResponsiveMobile; else btnResponsiveLaptop">
      <button *ngIf="tabs.id === 1" class="btn-icon" nz-button (click)="showDeActivation()">
        <i nz-icon nzType="user-delete" nzTheme="outline"></i>
      </button>
      <button *ngIf="tabs.id === 2" class="btn-icon" nz-button (click)="showActivation()">
        <span nz-icon nzType="user-add" nzTheme="outline"></span>
      </button>
    </ng-container>
  </div>
</div>
<!-- <app-list-filter *ngIf="isShowListFilter"></app-list-filter> -->
<app-activation-user (emitReloadData)="acceptData()"></app-activation-user>
<app-de-activate-user (emitReloadData)="acceptData()"></app-de-activate-user>

<ng-template #btnResponsiveLaptop>
  <ng-container>
    <button *ngIf="tabs.id === 1" class="btn_deactive" nz-button (click)="showDeActivation()">
      <i nz-icon nzType="user-delete" nzTheme="outline"></i>
      Deactivate User
    </button>
    <button *ngIf="tabs.id === 2" class="btn_deactive" nz-button (click)="showActivation()">
      <span nz-icon nzType="user-add" nzTheme="outline"></span>
      Activate User
    </button>
  </ng-container>
</ng-template>
