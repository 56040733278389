import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { URL_PAGE } from '../../commons/consts'
import { TITLE_PAGE } from '../../commons/enums/common.enum'
import { StorageKey } from '../../commons/enums/storage-key.enum'
import { AuthServices } from '../../commons/services/authen/auth.service'
import { SessionStorageService } from '../../commons/services/session/session-storage.service'
import { ModalConfirmComponent } from '../../shared/components/modal-confirm/modal-confirm.component'

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit {
  isCollapsed = false
  width_sidebar = 256
  visibleProfile = false
  companyName!: string
  userName!: string
  URL_PAGE = URL_PAGE
  TITLE_PAGE = TITLE_PAGE
  userDetail = {
    firstName: 'John',
    lastName: 'John',
    username: 'John khang',
    email: 'john.khang@gmail.com',
    phoneNumber: '123-456-2'
  }
  domainTenant!: string

  @ViewChild(ModalConfirmComponent) modalConfirm!: ModalConfirmComponent
  constructor(
    public authService: AuthServices,
    private sessionStorage: SessionStorageService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.companyName = JSON.parse(this.sessionStorage.getDataUser() as string)?.companyName
    this.userName = JSON.parse(this.sessionStorage.getDataUser() as string)?.username
    this.domainTenant = JSON.parse(this.sessionStorage.getDataUser() as string)?.tenantId
  }

  logOutUser() {
    this.authService.logout()
    this.router.navigateByUrl(URL_PAGE.LOGIN)
  }

  showProfileTenant() {
    this.visibleProfile = true
  }

  goEditUser = () => {
    // const id = this.userDetail.id
    // this.router.navigateByUrl(`${URL_PAGE.ACCOUNT_MANAGEMENT}/${URL_PAGE.EDIT_ACCOUNT}/${id}`)
    // this.generalService.addSelectedData(id, [this.userDetail])
    this.sessionStorage.set(StorageKey.EDIT_ACCOUNT, true)
  }
}
