import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { BillingInvoiceService } from '../../../api/billing-invoice.service'
import { PaymentService } from '../../../api/payment.service'
import { GeneralService } from '../../../commons/services/general.service'
import { TableService } from '../../../commons/services/table.service'
import { DATE_FORMAT } from '../../../configs/constants'
import { ResponsePayment } from '../../../interfaces/subscription'
import { TemplatePdfComponent } from '../../../shared/components/template-pdf/template-pdf.component'
import { numberNoComma } from '../../../utils/format'
import { generateSTT } from '../../../utils/function'
import { PAYMENT_STATUS, TITLE_PAGE } from './../../../commons/enums/common.enum'
import { HttpObservablesService } from './../../../commons/services/connection/http-observables.service'
@Component({
  selector: 'app-billing-invoice',
  templateUrl: './billing-invoice.component.html',
  styleUrls: ['./billing-invoice.component.scss']
})
export class BillingInvoiceComponent implements OnInit, OnDestroy {
  @ViewChild(TemplatePdfComponent) templatePdfComp!: TemplatePdfComponent
  public formatDate = DATE_FORMAT.dd_MM_yyyy
  public numberNoComma = numberNoComma
  public TITLE_PAGE = TITLE_PAGE
  public visibleTemplatePDF = false
  public PAYMENT_STATUS = PAYMENT_STATUS
  public visibleSendInvoice = false
  public visibleInvoiceDetail = false
  public isLoadingBtn = false
  public listOfDisplayData: any[] = []
  public listOfColumn: any = [
    {
      title: 'Package'
    },
    {
      title: 'Invoice Number',
      align: 'right'
    },
    {
      title: 'Date',
      align: 'right'
    },
    {
      title: 'User',
      align: 'right'
    },
    {
      title: 'Amount',
      align: 'right'
    }
  ]
  constructor(
    public general: GeneralService,
    public tableService: TableService<ResponsePayment>,
    private httpObservable: HttpObservablesService,
    private paymentService: PaymentService,
    private billingInvoiceService: BillingInvoiceService
  ) {}
  ngOnDestroy(): void {
    this.tableService.clearAllChecked()
  }

  ngOnInit(): void {
    this.tableService.loading = false
    this.getListInvoice()
  }

  getListInvoice() {
    this.paymentService.paymentListStatus(0, 100, PAYMENT_STATUS.SUCCESS).subscribe(
      (res) => {
        this.tableService.loading = false
        if (res?.data.length > 0) {
          this.tableService.listOfData = res?.data.map((item: any, key: number) => {
            return {
              ...item,
              stt: generateSTT(key + 1)
            }
          })
          this.listOfDisplayData = [...this.tableService.listOfData]
        }
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.BILLING_INVOICING
        })
      }
    )
  }

  exportInvoice() {
    this.isLoadingBtn = true
    const paymentIds = Array.from(this.tableService.setOfCheckedId)
    this.billingInvoiceService.exportInvoice(paymentIds).subscribe((res) => {
      this.general.dataPDF = res
      this.general.generatePDF({
        element: this.templatePdfComp.htmlData.nativeElement,
        isSavePdf: true,
        callbackFn: () => (this.isLoadingBtn = false)
      })
    })
  }

  showPaymentDetail(item: ResponsePayment) {
    this.visibleInvoiceDetail = true
    this.general.selectedItem.next(item)
  }
}
