import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import APIs from '../commons/consts/API'
import { CommonService } from '../commons/services/connection/common.service'
import { HttpObservablesService } from '../commons/services/connection/http-observables.service'
import { environment } from '../environments/environment'
import { RequestAccount, RequestAccountStatus, StatusAccount } from '../interfaces/user-manage'

const SERVER_URL = environment.API_WS_SERVER_URL
@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  // moduleService: any;
  constructor(
    protected httpObservablesService: HttpObservablesService,
    protected commonService: CommonService,
    private http: HttpClient
  ) {}

  getListUser(offset: number, limit: number, status?: StatusAccount): Observable<any> {
    return this.http.get(
      SERVER_URL +
        APIs.USERS +
        `?limit=${limit}&offset=${offset}` +
        `&status=${status ? status : ''}`
    )
  }

  getUserDetail(id: string): Observable<any> {
    return this.http.get(SERVER_URL + APIs.USERS + `/${id}`)
  }

  createUser(body: RequestAccount) {
    let url = SERVER_URL + APIs.USERS
    return this.http.post(url, body)
  }

  editUser(body: RequestAccount, accountId: string) {
    let url = SERVER_URL + APIs.USERS + `/${accountId}`
    return this.http.put(url, body)
  }

  updateStatusAccount(body: RequestAccountStatus) {
    let url = SERVER_URL + APIs.USERS + '/status'
    return this.http.put(url, body)
  }
  checkAccountAmount(email: string) {
    let url = SERVER_URL + APIs.USERS + `/check-account-amount?email=${email}`
    return this.http.post(url, {})
  }

  requestChangePassword(email: string): Observable<any> {
    return this.http.get(SERVER_URL + APIs.REQUEST_CHANGE_PASSWORD_ACCOUNT + `?email=${email}`)
  }

  getUserDetailLogin(): Observable<any> {
    return this.http.get(SERVER_URL + APIs.USERSLOGIN)
  }
}
