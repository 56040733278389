<div class="container-title">
  <i class="icon-arrow-right" (click)="handleCancel()">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581"
        stroke="#252937"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.91699 10H16.942"
        stroke="#252937"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </i>
  <h2 class="title">{{ title }}</h2>
</div>
