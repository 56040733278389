import { isArray } from 'lodash'
import * as moment from 'moment'
import { LocaleFormat } from '../configs/localFormat'
import { Regexs } from '../configs/regexs'

export const formatMoney = (value: number): string => {
  return `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\./g, '')
}
export const parserMoney = (value: string): string => {
  return value?.replace(/\$\s?|(,*)/g, '')
}
export const formatMoneyWithDecimal = (value: number): string => {
  return `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const formatNumber = (value: number): string => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatNumber2 = (value: number): string => {
  return `${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatLocalePhone = (value: string): string => {
  let phone = value.replace(Regexs.UNFORMAT_PHONE_NUMBER, '')
  if (phone[0] === '0') {
    phone = phone.substr(1)
    phone = LocaleFormat.PHONE_NUMBER_PREFIX_VI + phone
  } else {
    phone = LocaleFormat.PHONE_NUMBER_PREFIX_US + phone
  }
  return phone
}
export const createUUID = () => {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

export function currencyFormat(data: string) {
  return data.replace(Regexs.ONLY_COMMAS, '').replace('$', '')
}

export var currencyUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})
export function currencyUSDFormat(data: string | number) {
  if (typeof data === 'string') data = parseFloat(data)
  return currencyUSD.format(data)
}
export function dateFormat(date: any, format: string) {
  return moment(date).format(format)
}
export function getCurrencyStringNumberZero(value: any) {
  const parseValue = parseFloat(value)
  if (typeof parseValue == 'number') {
    let num = parseValue.toFixed(2)
    if (parseFloat(num)) {
      if (!Number.isInteger(parseValue)) return `${num.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      return `${parseInt(parseValue.toString())
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
  }
  return ''
}
export function getCurrencyNumberFromString(value: string) {
  if (value) {
    value = value.replace(/[^\d.-]/g, '')
    return parseFloat(value)
  }
  return 0
}

export function setCharAt(str: string, index: number, chr: string) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}
export const formatAmount = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  }).format(amount)
}

export function formatAmountWithOutUnit(
  amount: number,
  fractionDigits = 2,
  decimalSeparator = '.'
): string {
  return new Intl.NumberFormat(decimalSeparator === '.' ? 'en-US' : 'de-DE', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  }).format(amount)
}

export const formatCryptoAmount = (value: number, unit = '') => {
  let str = unit != '' ? unit : ''
  if (value >= 1000000000)
    str = str.concat(formatAmountWithOutUnit(value / 1000000000).concat(' B'))
  else if (value >= 1000000) str = str.concat(formatAmountWithOutUnit(value / 1000000).concat(' M'))
  else str = str.concat(formatAmountWithOutUnit(value))
  return str
}

export function getDifferenceInDays(date1: any, date2: any) {
  const diffInMs = Math.abs(date2 - date1)
  return diffInMs / (1000 * 60 * 60 * 24)
}

export const formatters = {
  default: new Intl.NumberFormat(),
  currency: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }),
  whole: new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }),
  oneDecimal: new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 8,
    maximumFractionDigits: 8
  }),
  twoDecimal: new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }),
  eightDecimal: new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 8,
    maximumFractionDigits: 8
  })
}

export const mappingObjectToArray = (data: any, config: any) => {
  const newArrays: any = []
  data &&
    data.filter((item: any, index: number) => {
      if (config.length > 0) {
        config.map((con: any) => {
          if (con.currency === item.currency) {
            const newArr = {
              name: con.name,
              images: con.image,
              ...item
            }
            data[index] = newArr
            newArrays.push(data[index])
          }
        })
      }
    })
  return newArrays
}

export const removeArrayDuplicate = (newArray: any) => {
  var dups: any[] = []
  var arr = newArray.filter(function (el: any) {
    // If it is not a duplicate, return true
    if (dups.indexOf(el.symbol) == -1 && dups.indexOf(el.name) == -1) {
      dups.push(el.symbol)
      dups.push(el.name)
      return true
    }
    return false
  })
  return arr
}

export const disabledDateInPast = (current: any) => {
  // Can not select days before today and today
  return current && current < moment().add(-1, 'days').endOf('day')
}

export const disabledDateFuture = (current: any) => {
  // Can not select days before today and today
  return current && current >= moment().endOf('day')
}

export const isExistVietnamese = (str: string) => {
  let str2 = `${str}`
  str2 = str2.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str2 = str2.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str2 = str2.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str2 = str2.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str2 = str2.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str2 = str2.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str2 = str2.replace(/đ/g, 'd')
  str2 = str2.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str2 = str2.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str2 = str2.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str2 = str2.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str2 = str2.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str2 = str2.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str2 = str2.replace(/Đ/g, 'D')
  return str !== str2
}

export const checkFormatNumber = (value: any) => {
  const valueNumber = Number(value) || 0
  const absNumber = Math.abs(valueNumber)
  if (absNumber === 0) return '$0.00'

  if (absNumber < 0.000001 || absNumber < -0.000001) return `$${valueNumber?.toFixed(9)}`

  if (absNumber > 0.000001 && absNumber < 0.01) return `$${parseFloat(valueNumber?.toFixed(7))}`

  return formatAmount(valueNumber) || 0
}

export const numberNoComma = (value: number | string) => {
  return Number(value)
    .toString()
    .match(/.{1,4}/g)
    ?.join(' ')
}
