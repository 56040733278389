import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../app/ng-zorro-antd.module'
import { DetailTicketComponent } from '../../features/admin/ticket/detail-ticket/detail-ticket.component'
import { TicketComponent } from '../../features/admin/ticket/ticket.component'
import { SharedModule } from '../../shared/shared.module'
import { AdminRoutingModule } from './admin-routing.module'
import { AdminComponent } from './admin.component'

@NgModule({
  declarations: [AdminComponent, TicketComponent, DetailTicketComponent],
  imports: [
    AdminRoutingModule,
    HttpClientModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AdminComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule {}
