import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { UserManagementService } from '../../../api/user-management.service'
import { URL_PAGE } from '../../../commons/consts'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../commons/enums/common.enum'
import { StorageKey } from '../../../commons/enums/storage-key.enum'
import { GeneralService } from '../../../commons/services/general.service'
import { TableService } from '../../../commons/services/table.service'
import { DATE_FORMAT } from '../../../configs/constants'
import { ResponseAccount, StatusAccount } from '../../../interfaces/user-manage'
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component'
import { dateFormat } from '../../../utils/format'
import { generateSTT } from '../../../utils/function'
import { HttpObservablesService } from './../../../commons/services/connection/http-observables.service'
import { StateService } from './../../../commons/services/state.service'
import { InviteMemberComponent } from './invite-member/invite-member.component'
@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.scss']
})
export class UserManageComponent implements OnInit {
  dd_MM_yyyy = DATE_FORMAT.dd_MM_yyyy
  public visibleUserProfile = false
  public date: null | Date = null
  public visibleCustomFilter = false
  public tabId = 1
  public listOfDisplayData: any[] = []
  public countActive: number = 0
  public countDeactive: number = 0
  public listOfColumn: any = [
    {
      title: 'User Name',
      sorter: (a: ResponseAccount, b: ResponseAccount) => a.username.localeCompare(b.username)
    },
    {
      title: 'Role',
      sorter: (a: ResponseAccount, b: ResponseAccount) =>
        a.roles[0].name.localeCompare(b.roles[0].name),
      filterFn: (list: string[], item: ResponseAccount) =>
        list.some((name) => item.roles[0].name.indexOf(name) !== -1),
      listOfFilter: [
        {
          text: 'Site Admin',
          value: 'Site Admin'
        },
        {
          text: 'Product Admin',
          value: 'Product Admin'
        },
        {
          text: 'Member',
          value: 'Member'
        }
      ]
    },
    {
      title: 'Email',
      sorter: (a: ResponseAccount, b: ResponseAccount) => a.email.localeCompare(b.email)
    }
  ]

  @ViewChild(InviteMemberComponent) inviteMemberComp!: InviteMemberComponent
  @ViewChild(ModalConfirmComponent)
  modalConfirm!: ModalConfirmComponent

  constructor(
    private userManagement: UserManagementService,
    public general: GeneralService,
    private router: Router,
    private stateService: StateService,
    public tableService: TableService<ResponseAccount>,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    // this.tableService.listOfData = new Array(50).fill(0).map((item, key: number) => {
    //   return {
    //     stt: key,
    //     id: `${key}`,
    //     firstName: 'firstname' + key,
    //     lastName: 'lastname' + key,
    //     username: 'user' + key,
    //     password: '2312sadad',
    //     phoneNumber: '',
    //     location: '',
    //     tenantId: 'innotech',
    //     email: 'user@example.com',
    //     roles: [{ id: 1, name: key % 2 ? 'Site Admin' : 'Member Admin' }],
    //     createdAt: key % 2 ? '2022-12-15' : '2022-12-14',
    //     status: key % 2 ? StatusAccount.ACTIVE : StatusAccount.DEACTIVE
    //   }
    // })
    // this.listOfDisplayData = [...this.tableService.listOfData]
    this.getListAccountWithTabs(1)
    this.general.selectedData = []
  }
  acceptData() {
    this.getListAccountWithTabs(this.tabId)
  }
  resetFilterDate(): void {
    this.date = null
    this.filterDate()
  }
  onChangeDate(result: Date | null) {
    this.date = result
  }
  filterDate() {
    const formatDate = dateFormat(this.date, DATE_FORMAT.DD_MM_YYYY)
    if (this.date !== null) {
      this.listOfDisplayData = this.tableService.listOfData.filter((item) => {
        const formatDateItem = dateFormat(item.createdAt, DATE_FORMAT.DD_MM_YYYY)
        return formatDateItem.indexOf(formatDate) !== -1
      })
    } else {
      this.listOfDisplayData = this.tableService.listOfData
    }

    this.visibleCustomFilter = false
  }

  getListAccountWithTabs(tabId: number) {
    this.tabId = tabId
    this.listOfDisplayData = []
    this.tableService.loading = true
    this.tableService.clearAllChecked()
    if (tabId === 1) {
      // this.listOfDisplayData = this.tableService.listOfData.filter(
      //   (item) => item.status === StatusAccount.ACTIVE
      // )
      this.getListAccount(StatusAccount.ACTIVE)
    } else {
      // this.listOfDisplayData = this.tableService.listOfData.filter(
      //   (item) => item.status === StatusAccount.DEACTIVE
      // )
      this.getListAccount(StatusAccount.DEACTIVE)
    }
  }

  getListAccount(status: StatusAccount) {
    return this.userManagement
      .getListUser(this.tableService.pageIndex, this.tableService.pageSize, status)
      .subscribe((res: any) => {
        this.tableService.loading = false
        this.tableService.totalData = res.total
        // this.tableService.pageIndex = res.pageIndex
        // this.tableService.pageSize = res.pageSize
        this.countActive = res.activeNumber
        this.countDeactive = res.deactiveNumber

        if (res?.data.length > 0) {
          this.tableService.listOfData = res?.data?.map((item: ResponseAccount, key: number) => {
            return {
              ...item,
              stt: generateSTT(key + 1),
              role: item?.roles?.length > 0 ? item?.roles[0]?.name : ''
            }
          })
          this.listOfDisplayData = [...this.tableService.listOfData]
        }
      })
  }

  showDrawerInvite() {
    this.inviteMemberComp.visible = true
  }

  showUserProfile(id: string) {
    this.visibleUserProfile = true
    this.tableService.clearAllChecked()
    this.general.addSelectedData(id, this.tableService.listOfData)
  }

  showEditUser(id: string) {
    this.router.navigateByUrl(`${URL_PAGE.ACCOUNT_MANAGEMENT}/${URL_PAGE.EDIT_ACCOUNT}/${id}`)
  }

  resetPassword(email: string) {
    this.userManagement.requestChangePassword(email).subscribe(
      () => {
        this.httpObservable.handleSuccess({
          messageSuccess: MESSAGE_SUCCESS.REQUEST_CHANGE_PASSWORD,
          title: TITLE_PAGE.RESET_PASSWORD_ACCOUNT
        })
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.RESET_PASSWORD_ACCOUNT
        })
      }
    )
  }

  goAddAccount() {
    const email: string = this.stateService.getState(StorageKey.USER_KEY).email
    this.userManagement.checkAccountAmount(email).subscribe(
      () => {
        this.router.navigateByUrl(`${URL_PAGE.ACCOUNT_MANAGEMENT}/${URL_PAGE.ADD_ACCOUNT}`)
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.SUBSCRIPTION })
      }
    )
  }
}
