import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { DATE_FORMAT } from '../../../configs/constants'

@Component({
  selector: 'app-template-pdf',
  templateUrl: './template-pdf.component.html',
  styleUrls: ['./template-pdf.component.scss']
})
export class TemplatePdfComponent implements OnInit {
  dd_MM_yyyy = DATE_FORMAT.dd_MM_yyyy
  @ViewChild('htmlData') htmlData!: ElementRef
  @Input() data: any[] = []
  constructor() {}

  ngOnInit(): void {}
}
