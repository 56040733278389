<div
  class="container_header"
  nz-row
  nzJustify="space-between"
  nzAlign="middle"
  [nzGutter]="[0, 16]"
>
  <app-heading-page
    nz-col
    nzXs="24"
    nzMd="12"
    title="All User"
    subtitle="Manage all of user in your company efficiently."
  ></app-heading-page>
  <div class="container_right" nz-col nzXs="24" nzMd="12">
    <button nz-button nzType="primary" class="btn" (click)="goAddAccount()">
      <span nz-icon nzType="plus" nzTheme="outline"></span>Add New User
    </button>
  </div>
</div>
<app-toolbar
  #toolbar
  (changeTab)="getListAccountWithTabs(toolbar.tabId)"
  [countActive]="countActive"
  [countDeactive]="countDeactive"
  (emitReloadData)="acceptData()"
></app-toolbar>
<!-- <div nz-row class="box-statistic">
  <div nz-col nzSpan="6">
    <nz-statistic
      nzValue="Basic"
      nzTitle="Name Package"
      [nzValueStyle]="{ fontSize: '16px' }"
    ></nz-statistic>
  </div>
  <div nz-col nzSpan="6">
    <nz-statistic
      nzValue="Total User"
      nzTitle="10"
      [nzValueStyle]="{ fontSize: '16px' }"
    ></nz-statistic>
  </div>
  <div nz-col nzSpan="6">
    <nz-statistic
      nzValue="Active users"
      nzTitle="8"
      [nzValueStyle]="{ fontSize: '16px' }"
    ></nz-statistic>
  </div>
</div> -->
<nz-divider nzOrientation="left"></nz-divider>
<nz-table
  #nzTable
  nzShowSizeChanger
  [nzLoading]="tableService.loading"
  [nzPageSizeOptions]="[10, 20, 50, 100]"
  [nzData]="listOfDisplayData"
  (nzCurrentPageDataChange)="tableService.onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th
        nzWidth="40px"
        [nzChecked]="tableService.checked"
        [nzIndeterminate]="tableService.indeterminate"
        (nzCheckedChange)="tableService.onAllChecked($event)"
      ></th>
      <th nzWidth="50px" nzAlign="center">No</th>
      <th
        *ngFor="let column of listOfColumn"
        [nzSortFn]="column.sorter"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
      >
        {{ column.title }}
      </th>
      <th nzCustomFilter nzAlign="right">
        Created On
        <nz-filter-trigger
          [nzActive]="!!date"
          [(nzVisible)]="visibleCustomFilter"
          [nzDropdownMenu]="menu"
        >
          <span nz-icon nzType="filter" nzTheme="fill"></span>
        </nz-filter-trigger>
      </th>
      <th nzWidth="50px" nzAlign="center" *ngIf="tabId === 1"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of nzTable.data | search : tableService.searchValue">
      <td
        [nzChecked]="tableService.setOfCheckedId.has(data.id)"
        (nzCheckedChange)="tableService.onItemChecked(data.id, $event)"
      ></td>
      <td nzAlign="center" (click)="showUserProfile(data.id)">{{ data.stt }}</td>
      <td (click)="showUserProfile(data.id)">
        <table-cell-avatar [title]="data.username"></table-cell-avatar>
      </td>
      <td (click)="showUserProfile(data.id)">{{ data.role }}</td>
      <td (click)="showUserProfile(data.id)">{{ data.email }}</td>
      <td (click)="showUserProfile(data.id)" nzAlign="right">
        {{ data.createdAt | date : dd_MM_yyyy }}
      </td>
      <td nzAlign="center" *ngIf="tabId === 1">
        <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
          <i nz-icon nzType="more" nzTheme="outline" class="icon-more"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="showEditUser(data.id)">
              <i nz-icon nzType="menu_action:user_edit" class="icon-action-menu"></i>
              Edit User
            </li>

            <li
              nz-menu-item
              nz-popconfirm
              nzPopconfirmTitle="Are you sure reset this?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="resetPassword(data.email)"
            >
              <i nz-icon nzType="menu_action:key" class="icon-action-menu"></i>
              Reset Password
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-invite-member></app-invite-member>
<app-user-profile
  [visible]="visibleUserProfile"
  (emitReloadData)="acceptData()"
  (cancel)="visibleUserProfile = $event"
></app-user-profile>
<app-modal-confirm
  title="Upgrade Your Subscription!"
  content="You need to subscribe more user before create new one."
  iconType="custom:card_circle"
  okText="Upgrade"
></app-modal-confirm>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div
      class="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-menu-vertical"
    >
      <app-date-picker #datePicker (onChangeDate)="onChangeDate(datePicker.date)"></app-date-picker>
    </div>
    <div class="ant-table-filter-dropdown-btns">
      <button nz-button nzSize="small" nzType="link" [disabled]="!date" (click)="resetFilterDate()">
        Reset
      </button>
      <button
        nz-button
        nzSize="small"
        nzType="primary"
        class="filter-button"
        (click)="filterDate()"
      >
        Ok
      </button>
    </div>
  </div>
</nz-dropdown-menu>
