import { TYPE_MESSAGE } from './../../../commons/enums/common.enum'
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { GeneralService } from '../../../commons/services/general.service'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  type: TYPE_MESSAGE = TYPE_MESSAGE.SUCCESS
  TYPE_MESSAGE = TYPE_MESSAGE
  @ViewChild(TemplateRef, { static: false }) template?: TemplateRef<{}>

  constructor(
    private notification: NzNotificationService,
    private generalService: GeneralService
  ) {}

  ngOnInit() {
    this.generalService.notificationMethodCall$.subscribe(
      ({ type, title, description, options }) => {
        this.type = type
        if (type === TYPE_MESSAGE.SUCCESS) {
          this.notification.template(this.template!, {
            nzData: { title, description },
            ...options
          })
        } else {
          this.notification.error(title, description)
        }
      }
    )
  }
}
