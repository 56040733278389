import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { URL_PAGE } from '../../../commons/consts'
import { TITLE_PAGE } from '../../../commons/enums/common.enum'
import { PaymentCompleteComponent } from './payment-complete/payment-complete.component'
import { PaymentComponent } from './payment.component'

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.PAYMENT },
  {
    path: '',
    component: PaymentComponent
  },
  {
    path: URL_PAGE.PAYMENT_DETAIL + '/:id',
    component: PaymentCompleteComponent,
    data: { breadcrumb: TITLE_PAGE.PAYMENT_DETAIL }
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule {}
