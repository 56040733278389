<app-container-form>
  <h3 class="title-form">Welcome to Admin Center</h3>
  <form nz-form nzLayout="vertical" [formGroup]="loginForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label nzFor="email">Email</nz-form-label>
      <nz-form-control [nzErrorTip]="emailErrorTpl">
        <input
          nz-input
          formControlName="email"
          type="email"
          id="email"
          placeholder="Enter your email address"
        />
        <ng-template #emailErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">Please input your email!</ng-container>
          <ng-container *ngIf="control.hasError('pattern')"
            >The email address you entered isn't connected to an account.</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="password">Password</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
        <app-input-password #inputPassword>
          <input
            id="password"
            [type]="inputPassword.passwordVisible ? 'text' : 'password'"
            nz-input
            placeholder="Enter your confirm password"
            formControlName="password"
          />
        </app-input-password>
        <ng-template #passwordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Your account or password is incorrect, please try again.</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div class="wrapper_forgot_remember">
      <nz-form-item class="remember_me">
        <nz-form-control>
          <label nz-checkbox formControlName="remember">Remember me</label>
        </nz-form-control>
      </nz-form-item>
      <a routerLink="/{{ URL_PAGE.FORGOT_PASSWORD }}" class="forgot">Forgot your password?</a>
    </div>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary">Log in</button>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="redirect_signUp">
    <div class="text_question">Don't have a account?</div>
    <a routerLink="/{{ URL_PAGE.REGISTER }}" class="text_redirect">
      <div class="content">Get start here</div>
      <img src="assets/images/christmas-stars-blue.png" class="icon" />
    </a>
  </div>
</app-container-form>
