<app-drawer titleHeader="Send Invoice" [visible]="visible" (cancel)="cancel.emit(false)">
  <ng-container drawerContent>
    <div class="container-input-email">
      <form nz-form [formGroup]="formSendInvoice" nzLayout="vertical">
        <nz-form-item>
          <nz-form-label nzNoColon nzRequired nzFor="emails">Send Email</nz-form-label>
          <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
            <nz-select
              formControlName="emails"
              nzPlaceHolder="Enter emails you want to send"
              nzMode="tags"
              nzAllowClear
              nzShowSearch
              (nzOnSearch)="onSearch($event)"
              id="emails"
              [(ngModel)]="listSelectedEmail"
              [nzOptionHeightPx]="42"
            >
              <nz-option
                [nzValue]="item"
                *ngFor="let item of listEmailSuggest"
                [nzLabel]="item"
              ></nz-option>
            </nz-select>
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Please input your email!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </form>

      <nz-divider></nz-divider>
    </div>

    <div class="lbl">*{{ paymentIds.length }} users selected</div>
    <div class="container-list-send-invoice">
      <div
        nz-row
        class="card-send-invoice"
        nzAlign="middle"
        nzJustify="space-between"
        *ngFor="let id of paymentIds; trackBy: trackByFn"
      >
        <div nz-col>{{ numberNoComma(id) }}</div>
        <div nz-col>
          <img
            class="icon-remove"
            width="32px"
            height="32px"
            src="assets/images/user-manage/btnclose.png"
            (click)="removeItem(id)"
          />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container drawerBtn>
    <button nz-button (click)="onCancel()" [disabled]="isLoadingBtn">Cancel</button>
    <button
      nz-button
      nzType="primary"
      (click)="sendInvoice()"
      [disabled]="paymentIds.length === 0"
      [nzLoading]="isLoadingBtn"
    >
      Send Invoice
    </button>
  </ng-container>
</app-drawer>
