import { Component, Input, OnInit } from '@angular/core'
@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements OnInit {
  @Input() disabled = false
  passwordVisible = false
  constructor() {}

  ngOnInit(): void {}
}
