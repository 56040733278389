<app-container-form>
  <app-heading-page
    title="Let start with your information"
    subtitle="Input Your Information"
    [titleStyle]="{ fontSize: '24px', fontWeight: '500' }"
    [subtitleStyle]="{ fontSize: '14px' }"
    containerStyle="flexDirection: column-reverse; margin: 49px 0 40px 0"
  ></app-heading-page>
  <form nz-form nzLayout="vertical" [formGroup]="mandatoryForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label nzFor="userName" nzRequired>Username</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="usernameErrorTpl">
        <input
          nz-input
          formControlName="userName"
          type="text"
          id="userName"
          placeholder="Enter your username"
        />
        <ng-template #usernameErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please input your username!</ng-container
          >
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format username</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="companyName" nzRequired>Company Name</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="companyErrorTpl">
        <input
          nz-input
          formControlName="companyName"
          type="text"
          id="companyName"
          placeholder="Enter your company name"
          [(ngModel)]="companyName"
        />
        <ng-template #companyErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please input your company name!</ng-container
          >
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format company name</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="companyAddress" nzRequired>Company Address</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="companyErrorTpl">
        <input
          nz-input
          formControlName="companyAddress"
          type="text"
          id="companyAddress"
          placeholder="Enter your company Address"
          [(ngModel)]="companyAddress"
        />
        <ng-template #companyErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please input your company Address!</ng-container
          >
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format company Address</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <!-- <nz-form-item>
      <nz-form-label nzFor="workEmail" nzRequired>Work Email</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="workEmailErrorTpl">
        <input
          nz-input
          formControlName="workEmail"
          type="email"
          id="workEmail"
          (input)="onInputEmail($event)"
          [nzAutocomplete]="autoEmail"
          placeholder="abcde@gmail.com"
        />
        <nz-autocomplete #autoEmail>
          <nz-auto-option *ngFor="let option of options" [nzValue]="option">{{
            option
          }}</nz-auto-option>
        </nz-autocomplete>
        <ng-template #workEmailErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please input your work email!</ng-container
          >
          <ng-container *ngIf="control.hasError('email')"
            >Please input your format email!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item> -->

    <nz-form-item>
      <nz-form-label nzFor="phoneNumber" nzRequired>Phone Number</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="phoneNumberErrorTpl">
        <input
          nz-input
          formControlName="phoneNumber"
          type="text"
          id="phoneNumber"
          placeholder="Enter your phone number"
        />
        <ng-template #phoneNumberErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please input your phone number!</ng-container
          >
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format phone number!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="location" nzRequired>Location</nz-form-label>
      <nz-form-control nzHasFeedback nzErrorTip="Please select your location">
        <nz-select nzPlaceHolder="Select your location" formControlName="location" nzAllowClear>
          <nz-option nzValue="vn" nzLabel="Việt nam"></nz-option>
          <nz-option nzValue="usa" nzLabel="USA"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="yourSite" nzRequired>Your Site</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="yourSiteErrorTpl" class="form-item-tenant">
        <nz-input-group nzAddOnAfter=".kingwork.vn">
          <input
            nz-input
            formControlName="yourSite"
            type="text"
            (blur)="onBlurYourSite()"
            id="yourSite"
            placeholder="Enter your site"
          />
        </nz-input-group>
        <ng-template #yourSiteErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">Please input your site!</ng-container>
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format domain with alphanumeric characters!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary">Next</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</app-container-form>
