import { URL_PAGE } from './../../../commons/consts/constants'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SessionStorageService } from '../../../commons/services/session/session-storage.service'
import { TYPE_SITE } from '../../../commons/enums/common.enum'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router, private storage: SessionStorageService) {}

  ngOnInit(): void {}

  navigationHome() {
    const typeSite = JSON.parse(this.storage.getDataUser() as string)?.roles[0].type
    if (!typeSite) {
      this.router.navigateByUrl(URL_PAGE.LOGIN)
    } else if (typeSite === TYPE_SITE.ITV) {
      this.router.navigateByUrl(URL_PAGE.TENANT_MANAGEMENT)
    } else {
      this.router.navigateByUrl(URL_PAGE.ACCOUNT_MANAGEMENT)
    }
  }
}
