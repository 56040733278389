import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { URL_PAGE } from '../../../commons/consts'
import { MESSAGE_SUCCESS, TITLE_PAGE, TYPE_SITE } from '../../../commons/enums/common.enum'
import { StorageKey } from '../../../commons/enums/storage-key.enum'
import { AuthServices } from '../../../commons/services/authen/auth.service'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'
import { SessionStorageService } from '../../../commons/services/session/session-storage.service'
import { Regexs } from '../../../configs/regexs'
import { StateService } from './../../../commons/services/state.service'
import { ValidationService } from './../../../commons/services/validation.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup
  URL_PAGE = URL_PAGE
  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private authService: AuthServices,
    private router: Router,
    private tokenStorage: SessionStorageService,
    private validation: ValidationService,
    private httpObservable: HttpObservablesService
  ) {
    if (this.tokenStorage.getBoolean(StorageKey.IS_LOGIN)) {
      this.navigationSite()
    }
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(Regexs.EMAIL)]],
      password: ['', [Validators.required]],
      remember: [true]
    })
  }

  navigationSite() {
    const typeSite = JSON.parse(this.tokenStorage.getDataUser() as string)?.roles[0].type
    if (typeSite === TYPE_SITE.ITV) {
      this.router.navigateByUrl(URL_PAGE.TENANT_MANAGEMENT)
    } else {
      this.router.navigateByUrl(URL_PAGE.SUBSCRIPTION)
    }
  }

  get email() {
    return this.loginForm.controls.email
  }
  get password() {
    return this.loginForm.controls.password
  }

  callbackSuccess(res: any) {
    this.tokenStorage.setToken(res?.data?.accessToken)
    this.stateService.setState(StorageKey.IS_LOGIN, true)
    this.tokenStorage.setDataUser(res.data)
    this.navigationSite()
  }

  submitForm(): void {
    this.authService.logout()
    if (this.loginForm.valid) {
      const formValue = {
        email: this.email.value,
        password: this.password.value
      }
      this.authService.login(formValue).subscribe(
        (res: any) => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.LOGIN,
            title: TITLE_PAGE.LOGIN,
            callbackSuccess: () => this.callbackSuccess(res)
          })
        },
        (err: { error: { message: string } }) => {
          this.httpObservable.handleError({
            error: err.error,
            title: TITLE_PAGE.LOGIN
          })
        }
      )
    } else {
      this.validation.checkAllValidation(this.loginForm.controls)
    }

    // if (this.loginForm.valid) {
    //   if (isEqual(formValue, { email: 'demo@gmail.com', password: '123' })) {
    //     this.stateService.setState('isLogged', true)
    //     this.router.navigateByUrl('/user-manage')
    //   } else {
    //     this.stateService.setState('isLogged', false)
    //     this.router.navigateByUrl('/user-manage')
    //   }
    // } else {
    //   Object.values(this.loginForm.controls).forEach((control) => {
    //     if (control.invalid) {
    //       control.markAsDirty()
    //       control.updateValueAndValidity({ onlySelf: true })
    //     }
    //   })
    // }
  }
}
