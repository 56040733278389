/* eslint-disable */
export const Regexs = {
  ONLY_SPACE: /\s+/g,
  ONLY_COMMAS: /\,/g,
  PHONE_NUMBER: /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/,
  UNFORMAT_PHONE_NUMBER: /[\(\)\s\-]/g,
  ALPHANUMBERIC_SPACE: /^[A-Za-z0-9\s]+$/,
  ADDRESS: /^[^`~!@#$%^&*()_+={}\[\]|\\:;“’<>.?๐฿]*$/,
  FREE_TEXT: /^[A-Za-z0-9\s\-\/\:\;\(\)\&\@\.\,\?\!\\_\\')]+$/,
  ACCOUNT_NUMBER: /^[0-9\-]+$/,
  NAME: /^[^`~!@#$%^&*()_+={}\[\]|\\:;“’<>.?๐฿]*$/,
  INPUT_NAME: /^[A-Za-z'\s\-\']+$/,
  ANSWER_TEXT: /^[A-Za-z'\s\.\,\-\(\)\\\']+$/,
  ANSWER_TEXT_AND_NUMBER: /^[A-Za-z0-9'\s\.\,\-\(\)\\\']+$/,
  ALPHANUMBERIC_UPPERCASE: /^[A-Z0-9]+$/,
  ALPHANUMBERIC: /^[A-Za-z0-9]+$/,
  DECIMAL_NUMBER: /^[^.]*.?[^.]*$/,
  SSN_FORMAT: /^\d{3}-\d{2}-\d{4}$/,
  CRYPTO_ADDRESS: /^[a-zA-Z0-9]{26,}$/,
  // EMAIL: /^(([^!~#$%^&*"'`=|?/}{“’+<>()\[\]\\.,;:\s@"]+(\.[^!~#$%^&*"'`=|?/}{“’+<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL: /^\w+([+.]?\w+)*@\w+([\.-]?\w+)*(\.\w{3,})+$/,
  FIST_CHARACTER_NOT_SPACE: /^\S.*/,
  FIST_CHARACTER_NOT_SPECIAL: /^[^\-\s\'].*/,

  REFERENCE_INPUT: /^[a-zA-Z0-9$.,\s]+$/,
  PASSWORD: /^(?=.*\d)(?=.*[!"#$%&'()*+,-.\\\/:;<=>?@\[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{12,}$/,
  CURRENCY_VALID: /^\$?(([1-9]\d{0,2}(,\d{3})*))(\.\d{1,2})?$/,
  START_DATE: /^(20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
  VALID_ROBO_AMOUNT: /^\$(0|[1-9][0-9]{0,2})(,\d{3})*?$/,
  VALIDATE_SHARES_AMOUNT: /^\$([1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?$/,
  VALIDATE_EQUITIES_AMOUNT: /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|0)?(\.[0-9]{1,8})?$/,
  DOMAIN: /^[a-zA-Z0-9]+$/,
  ONLY_NUMBER: /^[0-9]*$/,
  PASSWORDNOTSPECIALCHARACTER: /((?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{6,})/
}
