import { URL_PAGE } from './../../commons/consts/constants'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthComponent } from './auth.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { LoginComponent } from './login/login.component'
import { MandatoryInfoComponent } from './mandatory-info/mandatory-info.component'
import { RegisterComponent } from './register/register.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.LOGIN },
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: URL_PAGE.LOGIN, component: LoginComponent },
      { path: URL_PAGE.REGISTER, component: RegisterComponent },
      { path: URL_PAGE.FORGOT_PASSWORD, component: ForgotPasswordComponent },
      { path: URL_PAGE.RESET_PASSWORD, component: ResetPasswordComponent },
      {
        path: URL_PAGE.MANDATORY_INFO,
        component: MandatoryInfoComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
