import { Component, Input, OnInit } from '@angular/core'
import { ListProduct } from '../../choose-service/choose-service.component'

@Component({
  selector: 'app-card-service',
  templateUrl: './card-service.component.html',
  styleUrls: ['./card-service.component.scss']
})
export class CardServiceComponent implements OnInit {
  @Input() defaultAvatar: string =
    'https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png'
  @Input() listProduct!: ListProduct[]

  constructor() {}

  ngOnInit(): void {}
}
