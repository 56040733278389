<app-drawer [titleHeader]="titleHeader" (cancel)="onCancel()" [visible]="visibleDrawer">
  <ng-container drawerContent>
    <div *ngIf="userDetail">
      <div class="banner-user">
        <button nz-button class="btn-edit-user" (click)="goEditUser()" *ngIf="isStatusActive">
          <span nz-icon nzType="edit" nzTheme="outline"></span>
          Edit Information
        </button>
        <img
          class="avatar-user"
          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
          alt="avatar-user"
        />
      </div>
      <h3 class="username">{{ userName }}</h3>
      <ng-content select="[content-profile]"></ng-content>
    </div>
  </ng-container>
  <ng-container drawerBtn>
    <ng-container [ngTemplateOutlet]="btnProfileTpl"></ng-container>
  </ng-container>
</app-drawer>
