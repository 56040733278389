<app-container-form>
  <h3 class="title-form">Reset Password</h3>
  <form nz-form nzLayout="vertical" [formGroup]="resetPassForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label nzFor="password">Password</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="passWordErrorTpl">
        <app-input-password #inputPassword>
          <input
            id="password"
            [type]="inputPassword.passwordVisible ? 'text' : 'password'"
            nz-input
            placeholder="Enter your password"
            formControlName="password"
            (ngModelChange)="validateConfirmPassword()"
          />
          <ng-template #passWordErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')"
              >Please input your password!</ng-container
            >
          </ng-template>
        </app-input-password>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="confirmedPassword">Confirmed Password</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="confirmPasswordErrorTpl">
        <app-input-password #inputConfirmedPassword>
          <input
            id="confirmedPassword"
            [type]="inputConfirmedPassword.passwordVisible ? 'text' : 'password'"
            nz-input
            placeholder="Enter your confirm password"
            formControlName="confirmedPassword"
          />
        </app-input-password>
        <ng-template #confirmPasswordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please confirm your password!</ng-container
          >
          <ng-container *ngIf="control.hasError('confirm')"
            >Two passwords that you enter is inconsistent!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary">Change Password</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</app-container-form>
