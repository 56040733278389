<div nz-row nzJustify="space-between" nzAlign="middle">
  <div nz-col>
    <app-heading-page
      title="{{ TITLE_PAGE.BILLING_INVOICING }}"
      subtitle="View your invoice history here"
    ></app-heading-page>
  </div>
  <div nz-col class="container-btn">
    <button nz-button class="btn-icon" (click)="visibleSendInvoice = true">
      <span nz-icon nzType="icon:send" class="custom-anticon"></span>
    </button>
    <button
      nz-button
      class="btn-icon"
      (click)="exportInvoice()"
      [nzLoading]="isLoadingBtn"
      [disabled]="tableService.setOfCheckedId.size === 0"
    >
      <span nz-icon nzType="icon:download" class="custom-anticon"></span>
    </button>
  </div>
</div>
<nz-divider></nz-divider>
<nz-table
  #nzTable
  nzShowSizeChanger
  [nzLoading]="tableService.loading"
  [nzPageSizeOptions]="[10, 20, 50, 100]"
  [nzData]="listOfDisplayData"
  (nzCurrentPageDataChange)="tableService.onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th
        nzWidth="50px"
        [nzChecked]="tableService.checked"
        [nzIndeterminate]="tableService.indeterminate"
        (nzCheckedChange)="tableService.onAllChecked($event)"
      ></th>
      <th nzWidth="80px" nzAlign="center">No</th>
      <th
        *ngFor="let column of listOfColumn"
        [nzSortFn]="column.sorter"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
        [nzAlign]="column.align"
      >
        {{ column.title }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of nzTable.data">
      <td
        [nzChecked]="tableService.setOfCheckedId.has(data.id)"
        (nzCheckedChange)="tableService.onItemChecked(data.id, $event)"
      ></td>
      <td nzAlign="center" (click)="showPaymentDetail(data)">{{ data.stt }}</td>
      <td (click)="showPaymentDetail(data)">{{ data?.packageHistory?.name }}</td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">
        {{ numberNoComma(data.id) }}
      </td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">
        {{ data.startDate | date : formatDate }}
      </td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">{{ data.totalUser }}</td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">
        {{ data.amount | currency : 'VND' }}
      </td>
    </tr>
  </tbody>
</nz-table>

<app-send-invoice
  [visible]="visibleSendInvoice"
  (cancel)="visibleSendInvoice = $event"
></app-send-invoice>

<app-detail-payment-invoice
  titleHeader="Invoice Detail"
  [visible]="visibleInvoiceDetail"
  (cancel)="visibleInvoiceDetail = $event"
></app-detail-payment-invoice>
<app-template-pdf
  [style.display]="general.visibleTemplatePDF ? 'block' : 'none'"
  [data]="general.dataPDF"
></app-template-pdf>
