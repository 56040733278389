<nz-table
  #nzTable
  class="table-subscription"
  [nzShowPagination]="false"
  [nzData]="listData"
  [nzLoading]="listData.length === 0"
>
  <thead>
    <tr>
      <th class="cell" nzWidth="100px">Paid</th>
      <th class="cell" nzWidth="190px">Storage</th>
      <th nzAlign="center" class="cell color-blue">unlimited</th>
      <th nzAlign="center" class="cell color-blue">unlimited</th>
      <th nzAlign="center" class="cell color-blue">unlimited</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of nzTable.data" class="row">
      <td></td>
      <td>{{ item.name | titlecase }}</td>

      <!-- ----- feature charge------- -->
      <td nzAlign="center" *ngIf="item.trial; else notTicked">
        <i nz-icon nzType="icon:ticked_circle"></i>
      </td>
      <td nzAlign="center" *ngIf="item.basic; else notTicked">
        <i nz-icon nzType="icon:ticked_circle"></i>
      </td>
      <td nzAlign="center" *ngIf="item.advance; else notTicked">
        <i nz-icon nzType="icon:ticked_circle"></i>
      </td>
    </tr>

    <tr class="row" *ngIf="rowStorage">
      <td></td>
      <td>Storage</td>
      <td nzAlign="center">
        {{ rowStorage.trial }}
      </td>
      <td nzAlign="center">
        {{ rowStorage.basic }}
      </td>
      <td nzAlign="center">
        {{ rowStorage.advance }}
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #notTicked><td nzAlign="center">-</td></ng-template>
