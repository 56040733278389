import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
