import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { URL_PAGE } from '../../consts'
import { ERROR_CODE } from '../../enums/common.enum'
import { HttpObservablesService } from '../connection/http-observables.service'
import { SessionStorageService } from '../session/session-storage.service'
import { AuthServices } from './auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storage: SessionStorageService,
    private router: Router,
    private authService: AuthServices,
    private httpObservable: HttpObservablesService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.getToken()
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-tenant-id': 'itv',
          'Content-Type': 'application/json'
        }
      })
    }
    return next.handle(req).pipe(
      catchError((httpError) => {
        const { error: responseError } = httpError
        if (
          responseError.status === ERROR_CODE.UNAUTHORIZED &&
          responseError.error === 'Unauthorized'
        ) {
          // redirect the user to the login page
          this.authService.logout()
          this.router.navigate([URL_PAGE.LOGIN])
        }
        return throwError(httpError)
      })
    )
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
]
