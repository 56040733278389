import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { PaymentService } from '../../../api/payment.service'
import { SubScriptionService } from '../../../api/subscription.service'
import { URL_PAGE } from '../../../commons/consts'
import { PAYMENT_STATUS, TITLE_PAGE } from '../../../commons/enums/common.enum'
import { StorageKey } from '../../../commons/enums/storage-key.enum'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'
import { SessionStorageService } from '../../../commons/services/session/session-storage.service'
import { DATE_FORMAT } from '../../../configs/constants'
import { ResponseManageSubscription, ResponsePayment } from '../../../interfaces/subscription'
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component'
import { numberNoComma } from '../../../utils/format'

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss']
})
export class ManageSubscriptionComponent implements OnInit {
  numberNoComma = numberNoComma
  dd_MM_yyyy = DATE_FORMAT.dd_MM_yyyy
  URL_PAGE = URL_PAGE
  data$!: Observable<ResponseManageSubscription>
  PAYMENT_STATUS = PAYMENT_STATUS
  subtitleReactivation =
    'Your Premium package has changed, please <span class="text-orange">Reactivate</span> the user'

  @ViewChild(ModalConfirmComponent) modalConfirmComp!: ModalConfirmComponent

  constructor(
    private router: Router,
    private session: SessionStorageService,
    private subscription: SubScriptionService,
    private paymentService: PaymentService,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    this.getCurrentAndNextPackage()
  }

  goSubscriptionPackage() {
    this.router.navigateByUrl(URL_PAGE.SUBSCRIPTION)
    this.session.set(StorageKey.HAS_NEXT_PLAN, true)
  }
  getCurrentAndNextPackage() {
    this.data$ = this.subscription
      .getCurrentAndNextPackage()
      .pipe(map((res: { data: ResponseManageSubscription }) => res.data))
  }

  confirmApplyPackage() {
    this.modalConfirmComp.showModal()
  }

  goPayment(item: ResponsePayment) {
    let body = {
      id: item.id,
      packageId: item.packageId,
      totalUser: item.totalUser,
      period: item.period
    }

    this.paymentService.registerPayment(body).subscribe(
      (res: any) => {
        window.location.href = res.message
      },
      (err: { error: { message: string } }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.PAYMENTS
        })
      }
    )
  }

  goPaymentNow(item: ResponsePayment) {}
}
