import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../../app/ng-zorro-antd.module'
import { SharedModule } from '../../../shared/shared.module'
import { BillingInvoiceRoutingModule } from './billing-invoice-routing.module'
import { BillingInvoiceComponent } from './billing-invoice.component'
import { SendInvoiceComponent } from './send-invoice/send-invoice.component'

@NgModule({
  imports: [
    BillingInvoiceRoutingModule,
    SharedModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgZorroAntdModule
  ],
  exports: [SendInvoiceComponent],
  declarations: [BillingInvoiceComponent, SendInvoiceComponent],
  providers: [],
  bootstrap: [BillingInvoiceComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BillingInvoiceModule {}
