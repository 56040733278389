const APIs = {
  AUTH_LOGIN: '/api/auth/authentication',
  AUTH_REGISTER: '/api/account-register',
  AUTH_REGISTER_VALIDATE: '/api/account-register/validate',
  AUTH_lIST_USER: '/api/account?limit=10&offset=0',
  USERS: '/api/account',
  USERSLOGIN: '/api/customer/info',
  DE_ACTIVE_ACCOUNT: '/api/users/status',
  PACKAGE_LIST: '/api/package',
  PACKAGE_LIST_ADMIN: '/api/package/admin',
  PRODUCT: '/api/product',
  PAYMENT: '/api/payment',
  INVOICE: '/api/invoice',

  BILLING_INVOICE_ADMIN: '/api/payment/billing-invoices-admin',
  UPGRADE_PACKAGE: '/api/payment/upgrade-package',
  CURRENT_AND_NEXT_PACKAGE: '/api/payment/current-and-next-package',

  REQUEST_CHANGE_PASSWORD: '/api/customer/request-change-password',
  CHANGE_CUSTOMER_PASSWORD: '/api/customer/change-password',
  REQUEST_CHANGE_PASSWORD_ACCOUNT: '/api/account/request-change-password',
  ROLE: '/api/role',

  LIST_FAQ: '/api/faqs/questions',

  RE_AUTHENTICATE: '/customers/auth/refresh-token',
  AUTH: 'v2/webplatform/authentication/check-status-account',
  CREATE_PASSWORD: 'v2/authentication/passwordweb',
  VALIDATE_PASSWORD: 'v2/authentication/passwordweb/validate',
  GET_USER_INFO: 'v2/cognito/users'
}
export default APIs
