<div class="container_filter" [class.filter-focus]="isFocused" (click)="isClick = !isClick">
  <i nz-icon nzType="filter" nzTheme="outline" class="icon_filter"></i>
  <div class="title">Filter</div>
  <i
    nz-icon
    nzType="down"
    nzTheme="outline"
    class="icon_arrow_down"
    *ngIf="!isClick; else icon_up"
  ></i>
</div>

<ng-template #icon_up>
  <i nz-icon nzType="up" nzTheme="outline" class="icon_arrow_up"></i>
</ng-template>
