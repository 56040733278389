<div>
  <app-heading-page
    title="Manage subscriptions"
    subtitle="Manage your subscriptions"
  ></app-heading-page>
  <div nz-row class="container-content" [nzGutter]="[24, 0]" *ngIf="data$ | async as data">
    <div nz-col nzXs="24" nzLg="16">
      <div class="section-left">
        <div class="box-user-reactivation">
          <app-heading-page
            title="User reactivation"
            [subtitle]="subtitleReactivation"
            [subtitleStyle]="{ color: 'black' }"
          ></app-heading-page>
        </div>
        <div class="box-content-package">
          <div class="header-content-package" nz-row nzJustify="space-between">
            <app-heading-page
              nz-col
              [titleStyle]="{ fontSize: '24px' }"
              [subtitleStyle]="{ fontSize: '14px' }"
              [title]="data.currentPackage.packageHistory.name || ''"
              [subtitle]="data.currentPackage.packageHistory.description || ''"
            ></app-heading-page>
            <div class="btn-text btn-change-package" nz-col (click)="goSubscriptionPackage()">
              Change
            </div>
          </div>
          <div class="content-package" nz-row nzJustify="space-between">
            <div nz-col nzXs="12" nzLg="6">
              <div class="title-content">Invoice number</div>
              <div class="description-content" *ngIf="data.currentPackage.id">
                {{ numberNoComma(data.currentPackage.id) || '' }}
              </div>
            </div>
            <div nz-col nzXs="12" nzLg="4">
              <div class="title-content">User</div>
              <div class="description-content">{{ data.currentPackage.totalUser }} users</div>
            </div>
            <div nz-col nzXs="12" nzLg="4">
              <div class="title-content">Price</div>
              <div class="description-content">
                {{ data.currentPackage.packageHistory.userPrice | currency : 'VND' }}
              </div>
            </div>
            <div nz-col nzXs="12" nzLg="4">
              <div class="title-content">Start date</div>
              <div class="description-content">
                {{ data.currentPackage.startDate | date : dd_MM_yyyy }}
              </div>
            </div>
          </div>
        </div>

        <nz-divider style="border-color: #cdd5df"></nz-divider>

        <div class="container-btn-manage-user">
          <button nz-button class="btn-manage-user" routerLink="/{{ URL_PAGE.ACCOUNT_MANAGEMENT }}">
            <i nz-icon nzType="icon:user" class="custom-anticon"></i>Manage users
          </button>
        </div>
      </div>
    </div>

    <div nz-col nzXs="24" nzLg="8" class="section-right">
      <app-heading-page title="Next Plan" subtitle="View all your next plan"></app-heading-page>
      <div class="box-next-plan">
        <div class="header-next-plan" nz-row nzJustify="space-between">
          <div nz-col>
            <div class="date-next-plan">{{ data.nextPackage.startDate | date : dd_MM_yyyy }}</div>
            <div class="code-next-plan" *ngIf="data.nextPackage.id">
              #{{ data.nextPackage.nextPlan ? numberNoComma(data.nextPackage.id) : '' }}
            </div>
          </div>
          <div class="btn-text btn-change-package" nz-col (click)="goSubscriptionPackage()">
            Change
          </div>
        </div>
        <nz-divider style="border-color: #e3e8ef; margin: 12px 0px"></nz-divider>
        <div class="content-next-plan">
          <div nz-row nzJustify="space-between">
            <div nz-col>Name Package</div>
            <div nz-col class="value-row">{{ data.nextPackage.packageHistory.name }}</div>
          </div>
          <div nz-row nzJustify="space-between">
            <div nz-col>User</div>
            <div nz-col class="value-row">{{ data.nextPackage.totalUser }} users</div>
          </div>
          <div nz-row nzJustify="space-between">
            <div nz-col>Price</div>
            <div nz-col class="value-row">{{ data.nextPackage.packageHistory.userPrice }}</div>
          </div>
          <div nz-row nzJustify="space-between">
            <div nz-col>Payment methods</div>
            <div nz-col class="value-row">
              {{ data.nextPackage.nextPlan ? data.nextPackage.cardType : '' }}
            </div>
          </div>
          <div nz-row nzJustify="space-between">
            <div nz-col>Status</div>
            <div [ngSwitch]="data.nextPackage.nextPlan ? data.nextPackage.status : null">
              <nz-badge
                *ngSwitchCase="PAYMENT_STATUS.SUCCESS"
                nzText="Successful"
                nzStatus="success"
              ></nz-badge>
              <nz-badge
                *ngSwitchCase="PAYMENT_STATUS.PENDING"
                nzText="Pending"
                nzStatus="warning"
              ></nz-badge>
              <nz-badge
                *ngSwitchCase="PAYMENT_STATUS.FAIL"
                nzText="Failed"
                nzStatus="error"
              ></nz-badge>
              <ng-container *ngSwitchDefault></ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-payment">
        <p>Package for the next plan is still unpaid.</p>
        <button
          nz-button
          nzType="text"
          class="btn-text btn-pay-now"
          [disabled]="!data.nextPackage.nextPlan"
          (click)="goPayment(data.nextPackage)"
        >
          Pay now <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        </button>
      </div>
      <!-- <app-modal-confirm
        title="Confirm"
        content="Do you want apply this package now?"
        okText="Yes"
        cancelText="No"
        (onOk)="goPaymentNow(data.nextPackage)"
        (onCancel)="goPayment(data.nextPackage)"
      ></app-modal-confirm> -->
    </div>
  </div>
</div>
