<ng-template let-data="data">
  <div class="container-noti">
    <div class="icon-noti"><ng-container [ngTemplateOutlet]="iconNotiSuccess"></ng-container></div>
    <div class="noti-content">
      <div class="noti-title">{{ data.title }}</div>
      <div class="noti-description">{{ data.description }}</div>
    </div>
  </div>
</ng-template>

<ng-template #iconNotiSuccess>
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#D1FADF" />
    <g clip-path="url(#clip0_794_60609)">
      <path
        d="M20.4993 22.9993L22.166 24.666L25.916 20.916M19.1108 16.1816C19.7807 16.1281 20.4166 15.8647 20.9281 15.4289C22.1216 14.4117 23.8771 14.4117 25.0706 15.4289C25.5821 15.8647 26.218 16.1281 26.8879 16.1816C28.4511 16.3063 29.6924 17.5476 29.8171 19.1108C29.8706 19.7807 30.134 20.4166 30.5698 20.9281C31.587 22.1216 31.587 23.8771 30.5698 25.0706C30.134 25.5821 29.8706 26.218 29.8171 26.8879C29.6924 28.4511 28.4511 29.6924 26.8879 29.8171C26.218 29.8706 25.5821 30.134 25.0706 30.5698C23.8771 31.587 22.1216 31.587 20.9281 30.5698C20.4166 30.134 19.7807 29.8706 19.1108 29.8171C17.5476 29.6924 16.3063 28.4511 16.1816 26.8879C16.1281 26.218 15.8647 25.5821 15.4289 25.0706C14.4117 23.8771 14.4117 22.1216 15.4289 20.9281C15.8647 20.4166 16.1281 19.7807 16.1816 19.1108C16.3063 17.5476 17.5476 16.3063 19.1108 16.1816Z"
        stroke="#039855"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <rect x="3" y="3" width="40" height="40" rx="20" stroke="#ECFDF3" stroke-width="6" />
    <defs>
      <clipPath id="clip0_794_60609">
        <rect width="20" height="20" fill="white" transform="translate(13 13)" />
      </clipPath>
    </defs>
  </svg>
</ng-template>

<ng-template #iconNotiError>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
      stroke="#F04438"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.17 14.83L14.83 9.17"
      stroke="#F04438"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.83 14.83L9.17 9.17"
      stroke="#F04438"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>
