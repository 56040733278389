<div class="app-auth" [style.height]="url === '/mandatory-info' ? 'auto' : '100%'">
  <router-outlet></router-outlet>
  <app-resources-export></app-resources-export>
</div>

<nz-modal
  [(nzVisible)]="visibleModalCode"
  nzTitle="Verify Code"
  [nzClosable]="false"
  [nzFooter]="null"
>
  <ng-container *nzModalContent>
    <form nz-form nzLayout="vertical" [formGroup]="verifyCodeForm" (ngSubmit)="submitFormVerify()">
      <nz-form-item>
        <nz-form-control [nzErrorTip]="codeErrorTpl">
          <input
            nz-input
            formControlName="codeVerify"
            type="codeVerify"
            id="codeVerify"
            placeholder="Enter your code"
            style="height: 40px"
          />
          <ng-template #codeErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')"
              >Please input your code!</ng-container
            >
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary">Log in</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
</nz-modal>
