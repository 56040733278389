import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Injectable({ providedIn: 'root' })
export class ValidationService {
  constructor() {}

  checkAllValidation(controls: { [key: string]: AbstractControl }) {
    return Object.values(controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty()
        control.updateValueAndValidity({ onlySelf: true })
      }
    })
  }
}
