<div class="modal-add-new-user">
  <div class="add-new-user">
    <app-heading-page
      title="Detail payment"
      subtitle="Manage your payment detail"
    ></app-heading-page>
    <div class="form-container" *ngIf="paymentDetail">
      <nz-card nzTitle="Order Summary" class="box" [nzExtra]="extraTpl">
        <div nz-row class="row">
          <p class="title-order">Package Price</p>
          <p class="value-order">đ{{ paymentDetail?.packageHistory?.userPrice }} user/month</p>
        </div>
        <div nz-row class="row">
          <p class="title-order">User</p>
          <p class="value-order">{{ paymentDetail.totalUser }}</p>
        </div>
        <div nz-row class="row">
          <p class="title-order">Discount</p>
          <p class="value-order">0</p>
        </div>
        <div nz-row class="row">
          <p class="title-order">Status</p>
          <div [ngSwitch]="paymentDetail.status">
            <nz-badge
              *ngSwitchCase="PAYMENT_STATUS.SUCCESS"
              nzText="Successful"
              nzStatus="success"
            ></nz-badge>
            <nz-badge
              *ngSwitchCase="PAYMENT_STATUS.PENDING"
              nzText="Pending"
              nzStatus="warning"
            ></nz-badge>
            <nz-badge
              *ngSwitchCase="PAYMENT_STATUS.FAIL"
              nzText="Failed"
              nzStatus="error"
            ></nz-badge>
          </div>
        </div>

        <nz-divider class="divider"></nz-divider>
        <div nz-row class="row">
          <p class="title-order">Total</p>
          <p class="value-order">đ{{ paymentDetail.amount }}</p>
        </div>
      </nz-card>
    </div>
  </div>
</div>

<ng-template #extraTpl>
  <a routerLink="/{{ URL_PAGE.PAYMENT }}">Back</a>
</ng-template>
