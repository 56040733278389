import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {
  @Input() titleHeader = ''
  @Input() visible = false
  @Input() width = '480px'
  @Input() visibleFooter = true
  @Output() cancel: EventEmitter<any> = new EventEmitter(false)
  constructor() {}

  ngOnInit(): void {}

  handleCancel() {
    this.cancel.emit(false)
  }
}
