export const icons = {
  dashboard_menu: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M4.16675 8.33366H5.83341C7.50008 8.33366 8.33341 7.50033 8.33341 5.83366V4.16699C8.33341 2.50033 7.50008 1.66699 5.83341 1.66699H4.16675C2.50008 1.66699 1.66675 2.50033 1.66675 4.16699V5.83366C1.66675 7.50033 2.50008 8.33366 4.16675 8.33366Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1667 8.33366H15.8334C17.5001 8.33366 18.3334 7.50033 18.3334 5.83366V4.16699C18.3334 2.50033 17.5001 1.66699 15.8334 1.66699H14.1667C12.5001 1.66699 11.6667 2.50033 11.6667 4.16699V5.83366C11.6667 7.50033 12.5001 8.33366 14.1667 8.33366Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1667 18.3337H15.8334C17.5001 18.3337 18.3334 17.5003 18.3334 15.8337V14.167C18.3334 12.5003 17.5001 11.667 15.8334 11.667H14.1667C12.5001 11.667 11.6667 12.5003 11.6667 14.167V15.8337C11.6667 17.5003 12.5001 18.3337 14.1667 18.3337Z" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.16675 18.3337H5.83341C7.50008 18.3337 8.33341 17.5003 8.33341 15.8337V14.167C8.33341 12.5003 7.50008 11.667 5.83341 11.667H4.16675C2.50008 11.667 1.66675 12.5003 1.66675 14.167V15.8337C1.66675 17.5003 2.50008 18.3337 4.16675 18.3337Z" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  user_menu: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M6.79175 5.62533C6.79175 3.85621 8.23096 2.41699 10.0001 2.41699C11.7687 2.41699 13.2076 3.85542 13.2084 5.62388C13.2009 7.35965 11.8506 8.75778 10.1288 8.82529C10.042 8.8168 9.95646 8.8177 9.87865 8.82383C8.11743 8.73959 6.79175 7.34224 6.79175 5.62533Z" />
<path d="M6.18471 16.5354L6.18266 16.534C5.28423 15.9351 4.8833 15.1957 4.8833 14.4831C4.8833 13.7699 5.28483 13.0225 6.19053 12.4158C7.21372 11.7391 8.59437 11.3789 10.0104 11.3789C11.4274 11.3789 12.8034 11.7395 13.8173 12.4154C14.7102 13.0107 15.1097 13.7496 15.1166 14.4694C15.1155 15.1901 14.7137 15.929 13.8147 16.5358C12.7967 17.2191 11.4175 17.5831 9.99997 17.5831C8.58222 17.5831 7.20271 17.2189 6.18471 16.5354Z" />
</svg>`,
  card_tick: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M1.66675 7.08301H11.2501" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 13.75H6.66667" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.75 13.75H12.0833"  stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3334 9.19199V13.4253C18.3334 16.3503 17.5917 17.0837 14.6334 17.0837H5.36675C2.40841 17.0837 1.66675 16.3503 1.66675 13.4253V6.57533C1.66675 3.65033 2.40841 2.91699 5.36675 2.91699H11.2501" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.75 5.00033L15 6.25033L18.3333 2.91699" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  receipt: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M5.60841 16.417C6.29175 15.6837 7.33342 15.742 7.93342 16.542L8.77508 17.667C9.45008 18.5587 10.5417 18.5587 11.2167 17.667L12.0584 16.542C12.6584 15.742 13.7001 15.6837 14.3834 16.417C15.8667 18.0003 17.0751 17.4753 17.0751 15.2587V5.86699C17.0834 2.50866 16.3001 1.66699 13.1501 1.66699H6.85008C3.70008 1.66699 2.91675 2.50866 2.91675 5.86699V15.2503C2.91675 17.4753 4.13341 17.992 5.60841 16.417Z"  stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.66675 5.83301H13.3334" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.5 9.16699H12.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  book: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M18.3334 13.9501V3.89174C18.3334 2.89174 17.5167 2.15008 16.5251 2.23341H16.4751C14.7251 2.38341 12.0667 3.27508 10.5834 4.20841L10.4417 4.30008C10.2001 4.45008 9.80008 4.45008 9.55842 4.30008L9.35008 4.17508C7.86675 3.25008 5.21675 2.36674 3.46675 2.22508C2.47508 2.14174 1.66675 2.89174 1.66675 3.88341V13.9501C1.66675 14.7501 2.31675 15.5001 3.11675 15.6001L3.35841 15.6334C5.16675 15.8751 7.95842 16.7917 9.55842 17.6667L9.59175 17.6834C9.81675 17.8084 10.1751 17.8084 10.3917 17.6834C11.9917 16.8001 14.7917 15.8751 16.6084 15.6334L16.8834 15.6001C17.6834 15.5001 18.3334 14.7501 18.3334 13.9501Z"  stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 4.5752V17.0752" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.45825 7.0752H4.58325" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.08325 9.5752H4.58325" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  logout: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
<path d="M12.3333 12.1663L16.5 7.99961M16.5 7.99961L12.3333 3.83294M16.5 7.99961H6.5M9 12.1663C9 12.4126 9 12.5357 8.99085 12.6424C8.89569 13.7512 8.07905 14.6636 6.98753 14.8807C6.88252 14.9015 6.76001 14.9152 6.51529 14.9424L5.66412 15.0369C4.3854 15.179 3.74601 15.25 3.23805 15.0875C2.56078 14.8708 2.00785 14.3759 1.71765 13.7267C1.5 13.2398 1.5 12.5965 1.5 11.3099V4.68935C1.5 3.40274 1.5 2.75944 1.71765 2.27254C2.00785 1.62334 2.56078 1.12844 3.23805 0.91171C3.74601 0.749162 4.38538 0.820203 5.66412 0.962285L6.51529 1.05686C6.7601 1.08406 6.8825 1.09766 6.98753 1.11854C8.07905 1.33557 8.89569 2.24797 8.99085 3.35678C9 3.46347 9 3.58662 9 3.83294" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  switch_horizontal: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
<path d="M1.33325 13.1667H14.6666M14.6666 13.1667L11.3333 9.83333M14.6666 13.1667L11.3333 16.5M14.6666 4.83333H1.33325M1.33325 4.83333L4.66659 1.5M1.33325 4.83333L4.66659 8.16667" stroke="#4B5565" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  arrow_right: `<svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none" color="transparent">
<path d="M0.940186 12.2797L5.28685 7.93306C5.80019 7.41973 5.80019 6.57973 5.28685 6.06639L0.940186 1.71973" stroke="#CDD5DF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  user_edit: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10.0002 10.0003C12.3013 10.0003 14.1668 8.13485 14.1668 5.83366C14.1668 3.53247 12.3013 1.66699 10.0002 1.66699C7.69898 1.66699 5.8335 3.53247 5.8335 5.83366C5.8335 8.13485 7.69898 10.0003 10.0002 10.0003Z" stroke="#364152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.0085 13.1162L13.0585 16.0662C12.9418 16.1829 12.8335 16.3995 12.8085 16.5579L12.6502 17.6828C12.5919 18.0912 12.8752 18.3745 13.2835 18.3162L14.4085 18.1579C14.5668 18.1329 14.7919 18.0245 14.9002 17.9079L17.8502 14.9579C18.3585 14.4495 18.6002 13.8579 17.8502 13.1079C17.1085 12.3662 16.5169 12.6079 16.0085 13.1162Z" stroke="#364152" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5835 13.541C15.8335 14.441 16.5335 15.141 17.4335 15.391" stroke="#364152" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.8418 18.3333C2.8418 15.1083 6.05015 12.5 10.0002 12.5C10.8668 12.5 11.7001 12.625 12.4751 12.8583" stroke="#364152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  key: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M13.1667 6.49995C13.1667 6.07343 13.0039 5.64691 12.6785 5.32149C12.3531 4.99605 11.9265 4.83333 11.5 4.83333M11.5 11.5C14.2614 11.5 16.5 9.26142 16.5 6.5C16.5 3.73858 14.2614 1.5 11.5 1.5C8.73858 1.5 6.5 3.73858 6.5 6.5C6.5 6.72807 6.51527 6.95256 6.54484 7.17253C6.59348 7.53432 6.6178 7.71521 6.60143 7.82966C6.58438 7.94888 6.56267 8.01312 6.50391 8.11825C6.4475 8.21917  6.34809 8.31857 6.14928 8.51739L1.89052 12.7761C1.7464 12.9203 1.67433 12.9923 1.6228 13.0764C1.57711 13.151 1.54344 13.2323 1.52303 13.3173C1.5 13.4132 1.5 13.5151 1.5 13.719V15.1667C1.5 15.6334 1.5 15.8667 1.59083 16.045C1.67072 16.2018 1.79821 16.3293 1.95501 16.4092C2.13327 16.5 2.36662 16.5 2.83333 16.5H4.83333V14.8333H6.5V13.1667H8.16667L9.48261 11.8507C9.68143 11.6519 9.78083 11.5525 9.88175 11.4961C9.98688 11.4373 10.0511 11.4156 10.1703 11.3986C10.2848 11.3822 10.4657 11.4065 10.8275 11.4552C11.0474 11.4847 11.2719 11.5 11.5 11.5Z" stroke="#364152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  ticked: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M18.3332 9.23812V10.0048C18.3321 11.8018 17.7503 13.5504 16.6743 14.9896C15.5983 16.4289 14.0859 17.4819 12.3626 17.9914C10.6394 18.5009 8.79755 18.4397 7.1119 17.8169C5.42624 17.1942 3.98705 16.0432 3.00897 14.5357C2.03089 13.0282 1.56633 11.2448 1.68457 9.45172C1.80281 7.65859 2.49751 5.95173 3.66507 4.58568C4.83263 3.21964 6.41049 2.26761 8.16333 1.87158C9.91617 1.47555 11.7501 1.65674 13.3915 2.38812M18.3332 3.33335L9.99984 11.675L7.49984 9.17502" stroke="#124EDB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  arrow_right_drawer: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#252937" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.91699 10H16.942" stroke="#252937" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  ticked_circle: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M13.337 7.43624L13.337 7.43629L13.3429 7.43019C13.7687 6.98975 13.7771 6.28597 13.337 5.8458C12.9 5.40887 12.1835 5.40887 11.7465 5.8458L7.81675 9.77558L6.25363 8.21246C5.81671 7.77553 5.10012 7.77553 4.66319 8.21246C4.22627 8.64939 4.22627 9.36597 4.66319 9.8029L5.01675 9.44935L4.66319 9.8029L7.02153 12.1612C7.23089 12.3706 7.51597 12.491 7.81675 12.491C8.11753 12.491 8.4026 12.3706 8.61197 12.1612L13.337 7.43624ZM1.16675 8.99935C1.16675 4.68382 4.68456 1.16602 9.00008 1.16602C13.3156 1.16602 16.8334 4.68382 16.8334 8.99935C16.8334 13.3149 13.3156 16.8327 9.00008 16.8327C4.68456 16.8327 1.16675 13.3149 1.16675 8.99935Z" fill="#124EDB" stroke="#124EDB"/>
</svg>`,
  card_circle: `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7"/>
<path d="M18 28.6104H35" stroke="#EB782F" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35 26.2798V33.4298C34.97 36.2798 34.19 36.9998 31.22 36.9998H21.78C18.76 36.9998 18 36.2498 18 33.2698V26.2798C18 23.5798 18.63 22.7098 21 22.5698C21.24 22.5598 21.5 22.5498 21.78 22.5498H31.22C34.24 22.5498 35 23.2998 35 26.2798Z" stroke="#EB782F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38 22.73V29.72C38 32.42 37.37 33.29 35 33.43V26.28C35 23.3 34.24 22.55 31.22 22.55H21.78C21.5 22.55 21.24 22.56 21 22.57C21.03 19.72 21.81 19 24.78 19H34.22C37.24 19 38 19.75 38 22.73Z" stroke="#EB782F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.25 33.8096H22.97" stroke="#EB782F" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.1094 33.8096H28.5494" stroke="#EB782F" stroke-width="1.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" stroke-width="8"/>
</svg>`,
  logout_circle: `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7"/>
<path d="M32 33.0003L37 28.0003M37 28.0003L32 23.0003M37 28.0003H25M28 33.0003C28 33.2959 28 33.4437 27.989 33.5717C27.8748 34.9023 26.8949 35.9972 25.585 36.2576C25.459 36.2826 25.312 36.299 25.0184 36.3316L23.9969 36.4451C22.4625 36.6156 21.6952 36.7008 21.0857 36.5058C20.2729 36.2457 19.6094 35.6518 19.2612 34.8728C19 34.2885 19 33.5165 19 31.9726V24.028C19 22.4841 19 21.7121 19.2612 21.1278C19.6094 20.3488 20.2729 19.7549 21.0857 19.4948C21.6952 19.2998 22.4625 19.385 23.9969 19.5555L25.0183 19.669C25.3121 19.7017 25.459 19.718 25.585 19.743C26.8949 20.0035 27.8748 21.0983 27.989 22.4289C28 22.5569 28 22.7047 28 23.0003" stroke="#EB782F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" stroke-width="8"/>
</svg>`,
  package: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
<path d="M14.1666 4.85221L8.49998 8.00036M8.49998 8.00036L2.83331 4.85221M8.49998 8.00036L8.5 14.3337M14.5 10.7061V5.29468C14.5 5.06625 14.5 4.95204 14.4663 4.85017C14.4366 4.76005 14.3879 4.67733 14.3236 4.60754C14.2509 4.52865 14.151 4.47318 13.9514 4.36224L9.01802 1.6215C8.82895 1.51646 8.73442 1.46395 8.6343 1.44336C8.5457 1.42513 8.45431 1.42513 8.3657 1.44336C8.26559 1.46395 8.17105 1.51646 7.98198 1.6215L3.04865 4.36225C2.84896 4.47318 2.74912 4.52865 2.67642 4.60754C2.61211 4.67733 2.56343 4.76005 2.53366 4.85017C2.5 4.95204 2.5 5.06625 2.5 5.29468V10.7061C2.5 10.9345 2.5 11.0487 2.53366 11.1506C2.56343 11.2407 2.61211 11.3234 2.67642 11.3932C2.74912 11.4721 2.84897 11.5276 3.04865 11.6385L7.98198 14.3793C8.17105 14.4843 8.26559 14.5368 8.3657 14.5574C8.45431 14.5756 8.5457 14.5756 8.6343 14.5574C8.73442 14.5368 8.82895 14.4843 9.01802 14.3793L13.9514 11.6385C14.151 11.5276 14.2509 11.4721 14.3236 11.3932C14.3879 11.3234 14.4366 11.2407 14.4663 11.1506C14.5 11.0487 14.5 10.9345 14.5 10.7061Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5 6.33333L5.5 3"  stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  currency_dollar_circle: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clip-path="url(#clip0_2810_35266)">
<path d="M5.66659 9.77843C5.66659 10.6375 6.36303 11.334 7.22214 11.334H8.66659C9.58706 11.334 10.3333 10.5878 10.3333 9.66732C10.3333 8.74684 9.58706 8.00065 8.66659 8.00065H7.33325C6.41278 8.00065 5.66659 7.25446 5.66659 6.33398C5.66659 5.41351 6.41278 4.66732 7.33325 4.66732H8.7777C9.63681 4.66732 10.3333 5.36376 10.3333 6.22287M7.99992 3.66732V4.66732M7.99992 11.334V12.334M14.6666 8.00065C14.6666 11.6826 11.6818 14.6673 7.99992 14.6673C4.31802 14.6673 1.33325 11.6826 1.33325 8.00065C1.33325 4.31875 4.31802 1.33398 7.99992 1.33398C11.6818 1.33398 14.6666 4.31875 14.6666 8.00065Z" stroke="#4B5565" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2810_35266">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>`,
  card: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path d="M1.33325 5.66992H14.6666" stroke="#4B5565" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4 11.0039H5.33333" stroke="#4B5565" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 11.0039H9.66667" stroke="#4B5565" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.29325 2.33594H11.6999C14.0733 2.33594 14.6666 2.9226 14.6666 5.2626V10.7359C14.6666 13.0759 14.0733 13.6626 11.7066 13.6626H4.29325C1.92659 13.6693 1.33325 13.0826 1.33325 10.7426V5.2626C1.33325 2.9226 1.92659 2.33594 4.29325 2.33594Z" stroke="#4B5565" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  profile_circle: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10.0999 10.65C10.0416 10.6417 9.9666 10.6417 9.89994 10.65C8.43327 10.6 7.2666 9.39998 7.2666 7.92498C7.2666 6.41665 8.48327 5.19165 9.99993 5.19165C11.5083 5.19165 12.7333 6.41665 12.7333 7.92498C12.7249 9.39998 11.5666 10.6 10.0999 10.65Z" stroke="#364152" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6166 16.15C14.1333 17.5083 12.1666 18.3333 9.99997 18.3333C7.8333 18.3333 5.86663 17.5083 4.3833 16.15C4.46663 15.3667 4.96663 14.6 5.8583 14C8.14163 12.4833 11.875 12.4833 14.1416 14C15.0333 14.6 15.5333 15.3667 15.6166 16.15Z" stroke="#364152" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99984 18.3334C14.6022 18.3334 18.3332 14.6024 18.3332 10C18.3332 5.39765 14.6022 1.66669 9.99984 1.66669C5.39746 1.66669 1.6665 5.39765 1.6665 10C1.6665 14.6024 5.39746 18.3334 9.99984 18.3334Z" stroke="#364152" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  send: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M6.16689 5.2668L13.2419 2.90846C16.4169 1.85013 18.1419 3.58346 17.0919 6.75846L14.7336 13.8335C13.1502 18.5918 10.5502 18.5918 8.96689 13.8335L8.26689 11.7335L6.16689 11.0335C1.40856 9.45013 1.40856 6.85846 6.16689 5.2668Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.4248 11.3755L11.4081 8.38379" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  export: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M13.7002 7.41699C16.7002 7.67533 17.9252 9.21699 17.9252 12.592V12.7003C17.9252 16.4253 16.4336 17.917 12.7086 17.917H7.28356C3.55856 17.917 2.06689 16.4253 2.06689 12.7003V12.592C2.06689 9.24199 3.27523 7.70033 6.22523 7.42533" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 12.4999V3.0166" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.7918 4.87467L10.0002 2.08301L7.2085 4.87467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  download: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 7.33333L9 11.5M9 11.5L4.83333 7.33333M9 11.5V1.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  tenant_management: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M17.5667 7.14987V12.8498C17.5667 13.7832 17.0667 14.6499 16.2584 15.1249L11.3084 17.9832C10.5 18.4499 9.50005 18.4499 8.68338 17.9832L3.73338 15.1249C2.92505 14.6582 2.42505 13.7915 2.42505 12.8498V7.14987C2.42505 6.21653 2.92505 5.34983 3.73338 4.87483L8.68338 2.0165C9.49172 1.54984 10.4917 1.54984 11.3084 2.0165L16.2584 4.87483C17.0667 5.34983 17.5667 6.2082 17.5667 7.14987Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 9.16651C11.0724 9.16651 11.9417 8.29719 11.9417 7.22483C11.9417 6.15248 11.0724 5.2832 10 5.2832C8.92766 5.2832 8.05835 6.15248 8.05835 7.22483C8.05835 8.29719 8.92766 9.16651 10 9.16651Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3334 13.8831C13.3334 12.3831 11.8417 11.1665 10.0001 11.1665C8.15841 11.1665 6.66675 12.3831 6.66675 13.8831" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  message_question: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M14.1667 15.3583H10.8334L7.12507 17.8249C6.57507 18.1916 5.83341 17.8 5.83341 17.1333V15.3583C3.33341 15.3583 1.66675 13.6916 1.66675 11.1916V6.19157C1.66675 3.69157 3.33341 2.0249 5.83341 2.0249H14.1667C16.6667 2.0249 18.3334 3.69157 18.3334 6.19157V11.1916C18.3334 13.6916 16.6667 15.3583 14.1667 15.3583Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 9.4668V9.29183C10 8.72516 10.35 8.42515 10.7 8.18348C11.0417 7.95015 11.3833 7.65016 11.3833 7.10016C11.3833 6.33349 10.7667 5.7168 10 5.7168C9.23334 5.7168 8.6167 6.33349 8.6167 7.10016" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99617 11.4582H10.0037" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  print: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M6.0415 5.83366H13.9582V4.16699C13.9582 2.50033 13.3332 1.66699 11.4582 1.66699H8.5415C6.6665 1.66699 6.0415 2.50033 6.0415 4.16699V5.83366Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3332 12.5V15.8333C13.3332 17.5 12.4998 18.3333 10.8332 18.3333H9.1665C7.49984 18.3333 6.6665 17.5 6.6665 15.8333V12.5H13.3332Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.5 8.33301V12.4997C17.5 14.1663 16.6667 14.9997 15 14.9997H13.3333V12.4997H6.66667V14.9997H5C3.33333 14.9997 2.5 14.1663 2.5 12.4997V8.33301C2.5 6.66634 3.33333 5.83301 5 5.83301H15C16.6667 5.83301 17.5 6.66634 17.5 8.33301Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1668 12.5H13.1585H5.8335" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.8335 9.16699H8.3335" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  task: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M9.16699 16.25H17.5003" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16699 10.4167H17.5003" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16699 4.58334H17.5003" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 4.58333L3.33333 5.41666L5.83333 2.91666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 10.4167L3.33333 11.25L5.83333 8.75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 16.25L3.33333 17.0833L5.83333 14.5833" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  align_left: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M3.75 6.375H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 10.125H15.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 13.875H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 17.625H15.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  align_center: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M3.75 6.375H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 10.125H18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 13.875H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 17.625H18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  align_justify: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M3.75 6.375H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 10.125H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 13.875H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 17.625H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  align_right: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M3.75 6.375H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.25 10.125H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 13.875H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.25 17.625H20.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  eye: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12Z" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  eye_invisible: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M14.53 9.46998L9.47004 14.53C8.82004 13.88 8.42004 12.99 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C12.99 8.41998 13.88 8.81998 14.53 9.46998Z" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C3.67997 15.84 4.59997 16.91 5.59997 17.77" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.42004 19.53C9.56004 20.01 10.77 20.27 12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39999C20.78 8.87999 20.42 8.38999 20.05 7.92999" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.47 14.53L2 22" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 2L14.53 9.47" stroke="#252937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
}
