<div class="section_right">
  <div class="section_right--heading">
    Where all your HR features are integrated into one platform, brings more convenience and makes
    HR processes manageable and easy to access.
  </div>
  <div class="decor">
    <img src="assets/images/christmas-stars-top.png" class="decor__top--large" />
    <img src="assets/images/christmas-stars-top.png" class="decor__top--small" />
  </div>
  <div class="section_right--img">
    <div class="card">
      <div class="decor_ellipse">
        <div class="box_ellipse--transparent"></div>
        <div class="box_ellipse--full"></div>
      </div>
      <div class="container_time_off">
        <img src="assets/images/new_application.png" class="new_application" />
        <img src="assets/images/timeoff.png" alt="time_off" class="time_off" />
      </div>

      <div class="container_personnel">
        <img src="assets/images/christmas-stars-blue.png" class="decor__blue" />
        <img src="assets/images/personnel.png" alt="personnel" class="personnel" />
      </div>
      <div class="box-empty"></div>
    </div>
    <div class="card card-bottom">
      <img
        src="assets/images/employee_development.png"
        alt="employee_development"
        class="employee_develop"
      />
      <img src="assets/images/employee_role.png" alt="employee_role" class="employee_role" />
    </div>
  </div>
  <img src="assets/images/christmas-stars-bottom.png" class="decor__bottom" />
</div>
