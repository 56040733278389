import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import APIs from '../commons/consts/API'
import { CommonService } from '../commons/services/connection/common.service'
import { HttpObservablesService } from '../commons/services/connection/http-observables.service'
import { environment } from '../environments/environment'
import { RequestPackage } from '../interfaces/product-management'

const SERVER_URL = environment.API_WS_SERVER_URL
@Injectable({
  providedIn: 'root'
})
export class ProductManagementService {
  constructor(
    protected httpObservablesService: HttpObservablesService,
    protected commonService: CommonService,
    private http: HttpClient
  ) {}

  getListProduct(): Observable<any> {
    let url = SERVER_URL + APIs.PRODUCT
    return this.http.get(url)
  }

  getListPackage(): Observable<any> {
    let url = SERVER_URL + APIs.PACKAGE_LIST
    return this.http.get(url)
  }

  getListPackageAdmin(limit: number, offset: number): Observable<any> {
    let url = SERVER_URL + APIs.PACKAGE_LIST_ADMIN + `?limit=${limit}&offset=${offset}`
    return this.http.get(url)
  }

  createPackage(body: RequestPackage): Observable<any> {
    let url = SERVER_URL + APIs.PACKAGE_LIST
    return this.http.post(url, body)
  }

  editPackage(body: RequestPackage): Observable<any> {
    let url = SERVER_URL + APIs.PACKAGE_LIST + '/' + body.id
    return this.http.put(url, body)
  }
}
