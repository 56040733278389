export const reloadPage = (): void => {
  window.location.reload()
}
export const removeObjectWithId = (arr: Array<any>, id: string | number) => {
  const arrCopy = [...arr]
  const objWithIdIndex = arrCopy.findIndex((obj) => obj.id === id)

  if (objWithIdIndex > -1) {
    arrCopy.splice(objWithIdIndex, 1)
  }

  return arrCopy
}

export const generateSTT = (id: number | string) => {
  const totalLengthDefault = 5
  const totalLengthId = id.toString().length
  if (totalLengthId > 5) {
    return '#####'
  } else {
    return new Array(totalLengthDefault - totalLengthId).fill(0).join('') + id
  }
}

export const blobToB64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
