<app-tabs #tabs [listTab]="listTab" (changeTab)="(tabs.id)" [visibleCountNumber]="false"></app-tabs>
<div nz-row [nzGutter]="[24, 0]" class="container-section" *ngIf="tabs.id === 1">
  <div nz-col nzSpan="12" class="section-left">
    <app-heading-page
      title="FAQ"
      subtitle="Find all answer for your questions and request help"
    ></app-heading-page>
    <nz-collapse nzExpandIconPosition="right" [nzBordered]="false">
      <nz-collapse-panel
        *ngFor="let item of listFAQ$ | async"
        [nzHeader]="item.name"
        [nzShowArrow]="item.list_data.length > 0"
      >
        <nz-radio-group *ngIf="item.list_data.length > 0" [(ngModel)]="listDetailFAQ">
          <label nz-radio [nzValue]="detailItem" *ngFor="let detailItem of item.list_data">
            {{ detailItem.name }}
          </label>
        </nz-radio-group>
      </nz-collapse-panel>
    </nz-collapse>
  </div>

  <div nz-col nzSpan="12" class="section-right">
    <ng-container *ngIf="listDetailFAQ">
      <h3 class="section-right-title">{{ listDetailFAQ.name }}</h3>
      <div [innerHTML]="listDetailFAQ.note"></div>
    </ng-container>

    <p class="warning-enter-form">Enter the full content in the form below so we can help you</p>

    <form nz-form class="form-phone-number" [formGroup]="formPhoneNumber" nzLayout="vertical">
      <nz-form-item>
        <nz-form-label>Phone number</nz-form-label>
        <nz-form-control nzErrorTip="Please input your phone number!">
          <input nz-input formControlName="phoneNumber" placeholder="Enter your phone number" />
          <p style="margin-top: 6px">
            If you leave this field blank, we will use the registered phone number to contact you
          </p>
        </nz-form-control>
      </nz-form-item>
    </form>

    <div class="container-rte">
      <h4 class="container-rte-title">Content</h4>
      <quill-editor [styles]="{ height: '200px' }" #editor>
        <div quill-editor-toolbar>
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="custom-align" (click)="onChangeAlign('justify')">
            <i nz-icon nzType="icon:align_left" class="custom-font"></i>
          </button>
          <button class="custom-align" (click)="onChangeAlign('center')">
            <i nz-icon nzType="icon:align_center" class="custom-font"></i>
          </button>
          <button class="custom-align" (click)="onChangeAlign('justify')">
            <i nz-icon nzType="icon:align_justify" class="custom-font"></i>
          </button>
          <button class="custom-align" (click)="onChangeAlign('right')">
            <i nz-icon nzType="icon:align_right" class="custom-font"></i>
          </button>
        </div>
      </quill-editor>
    </div>

    <div class="file-upload">
      <label class="file-upload-label" for="file-upload">Attachments</label>
      <input type="file" class="file-upload-input" id="file-upload" />
      <p class="file-upload-warning">Maximum support 8mb</p>
    </div>

    <div class="container_btn">
      <button nz-button nzType="primary" class="btn-submit" (click)="submitRequestTicket()">
        Submit
      </button>
    </div>
  </div>
</div>

<app-history-request *ngIf="tabs.id === 2"></app-history-request>
