import { ValidationService } from './../../../commons/services/validation.service'
import { Location } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { GeneralService } from '../../../commons/services/general.service'
import { URL_PAGE } from '../../../commons/consts'
import { AuthServices } from '../../../commons/services/authen/auth.service'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../commons/enums/common.enum'
import { SessionStorageService } from '../../../commons/services/session/session-storage.service'
import { StorageKey } from '../../../commons/enums/storage-key.enum'
import { Regexs } from '../../../configs/regexs'

@Component({
  selector: 'app-mandatory-info',
  templateUrl: './mandatory-info.component.html',
  styleUrls: ['./mandatory-info.component.scss']
})
export class MandatoryInfoComponent implements OnInit, OnDestroy {
  mandatoryForm!: FormGroup
  options: string[] = []
  companyName = ''
  companyAddress = ''
  constructor(
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private general: GeneralService,
    private validation: ValidationService,
    private authService: AuthServices,
    private httpObservable: HttpObservablesService,
    private sessionService: SessionStorageService
  ) {
    const hasRegister = this.sessionService.get(StorageKey.HAS_REGISTER)
    if (!hasRegister) {
      this.router.navigateByUrl('/')
    } else {
      return
    }
  }

  ngOnDestroy(): void {
    this.sessionService.set(StorageKey.HAS_REGISTER, false)
  }

  ngOnInit(): void {
    this.mandatoryForm = this.fb.group({
      userName: [null, [Validators.required, Validators.pattern(Regexs.NAME)]],
      companyName: [null, [Validators.required, Validators.pattern(Regexs.INPUT_NAME)]],
      companyAddress: [null, [Validators.required, Validators.pattern(Regexs.ADDRESS)]],
      phoneNumber: [null, [Validators.required, Validators.pattern(Regexs.PHONE_NUMBER)]],
      location: [null, [Validators.required]],
      yourSite: [null, [Validators.required, Validators.pattern(Regexs.DOMAIN)]]
    })
  }

  get yourSite() {
    return this.mandatoryForm.controls.yourSite
  }
  onInputEmail(e: Event): void {
    const value = (e.target as HTMLInputElement).value
    if (!value || value.indexOf('@') >= 0) {
      this.options = []
    } else {
      this.options = ['gmail.com', 'outlook.com', 'innotech-vn.com'].map(
        (domain) => `${value}@${domain}`
      )
    }
  }

  onBlurYourSite() {
    const valueYourSite: string = this.yourSite.value
    this.yourSite.setValue(valueYourSite.toLowerCase())
  }

  submitForm() {
    const controls = this.mandatoryForm.controls
    if (this.mandatoryForm.valid) {
      const dataRegisterForm: any = this.location.getState()
      this.general.registerTenant = {
        email: dataRegisterForm.email,
        password: dataRegisterForm.password,
        companyName: controls.companyName.value,
        address: controls.companyAddress.value,
        tenantId: controls.yourSite.value,
        phoneNumber: controls.phoneNumber.value,
        username: controls.userName.value,
        location: controls.location.value
      }
      this.authService.register(this.general.registerTenant).subscribe(
        (res: any) => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.REGISTER,
            title: TITLE_PAGE.REGISTER
          })
          this.router.navigateByUrl(URL_PAGE.WAITING_EMAIL)
        },
        (err: { error: { message: any } }) => {
          this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.REGISTER })
        }
      )
    } else {
      this.validation.checkAllValidation(controls)
    }
  }
}
