<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="titleDrawer"
  (nzOnClose)="handleCancel()"
  nzWidth="480px"
  [nzFooter]="footerTpl"
>
  <ng-container *nzDrawerContent>
    <p class="content">Invite members to join your system on Kingwork.</p>
    <p class="content">
      You can invite members by sharing the invitation link with them or send an invitation email.
    </p>

    <form nz-form class="form-invite" [formGroup]="formInvite" nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzFor="inviteLink">Invitation Link</nz-form-label>
        <nz-form-control nzErrorTip="Please input your invitation link">
          <nz-input-group nzCompact class="input-group">
            <input
              #inputEl
              style="width: 79%"
              class="input-invite"
              id="inviteLink"
              formControlName="inviteLink"
              type="text"
              nz-input
              placeholder="site.kingwork.vn/invitation"
            />
            <span nz-tooltip nzTooltipTitle="Copy this" nzTooltipTrigger="click">
              <div nz-button class="btn-copy" (click)="copyInputInvite(inputEl)">
                <span nz-icon nzType="copy" nzTheme="outline"></span>
                <span>Copy</span>
              </div>
            </span>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="sendEmail">Send Email</nz-form-label>
        <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
          <input
            id="sendEmail"
            formControlName="sendEmail"
            type="text"
            nz-input
            placeholder="Enter email address to send"
          />
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')"
              >Please input your email!</ng-container
            >
            <ng-container *ngIf="control.hasError('pattern')"
              >Please input your format email!</ng-container
            >
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
</nz-drawer>

<ng-template #titleDrawer>
  <app-header-drawer title="Invite Member" (cancel)="handleCancel()"></app-header-drawer>
</ng-template>

<ng-template #footerTpl>
  <div class="group-button">
    <button nz-button (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="onSubmit()">Send Invitation</button>
  </div>
</ng-template>
