import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { GeneralService } from '../../../../../commons/services/general.service'
import { TableService } from '../../../../../commons/services/table.service'
import { ResponseAccount } from '../../../../../interfaces/user-manage'

interface ListData {
  id: string
  username: string
  email: string
  role: string
}
@Component({
  selector: 'app-selected-user-card',
  templateUrl: './selected-user-card.component.html',
  styleUrls: ['./selected-user-card.component.scss']
})
export class SelectedUserCardComponent implements OnInit {
  @Input() listData: ListData[] = []
  @Input() isShowBtnRemove = true
  @Output() remove: EventEmitter<string> = new EventEmitter()
  constructor(
    private generalService: GeneralService,
    private table: TableService<ResponseAccount>
  ) {}

  ngOnInit(): void {}

  removeItem(id: string) {
    this.table.removeChecked(id)
    this.generalService.removeSelectedData(id)
  }
}
