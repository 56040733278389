<div class="container-waiting-email" nz-row nzJustify="center" nzAlign="middle">
  <div class="left-section" nz-col nzXs="24" nzLg="12">
    <i class="left-section-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"
          fill="#EB782F"
        />
      </svg>
    </i>
    <h1 class="left-section-title">Thank you</h1>
    <div class="left-section-description">
      <p>Your registeration submission is received and we will contact you soon.</p>
      <p>Meanwhile, you can visit our website for more information</p>
    </div>
    <button nz-button nzType="primary" (click)="goKingWork()">Visit our website</button>
  </div>
  <div class="right-section" nz-col nzXs="24" nzLg="12"></div>
</div>
