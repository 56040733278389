import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import APIs from '../commons/consts/API'
import { TYPE_SITE } from '../commons/enums/common.enum'
import { environment } from '../environments/environment'
import { RequestRole } from '../interfaces/roles-manage'

const SERVER_URL = environment.API_WS_SERVER_URL
@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {
  // moduleService: any;
  constructor(private http: HttpClient) {}

  getListRole(offset: number, limit: number, status?: TYPE_SITE): Observable<any> {
    return this.http.get(
      SERVER_URL +
        APIs.ROLE +
        `?limit=${limit}&offset=${offset}` +
        `&status=${status ? status : ''}`
    )
  }

  getListRoleByType(offset: number, limit: number, type?: TYPE_SITE): Observable<any> {
    return this.http.get(
      SERVER_URL + APIs.ROLE + `?limit=${limit}&offset=${offset}` + (type ? `&type=${type}` : '')
    )
  }

  getRoleDetail(id: string): Observable<any> {
    return this.http.get(SERVER_URL + APIs.ROLE + `/${id}`)
  }

  createRole(body: RequestRole) {
    let url = SERVER_URL + APIs.ROLE
    return this.http.post(url, body)
  }

  editRole(body: RequestRole, roleId: number) {
    let url = SERVER_URL + APIs.ROLE + `/${roleId}`
    return this.http.put(url, body)
  }
}
