<div>
  <app-heading-page
    title="Subscription"
    subtitle="Manage your ideal subscription plan"
  ></app-heading-page>

  <div nz-row class="container-list-subscription">
    <div nz-col nzSpan="6" class="col">
      <div class="user-distribution">
        <app-heading-page
          title="User Distribution"
          subtitle="View your user distribution"
        ></app-heading-page>
        <nz-divider [nzDashed]="true" class="divider"></nz-divider>
        <nz-progress
          *ngIf="totalUserCurrent && totalUser"
          [nzFormat]="formatText"
          [nzStrokeWidth]="10"
          [nzGapDegree]="140"
          [nzGapPosition]="'bottom'"
          [nzStatus]="totalUserCurrent > totalUser ? 'exception' : undefined"
          [nzPercent]="totalUserCurrent < totalUser ? (100 * totalUserCurrent) / totalUser : 100"
          nzType="dashboard"
          class="progress-circle"
        ></nz-progress>
      </div>
    </div>
    <app-card
      nz-col
      nzSpan="6"
      [class.focus-card]="item.current"
      *ngFor="let item of listPackage; let indexItem = index"
      class="col"
    >
      <img src="assets/images/badge.png" *ngIf="item.current" class="badge-focus" />
      <app-heading-page [title]="item.name" [subtitle]="item.description"></app-heading-page>
      <nz-divider [nzDashed]="true" class="divider"></nz-divider>
      <div class="container_price">
        <span class="price-number">{{ item.userPrice | currency : 'VND' }}</span>
        <span class="price-suffix">each user</span>
      </div>
      <button
        *ngIf="checkHasUpgradePackage(indexItem, item)"
        nz-button
        class="btn btn-outline"
        [ngClass]="{
          'btn-active': item?.id === subscriptionClick?.id,
          'btn-not-active': item?.id !== subscriptionClick?.id
        }"
        (click)="showSelectPackage(item)"
      >
        Upgrade
      </button>
    </app-card>
  </div>
  <app-table-subscription
    [listData]="featureList"
    [rowStorage]="rowStorage"
  ></app-table-subscription>
</div>
<ng-template #badge_focus>
  <div class="badge-focus">
    <img src="assets/images/christmas-stars-bottom.png" />
    <span class="text">Current</span>
  </div>
</ng-template>
<app-upgrade-drawer [visible]="visible" (cancel)="cancelDrawerUpgrade($event)"></app-upgrade-drawer>
