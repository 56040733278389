import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { UserManagementService } from '../../api/user-management.service'
import { URL_PAGE } from '../../commons/consts'
import { TITLE_PAGE } from '../../commons/enums/common.enum'
import { StorageKey } from '../../commons/enums/storage-key.enum'
import { AuthServices } from '../../commons/services/authen/auth.service'
import { HttpObservablesService } from '../../commons/services/connection/http-observables.service'
import { SessionStorageService } from '../../commons/services/session/session-storage.service'
import { ModalConfirmComponent } from '../../shared/components/modal-confirm/modal-confirm.component'
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  isCollapsed = false
  width_sidebar = 256
  visibleProfile = false
  companyName!: string
  URL_PAGE = URL_PAGE
  TITLE_PAGE = TITLE_PAGE
  userDetail = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    address: ''
  }

  @ViewChild(ModalConfirmComponent) modalConfirm!: ModalConfirmComponent
  constructor(
    public authService: AuthServices,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private httpObservable: HttpObservablesService,
    private userManagement: UserManagementService
  ) {}
  ngOnInit(): void {
    this.companyName = JSON.parse(this.sessionStorage.getDataUser() as string)?.companyName
  }

  logOutUser = () => {
    this.authService.logout()
    this.router.navigateByUrl(URL_PAGE.LOGIN)
  }

  getProfileUserLogin() {
    this.userManagement.getUserDetailLogin().subscribe(
      (res) => {
        this.userDetail = res.data
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.TENANT_MANAGEMENT
        })
      }
    )
  }

  showProfileTenant() {
    this.visibleProfile = true
    this.getProfileUserLogin()
  }

  onCancelProfile() {
    this.visibleProfile = false
  }

  goEditUser = () => {
    // const id = this.userDetail.id
    // this.router.navigateByUrl(`${URL_PAGE.ACCOUNT_MANAGEMENT}/${URL_PAGE.EDIT_ACCOUNT}/${id}`)
    // this.generalService.addSelectedData(id, [this.userDetail])
    this.sessionStorage.set(StorageKey.EDIT_ACCOUNT, true)
  }
}
