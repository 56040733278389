import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { PaymentService } from '../../../api/payment.service'
import { URL_PAGE } from '../../../commons/consts'
import { PAYMENT_STATUS, TITLE_PAGE } from '../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'
import { GeneralService } from '../../../commons/services/general.service'
import { TableService } from '../../../commons/services/table.service'
import { DATE_FORMAT } from '../../../configs/constants'
import { PaymentListTable, ResponsePayment } from '../../../interfaces/subscription'

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public formatDate = DATE_FORMAT.dd_MM_yyyy
  public visiblePaymentDetail = false
  public visibleUpgrade = false
  public date: null | Date = null
  public PAYMENT_STATUS = PAYMENT_STATUS
  public listOfDisplayData: PaymentListTable[] = []
  // public listPaymentHistory: ResponsePayment[] = []
  // public paginationPaymentHistory = {
  //   total: 1,
  //   pageIndex: 1
  // }
  // public limitDataPaymentHistory = 3

  public listOfColumn: any = [
    { title: 'Product Name' },
    { title: 'Package Name' },
    { title: 'Status' },
    { title: 'Total User', align: 'right' },
    { title: 'Start Time', align: 'right' },
    { title: 'End Time', align: 'right' }
  ]

  constructor(
    public tableService: TableService<PaymentListTable>,
    public general: GeneralService,
    private paymentService: PaymentService,
    private httpObservable: HttpObservablesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tableService.loading = true
    this.getListPayment()
  }

  onChangeDate(result: Date | null) {
    this.date = result
  }

  getListPayment() {
    return this.paymentService.paymentList(0, 10).subscribe(
      (res: any) => {
        this.tableService.loading = false
        if (res?.data.length > 0) {
          this.tableService.listOfData = res?.data?.map((item: ResponsePayment) => {
            return {
              ...item,
              name: item?.packageHistory?.name,
              userPrice: item?.packageHistory?.userPrice,
              productName: item?.product?.name
            }
          })
          this.listOfDisplayData = [...this.tableService.listOfData]
        }
      },
      (err: { error: { message: string } }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.PAYMENTS
        })
      }
    )
  }

  // getHistoryPayment(offset: number, limit: number) {
  //   this.paymentService.paymentList(offset, limit).subscribe(
  //     (res) => {
  //       this.paginationPaymentHistory = {
  //         total: res.total,
  //         pageIndex: res.currentPage
  //       }

  //       this.listPaymentHistory = res?.data.map((item: any) => {
  //         return {
  //           ...item,
  //           updatedAt: dateFormat(item.updatedAt, DATE_FORMAT.DD_MM_YYYY) || ''
  //         }
  //       })
  //     },
  //     (error: { err: any }) => {
  //       this.httpObservable.handleError({
  //         error: error.err,
  //         title: TITLE_PAGE.BILLING_INVOICING
  //       })
  //     }
  //   )
  // }

  // handleChangePage(pageIndex: number) {
  //   this.getHistoryPayment(pageIndex - 1, this.limitDataPaymentHistory)
  // }

  goPayment(item: ResponsePayment, event: Event) {
    if (this.isDisabledMenuAction(item)) {
      event.stopPropagation()
    } else {
      let body = {
        id: item.id,
        packageId: item.packageId,
        totalUser: item.totalUser,
        period: item.period
      }
      this.paymentService.registerPayment(body).subscribe(
        (res: any) => {
          window.location.href = res.message
        },
        (err: { error: { message: string } }) => {
          this.httpObservable.handleError({
            error: err.error,
            title: TITLE_PAGE.PAYMENTS
          })
        }
      )
    }
  }

  goAddUser() {
    this.router.navigateByUrl(URL_PAGE.ACCOUNT_MANAGEMENT + '/' + URL_PAGE.ADD_ACCOUNT)
  }
  showSelectPackage(item: ResponsePayment, event: Event) {
    if (this.isDisabledMenuAction(item)) {
      event.stopPropagation()
    } else {
      this.visibleUpgrade = true
      this.general.selectedItem.next(item)
    }
  }

  showPaymentDetail(item: ResponsePayment) {
    this.visiblePaymentDetail = true
    this.general.selectedItem.next(item)
  }

  isDisabledMenuAction(item: ResponsePayment) {
    if (item.status === PAYMENT_STATUS.SUCCESS || item.status === PAYMENT_STATUS.FAIL) {
      return true
    }
    return false
  }
}
