import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { GeneralService } from '../../../commons/services/general.service'
import { DATE_FORMAT } from '../../../configs/constants'
import { ResponsePayment } from '../../../interfaces/subscription'
import { numberNoComma } from '../../../utils/format'

@Component({
  selector: 'app-detail-payment-invoice',
  templateUrl: './detail-payment-invoice.component.html',
  styleUrls: ['./detail-payment-invoice.component.scss']
})
export class DetailPaymentInvoiceComponent implements OnInit {
  public numberNoComma = numberNoComma
  public paymentDetail!: ResponsePayment
  public formatDate = DATE_FORMAT.dd_MM_yyyy
  public rowLayout = { colTitle: 11, colDescription: 13 }
  @Input() visible: boolean = false
  @Input() titleHeader: string = ''
  @Output() cancel = new EventEmitter(false)
  constructor(private general: GeneralService) {}

  ngOnInit(): void {
    this.getPaymentDetail()
  }

  getPaymentDetail() {
    this.general.selectedItem.subscribe((item) => (this.paymentDetail = { ...item }))
  }

  handleCancel() {
    this.cancel.emit()
  }
}
