<div nz-row [nzGutter]="[24, 24]" *ngIf="listProduct">
  <div
    *ngFor="let item of listProduct"
    nz-col
    [nzXs]="24 / 1"
    [nzMd]="24 / 2"
    [nzLg]="24 / 3"
    [nzXXl]="24 / 4"
  >
    <app-card addClassActive nameClass="focus-card">
      <i nz-icon nzType="custom_icon:ticked" class="icon_ticked"></i>
      <img [src]="item.avatar || defaultAvatar" class="avatar" />
      <div class="detail">
        <h3 class="title">{{ item.name }}</h3>
        <p class="description">{{ item.description }}</p>
      </div>
    </app-card>
  </div>
</div>
