<app-drawer titleHeader="Reset Password" (cancel)="handleCancel()" [visible]="visible">
  <ng-container drawerContent>
    <p class="content">Are you sure you want to reset user password?</p>
    <p class="content mb-24">User will receive a link to create a new password via email.</p>
    <app-selected-user-card
      [listData]="generalService.selectedData"
      [isShowBtnRemove]="false"
    ></app-selected-user-card>
    <form nz-form [formGroup]="resetPassUserForm" nzLayout="vertical" class="form-reset-pass">
      <nz-form-item>
        <nz-form-label nzFor="email" nzRequired>Email</nz-form-label>
        <nz-form-control [nzErrorTip]="emailErrorTpl">
          <input
            nz-input
            type="email"
            formControlName="email"
            placeholder="Enter your email address"
            id="email"
          />
          <ng-template #emailErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')"
              >Please input your email!</ng-container
            >
            <ng-container *ngIf="control.hasError('pattern')"
              >Please input your format email!</ng-container
            >
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <ng-container drawerBtn>
    <button nz-button (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="onSubmit()">Reset Password</button>
  </ng-container>
</app-drawer>
