export enum StorageKey {
  LOGINED_AGENT = 'LOGINED_AGENT',
  LOGINED_SESSION = 'LOGINED_SESSION',
  FIRST_LOGIN = 'FIRST_LOGIN',
  TOKEN_KEY = 'auth-token',
  USER_KEY = 'auth-user',
  HAS_REGISTER = 'hasRegister',
  EDIT_ACCOUNT = 'editAccount',
  HAS_NEXT_PLAN = 'hasNextPlan',
  IS_LOGIN = 'isLogged'
}
