import { HttpClient } from '@angular/common/http'
import { ElementRef, Injectable, Renderer2 } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { L10nTranslationService } from 'angular-l10n'
import { NzNotificationDataOptions } from 'ng-zorro-antd/notification'
import { BehaviorSubject, Subject } from 'rxjs'
import { environment } from '../../environments/environment'
import { TYPE_MESSAGE } from '../enums/common.enum'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import * as moment from 'moment'
import { DATE_FORMAT } from '../../configs/constants'
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private translation: L10nTranslationService
  ) {
    this.siteURL = environment.HOME_URL
  }
  isShowSlickCarousel: boolean = true
  accessToken: any = ''
  visibleTemplatePDF = false
  dataPDF = []
  siteURL: string = ''
  selectedData: Array<any> = []
  forceRender: BehaviorSubject<string> = new BehaviorSubject<string>('')
  registerTenant: { [unit: string]: any } = {
    email: '',
    password: '',
    companyName: '',
    tenantId: '',
    phoneNumber: '',
    username: '',
    location: '',
    productSelected: ''
  }
  selectedItem = new Subject<any>()

  static isAdminLogin: boolean = true

  private notification = new Subject<any>()
  notificationMethodCall$ = this.notification.asObservable()
  callNotification(
    type: TYPE_MESSAGE,
    title: string,
    description: string,
    options?: NzNotificationDataOptions
  ) {
    this.notification.next({
      type,
      title,
      description,
      options
    })
  }

  addSelectedData(id: string | number, listOfData: Array<any>) {
    const item = listOfData.find((item) => item.id === id)!
    this.selectedData.push(item)
  }

  removeSelectedData(id: string | number) {
    const item = this.selectedData.find((item: any) => item.id === id)!
    const index = this.selectedData.indexOf(item)
    this.selectedData.splice(index, 1)
  }

  formatDateMDY(date: string, strFormat: string) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [month, day, year].join(strFormat)
  }

  formatDateDMY(date: string, strFormat: string) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join(strFormat)
  }

  htmlEncodeEntities(str: string) {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;')
  }
  htmlDecodeEntities(str: string) {
    return str
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
  }

  convertDMY2MDY(strDate: string) {
    if (strDate) {
      let aDate = strDate.split('/')
      let result = aDate[1] + '-' + aDate[0] + '-' + aDate[2]
      return this.formatDateMDY(result, '-')
    }
    return
  }

  openDialogMess(message: any, type: any) {
    // const dialogRef = this.dialog.open(DialogMessageComponent, {
    //   // width: '50%',
    //   position: {
    //     top: '200px'
    //   },
    //   data: {
    //     msg: message,
    //     type: type
    //   }
    // })
  }

  async getAuthorizationCode() {
    if (environment.production) {
      let accessToken: any = localStorage.getItem('accessToken')
      let authorizationCode = this.route.snapshot.queryParamMap.get('code')

      if (authorizationCode) {
        return await this.getAccessToken(authorizationCode, environment.CAS_COGNITO)
      } else if (this.checkHasToken(accessToken)) {
        return accessToken
      } else if (!authorizationCode && !this.checkHasToken(accessToken)) {
        // (typeof (accessToken) !== 'string' || accessToken == '' || accessToken == null || accessToken == undefined || accessToken == "undefined")
        this.redirectToLogin(environment.CAS_COGNITO)
        return ''
      }
    } else {
      localStorage.setItem('userID', 'admin')
      return 'production: develop'
    }
  }

  async getAccessToken(authorizationCode: string, isCognitor: boolean) {
    if (isCognitor) {
      let headers: any = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      let response: any = await this.http
        .post<any>(
          environment.SERVER_URL +
            'oauth2/token?' +
            'grant_type=authorization_code=' +
            '&code=' +
            authorizationCode +
            '&redirect_uri=' +
            this.siteURL,
          null,
          { headers }
        )
        .toPromise()
        .then(
          (data) => {
            return data
          },
          (error) => {
            return { error: error }
          }
        )

      if (
        !response ||
        (response && response.hasOwnProperty('error')) ||
        response.hasOwnProperty('errors')
      ) {
        localStorage.setItem('accessToken', 'admin')
        // const dialogRef = this.dialog.open(DialogMessageComponent, {
        //   // width: '50%',
        //   position: {
        //     top: '200px'
        //   },
        //   data: {
        //     msg: [
        //       {
        //         fieldName: '',
        //         message: this.translation.translate('accessTokenNotFound')
        //       }
        //     ],
        //     type: 'error',
        //     class: 'wider'
        //   }
        // })
        // dialogRef.afterClosed().subscribe(async (result) => {
        //   this.redirectToLogin(environment.CAS_COGNITO, true)
        //   /* if (result === 'continue') {
        //   } */
        // })
        this.openDialogMess(
          [{ fieldName: '', message: this.translation.translate('accessTokenNotFound') }],
          'error'
        )
        //this.redirectToLogin()
        return ''
      } else {
        let ac = response.access_token
        this.accessToken = ac
        await this.getUserID(ac, environment.CAS_COGNITO)
        localStorage.setItem('accessToken', ac)
        return this.accessToken
      }
    } else {
      const requestOptions: Object = {
        //If your response is text not json
        responseType: 'text'
      }
      let response: any = await this.http
        .post<any>(environment.SERVER_URL + this.siteURL, null, requestOptions)
        .toPromise()
        .then(
          (data) => {
            return data
          },
          (error) => {
            return { error: error }
          }
        )

      if (
        !response ||
        (response && response.hasOwnProperty('error')) ||
        response.hasOwnProperty('errors')
      ) {
        localStorage.setItem('accessToken', '')
        // const dialogRef = this.dialog.open(DialogMessageComponent, {
        //   // width: '50%',
        //   position: {
        //     top: '200px'
        //   },
        //   data: {
        //     msg: [
        //       {
        //         fieldName: '',
        //         message: this.translation.translate('accessTokenNotFound')
        //       }
        //     ],
        //     type: 'error',
        //     class: 'wider'
        //   }
        // })
        // dialogRef.afterClosed().subscribe(async (result) => {
        //   this.redirectToLogin(environment.CAS_COGNITO, true)
        //   /* if (result === 'continue') {
        //   } */
        // })
        this.openDialogMess(
          [{ fieldName: '', message: this.translation.translate('accessTokenNotFound') }],
          'error'
        )
        //this.redirectToLogin()
        return ''
      } else {
        var str1 = new String(response)
        var i = str1.indexOf('=')
        var j = str1.indexOf('&expires_in')
        var ac = str1.substring(i + 1, j)
        this.accessToken = ac
        console.log('accessToken New: ', ac)
        await this.getUserID(ac, environment.CAS_COGNITO)
        localStorage.setItem('accessToken', ac)
        return this.accessToken
      }
    }
  }

  checkHasToken(accessToken: any) {
    return accessToken && typeof accessToken === 'string' && accessToken !== ''
  }

  redirectToLogin(isCognitor: boolean, logAgain?: boolean) {
    localStorage.setItem('accessToken', '')
    localStorage.setItem('userID', '')
    localStorage.clear()
    sessionStorage.clear()

    if (isCognitor) {
      window.location.href =
        environment.COGNITO.SERVER_URL +
        'oauth2/authorize?identity_provider=' +
        environment.COGNITO.identity_provider +
        '&response_type=CODE=' +
        '&scope=' +
        environment.COGNITO.scope +
        '&redirect_uri=' +
        this.siteURL
    } else {
      window.location.href =
        'cas/oauth2.0/authorize?' + 'response_type=code=' + '&redirect_uri=' + this.siteURL
    }
  }

  async getUserID(accessToken: any, isCognitor: boolean) {
    if (this.checkHasToken(this.accessToken)) {
      if (isCognitor) {
        /* let headers: any = {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
        }; */

        let headers: any = {
          Authorization: 'Bearer ' + accessToken
        }

        let response: any = await this.http
          .get<any>(environment.COGNITO.SERVER_URL + 'oauth2/userInfo', {
            headers
          })
          .toPromise()
          .then(
            (data) => {
              return data
            },
            (error) => {
              return { error: error }
            }
          )

        if (response && response.username) {
          localStorage.setItem(
            'userID',
            response.username.substr(environment.COGNITO.identity_provider.length + 1)
          )
        } else if (
          !response ||
          (response && response.hasOwnProperty('error')) ||
          response.hasOwnProperty('errors')
        ) {
          // localStorage.setItem('userID', '');
        }
      } else {
        let response: any = await this.http
          .get<any>(environment.SERVER_URL + 'cas/oauth2.0/profile?access_token=' + accessToken)
          .toPromise()
          .then(
            (data) => {
              return data
            },
            (error) => {
              return { error: error }
            }
          )

        if (response && response.id) {
          localStorage.setItem('userID', response.id)
        } else if (
          !response ||
          (response && response.hasOwnProperty('error')) ||
          response.hasOwnProperty('errors')
        ) {
          // localStorage.setItem('userID', '');
        }
      }
    }
  }

  clearCookies() {
    var cookies = document.cookie.split(';')
    for (var i = 0; i < cookies.length; i++) {
      var element = cookies[i]
      var equalPosition = element.indexOf('=')
      var name = equalPosition > -1 ? element.substr(0, equalPosition) : element
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    }
  }

  removeCookieItem(name: string) {
    this.setCookie(name, '', -1)
  }

  setCookie(name: any, value: any, exdays: any) {
    var date = new Date()
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + date.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
  }

  getCookie(name: string) {
    var key_name = name + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var arrayCookie = decodedCookie.split(';')
    for (var i = 0; i < arrayCookie.length; i++) {
      var element = arrayCookie[i]
      while (element.charAt(0) == ' ') {
        element = element.substring(1)
      }
      if (element.indexOf(key_name) == 0) {
        return element.substring(key_name.length, element.length)
      }
    }
    return ''
  }

  setUsernameAuthenticationInfo(body: any) {
    let k = Object.keys(body)[0]
    if (body[k].hasOwnProperty('authenticationInfo')) {
      body[k].authenticationInfo.username = localStorage.getItem('userID')
    }
    return body
  }
  compareData(obj1: any, obj2: any) {
    let str1 = JSON.stringify(obj1)
    let str2 = JSON.stringify(obj2)

    str1 = str1.replace(/"X"/g, '""')
    str2 = str2.replace(/"X"/g, '""')

    console.log(str1 == str2)
    return str1 == str2
  }

  downloadFile(blob: Blob, fileName: string, renderer: Renderer2, elementRef: ElementRef): void {
    const url = (window.URL || window.webkitURL).createObjectURL(blob)
    const link = renderer.createElement('a')
    renderer.setAttribute(link, 'download', fileName)
    renderer.setAttribute(link, 'href', url)
    renderer.setAttribute(link, 'target', '_blank')
    renderer.appendChild(elementRef.nativeElement, link)
    link.click()
    renderer.removeChild(elementRef.nativeElement, link)

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 1000)
  }

  generatePDF(params: {
    element: HTMLElement
    isSavePdf: boolean
    callbackFn?: (doc: jsPDF) => void
  }) {
    const { element, isSavePdf, callbackFn } = params
    this.visibleTemplatePDF = true
    setTimeout(() => {
      html2canvas(element).then((canvas) => {
        let PDF = new jsPDF('p', 'pt', 'letter')
        return PDF.html(element, {
          callback: (doc) => {
            const nameExport = `invoice-${moment().format(DATE_FORMAT.DD_MM_YYYY)}.pdf`
            isSavePdf && doc.save(nameExport)
            return callbackFn && callbackFn(doc)
          }
        })
      })
    }, 0)
    this.visibleTemplatePDF = false
  }
}
