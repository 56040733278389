import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../app/ng-zorro-antd.module'
import { ActionListComponent } from './components/action-list/action-list.component'
import { CardComponent } from './components/card/card.component'
import { DatePickerComponent } from './components/date-picker/date-picker.component'
import { DetailPaymentInvoiceComponent } from './components/detail-payment-invoice/detail-payment-invoice.component'
import { DrawerComponent } from './components/drawer/drawer.component'
import { HeadingPageComponent } from './components/heading-page/heading-page.component'
import { InputPasswordComponent } from './components/input-password/input-password.component'
import { InputSearchComponent } from './components/input-search/input-search.component'
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component'
import { NotificationComponent } from './components/notification/notification.component'
import { ProfileComponent } from './components/profile/profile.component'
import { SelectFilterComponent } from './components/select-filter/select-filter.component'
import { TableCellAvatarComponent } from './components/table-cell-avatar/table-cell-avatar.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { AddClassActiveDirective } from './directives/add-class-click.directive'
import { SearchPipe } from './pipes/search.pipe'
import { TemplatePdfComponent } from './components/template-pdf/template-pdf.component'

@NgModule({
  imports: [CommonModule, NgZorroAntdModule, FormsModule, ReactiveFormsModule],
  declarations: [
    CardComponent,
    ActionListComponent,
    HeadingPageComponent,
    InputPasswordComponent,
    InputSearchComponent,
    SelectFilterComponent,
    DatePickerComponent,
    AddClassActiveDirective,
    TableCellAvatarComponent,
    DrawerComponent,
    NotificationComponent,
    ModalConfirmComponent,
    ProfileComponent,
    SearchPipe,
    DetailPaymentInvoiceComponent,
    TabsComponent,
    TemplatePdfComponent
  ],
  exports: [
    CardComponent,
    ActionListComponent,
    HeadingPageComponent,
    InputPasswordComponent,
    InputSearchComponent,
    SelectFilterComponent,
    DatePickerComponent,
    AddClassActiveDirective,
    TableCellAvatarComponent,
    DrawerComponent,
    NotificationComponent,
    ModalConfirmComponent,
    ProfileComponent,
    SearchPipe,
    DetailPaymentInvoiceComponent,
    TabsComponent,
    TemplatePdfComponent
  ],
  providers: []
})
export class SharedModule {}
