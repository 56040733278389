import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {
  date = null

  constructor() {}

  ngOnInit(): void {}
  onChange(result: Date): void {
    console.log('onChange: ', result)
  }
}
