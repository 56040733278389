import { CommonModule, HashLocationStrategy, registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import en from '@angular/common/locales/en'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { L10nTranslationService } from 'angular-l10n'
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n'
import { authInterceptorProviders } from '../commons/services/authen/auth.interceptor'
import { AuthModule } from '../features/auth/auth.module'
import { ChooseLinkComponent } from '../features/auth/choose-link/choose-link.component'
import { ChooseServiceComponent } from '../features/auth/choose-service/choose-service.component'
import { CardModuleComponent } from '../features/auth/components/card-module/card-module.component'
import { CardServiceComponent } from '../features/auth/components/card-service/card-service.component'
import { ContainerUiChooseComponent } from '../features/auth/components/container-ui-choose/container-ui-choose.component'
import { WaitingEmailComponent } from '../features/auth/waiting-email/waiting-email.component'
import { AdminModule } from '../layout/admin/admin.module'
import { TenantModule } from '../layout/tenant/tenant.module'
import { NotFoundComponent } from '../shared/components/not-found/not-found.component'
import { SharedModule } from '../shared/shared.module'
import { ChooseModuleComponent } from './../features/auth/choose-module/choose-module.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { NgZorroAntdModule } from './ng-zorro-antd.module'
registerLocaleData(en)

@NgModule({
  declarations: [
    AppComponent,
    ChooseServiceComponent,
    ContainerUiChooseComponent,
    CardServiceComponent,
    ChooseModuleComponent,
    CardModuleComponent,
    ChooseLinkComponent,
    WaitingEmailComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgZorroAntdModule,
    AuthModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    TenantModule,
    AdminModule
  ],
  providers: [
    HttpClientModule,
    authInterceptorProviders,
    {
      provide: L10nTranslationService,
      useClass: HashLocationStrategy
    },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
