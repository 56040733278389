import { Injectable } from '@angular/core'
import { GeneralService } from './general.service'

@Injectable({
  providedIn: 'root'
})
export class TableService<T> {
  public loading = true
  public totalData = 1
  public pageSize = 10
  public pageIndex = 0
  public listOfCurrentPageData: readonly T[] = []
  public listOfData: T[] = []
  public checked = false
  public indeterminate = false
  public setOfCheckedId = new Set<string | number>()
  public searchValue?: string

  constructor(private general: GeneralService) {}

  updateCheckedSet(id: string | number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id)
      this.general.addSelectedData(id, this.listOfData)
    } else {
      this.setOfCheckedId.delete(id)
      this.general.removeSelectedData(id)
    }
  }
  onCurrentPageDataChange(listOfCurrentPageData: readonly T[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData
    this.refreshCheckedStatus()
  }
  refreshCheckedStatus(): void {
    if (this.listOfCurrentPageData.length === 0) {
      this.checked = false
    } else {
      this.checked = this.listOfCurrentPageData.every((item: any) =>
        this.setOfCheckedId.has(item.id)
      )
    }
    this.indeterminate =
      this.listOfCurrentPageData.some((item: any) => this.setOfCheckedId.has(item.id)) &&
      !this.checked
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked)
    this.refreshCheckedStatus()
  }

  clearAllChecked() {
    this.general.selectedData = []
    this.setOfCheckedId.clear()
    this.refreshCheckedStatus()
  }

  removeChecked(id: string | number): void {
    this.setOfCheckedId.delete(id)
    this.refreshCheckedStatus()
  }

  onAllChecked(checked: boolean): void {
    this.general.selectedData = []
    this.listOfCurrentPageData.forEach((item: any) => this.updateCheckedSet(item.id, checked))
    this.refreshCheckedStatus()
  }

  // onQueryParamsChange(params: NzTableQueryParams) {
  //   const { pageSize, pageIndex } = params
  //   // const currentSort = sort.find((item) => item.value !== null)
  //   // const sortField = (currentSort && currentSort.key) || null
  //   // const sortOrder = (currentSort && currentSort.value) || null
  //   this.getListAccount(pageIndex, pageSize, StatusAccount.ACTIVE)
  //   // this.dataSource = this.listOfData.filter((item) => item.status === StatusAccount.ACTIVE)
  // }
}
