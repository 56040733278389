import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core'

@Directive({ selector: '[addClassActive]' })
export class AddClassActiveDirective {
  isShow = false
  @Input() nameClass!: string
  constructor(private eRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('click', ['$event'])
  handleFocused(event: any) {
    this.isShow = !this.isShow
    if (this.eRef.nativeElement.contains(event.target) && this.isShow) {
      return this.renderer.addClass(this.eRef.nativeElement, this.nameClass)
    } else {
      return this.renderer.removeClass(this.eRef.nativeElement, this.nameClass)
    }
  }
}
