import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { URL_PAGE } from '../../../commons/consts'
import { StorageKey } from '../../../commons/enums/storage-key.enum'
import { AuthServices } from '../../../commons/services/authen/auth.service'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'
import { SessionStorageService } from '../../../commons/services/session/session-storage.service'
import { ValidationService } from '../../../commons/services/validation.service'
import { Regexs } from '../../../configs/regexs'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm!: FormGroup
  options: string[] = []
  constructor(
    private fb: FormBuilder,
    private sessionStorage: SessionStorageService,
    private validation: ValidationService,
    private router: Router,
    private authService: AuthServices,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(Regexs.EMAIL)]],
      password: [
        null,
        [Validators.required, Validators.pattern(Regexs.PASSWORDNOTSPECIALCHARACTER)]
      ],
      confirmedPassword: [null, [this.confirmValidator]],
      agree: [true, [Validators.requiredTrue]]
    })
  }

  get agree() {
    return this.registerForm.controls.agree
  }
  get password() {
    return this.registerForm.controls.password
  }

  confirmValidator = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true }
    } else if (control.value !== this.password.value) {
      return { confirm: true, error: true }
    }
    return {}
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.registerForm.controls.confirmedPassword.updateValueAndValidity())
  }

  onInputEmail(e: Event): void {
    const value = (e.target as HTMLInputElement).value
    if (!value || value.indexOf('@') >= 0) {
      this.options = []
    } else {
      this.options = ['gmail.com', 'outlook.com', 'innotech-vn.com'].map(
        (domain) => `${value}@${domain}`
      )
    }
  }
  submitForm(): void {
    const body = {
      email: this.registerForm.controls.email.value,
      password: this.registerForm.controls.password.value
    }
    if (this.registerForm.valid && this.agree.value) {
      // this.authService.validateRegister(body).subscribe(
      // () => {
      this.sessionStorage.set(StorageKey.HAS_REGISTER, true)
      this.router.navigateByUrl(URL_PAGE.MANDATORY_INFO, { state: body })
      // },
      // (err: { error: any }) => {
      //   this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.REGISTER })
      // }
      // )
    } else {
      this.validation.checkAllValidation(this.registerForm.controls)
    }
  }
}
