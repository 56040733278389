import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { UserManagementService } from '../../../../api/user-management.service'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { StorageKey } from '../../../../commons/enums/storage-key.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'
import { GeneralService } from '../../../../commons/services/general.service'
import { StateService } from '../../../../commons/services/state.service'
import { DATE_FORMAT } from '../../../../configs/constants'
import { ResponseAccount, StatusAccount } from '../../../../interfaces/user-manage'
import { DeActivateUserComponent } from '../de-activate-user/de-activate-user.component'
import { URL_PAGE } from './../../../../commons/consts/constants'

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnChanges {
  public formatDate = DATE_FORMAT.dd_MM_yyyy
  public userDetail!: ResponseAccount
  public statusAccount = StatusAccount
  public isStatusActive = false
  public visibleResetPassword = false
  public isLoadingBtn = false
  @Input() visible = false
  @Output() emitReloadData = new EventEmitter()
  @Output() cancel: EventEmitter<boolean> = new EventEmitter(false)

  @ViewChild(DeActivateUserComponent) userDeActivateComp!: DeActivateUserComponent

  constructor(
    private userManageService: UserManagementService,
    private router: Router,
    private stateService: StateService,
    private generalService: GeneralService,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnChanges(): void {
    this.getUserDetail()
  }

  ngOnInit(): void {}

  getUserDetail() {
    if (this.generalService.selectedData.length > 0) {
      const selectedData = this.generalService.selectedData
      this.userDetail = selectedData[0]
      this.isStatusActive = this.userDetail.status === this.statusAccount.ACTIVE
    }
  }

  callbackSuccessChangeStatus() {
    this.cancel.emit(false)
    this.emitReloadData.emit()
    this.isLoadingBtn = false
  }

  changeStatusAccount() {
    const status = this.isStatusActive ? StatusAccount.DEACTIVE : StatusAccount.ACTIVE
    let body = {
      status: status,
      accountIds: [this.userDetail.id]
    }
    this.isLoadingBtn = true
    this.userManageService.updateStatusAccount(body).subscribe(
      () => {
        this.httpObservable.handleSuccess({
          messageSuccess: this.isStatusActive ? MESSAGE_SUCCESS.DEACTIVATE : MESSAGE_SUCCESS.ACTIVE,
          title: this.isStatusActive ? TITLE_PAGE.DEACTIVATE_ACCOUNT : TITLE_PAGE.ACTIVE_ACCOUNT,
          callbackSuccess: () => this.callbackSuccessChangeStatus()
        })
      },
      (err: { error: { message: string } }) => {
        this.isLoadingBtn = false
        this.httpObservable.handleError({
          error: err.error,
          title: this.isStatusActive ? TITLE_PAGE.DEACTIVATE_ACCOUNT : TITLE_PAGE.ACTIVE_ACCOUNT
        })
      }
    )
  }

  handleCancel() {
    this.cancel.emit(false)
    this.generalService.selectedData = []
  }

  goEditUser = () => {
    const id = this.userDetail.id
    this.router.navigateByUrl(`${URL_PAGE.ACCOUNT_MANAGEMENT}/${URL_PAGE.EDIT_ACCOUNT}/${id}`)
    this.generalService.addSelectedData(id, [this.userDetail])
    this.stateService.setState(StorageKey.EDIT_ACCOUNT, true)
  }

  resetPassword() {
    this.userManageService.requestChangePassword(this.userDetail.email).subscribe(
      () => {
        this.httpObservable.handleSuccess({
          messageSuccess: MESSAGE_SUCCESS.REQUEST_CHANGE_PASSWORD,
          title: TITLE_PAGE.RESET_PASSWORD_ACCOUNT
        })
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.RESET_PASSWORD_ACCOUNT
        })
      }
    )
  }
}
