import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../../app/ng-zorro-antd.module'
import { SharedModule } from '../../../shared/shared.module'
import { ActivationUserComponent } from './activation-user/activation-user.component'
import { AddUserComponent } from './add-user/add-user.component'
import { HeaderDrawerComponent } from './components/header-drawer/header-drawer.component'
import { ListFilterComponent } from './components/list-filter/list-filter.component'
import { SelectedUserCardComponent } from './components/selected-user-card/selected-user-card.component'
import { ToolbarComponent } from './components/toolbar/toolbar.component'
import { DeActivateUserComponent } from './de-activate-user/de-activate-user.component'
import { EditUserComponent } from './edit-user/edit-user.component'
import { InviteMemberComponent } from './invite-member/invite-member.component'
import { ResetPasswordUserComponent } from './reset-password-user/reset-password-user.component'
import { UserManageRoutingModule } from './user-manage-routing.module'
import { UserManageComponent } from './user-manage.component'
import { UserProfileComponent } from './user-profile/user-profile.component'
@NgModule({
  imports: [
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    UserManageRoutingModule,
    SharedModule
  ],
  exports: [ToolbarComponent],
  declarations: [
    UserManageComponent,
    AddUserComponent,
    ToolbarComponent,
    ListFilterComponent,
    EditUserComponent,
    InviteMemberComponent,
    HeaderDrawerComponent,
    ActivationUserComponent,
    UserProfileComponent,
    DeActivateUserComponent,
    ResetPasswordUserComponent,
    SelectedUserCardComponent
  ],
  providers: [],
  bootstrap: [UserManageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserManageModule {}
