import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PACKAGE_TIME } from '../../../../../commons/enums/common.enum'
import { GeneralService } from '../../../../../commons/services/general.service'
import { ResponseListPackage, ResponsePayment } from './../../../../../interfaces/subscription'

@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrls: ['./select-package.component.scss']
})
export class SelectPackageComponent implements OnInit {
  public PACKAGE_TIME = PACKAGE_TIME
  public selectedItem!: ResponsePayment & ResponseListPackage
  @Output() changePackageTime: EventEmitter<PACKAGE_TIME> = new EventEmitter()
  @Output() changeTotalUser: EventEmitter<number> = new EventEmitter()
  @Input() period: PACKAGE_TIME = PACKAGE_TIME.MONTHLY
  @Input() totalUser: number = 1
  constructor(private generalService: GeneralService) {}

  ngOnInit(): void {
    this.generalService.selectedItem.subscribe((item: ResponsePayment & ResponseListPackage) => {
      this.selectedItem = { ...item }
    })
  }
  decreaseUserNumber() {
    this.totalUser -= 1
    this.changeTotalUser.emit(this.totalUser)
  }
  increaseUserNumber() {
    this.totalUser += 1
    this.changeTotalUser.emit(this.totalUser)
  }

  onChangeRadio(value: PACKAGE_TIME) {
    this.changePackageTime.emit(value)
  }

  onChangeTotalUser(value: number) {
    this.totalUser = value
    return this.changeTotalUser.emit(this.totalUser)
  }
}
