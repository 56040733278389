import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-header-drawer',
  templateUrl: './header-drawer.component.html',
  styleUrls: ['./header-drawer.component.scss']
})
export class HeaderDrawerComponent implements OnInit {
  @Output() cancel: EventEmitter<any> = new EventEmitter()
  @Input() title!: string
  constructor() {}

  ngOnInit(): void {}

  handleCancel() {
    this.cancel.emit()
  }
}
