import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../../app/ng-zorro-antd.module'
import { SharedModule } from '../../../shared/shared.module'
import { SubscriptionRoutingModule } from './subscription-routing.module'
import { SubscriptionComponent } from './subscription.component'
import { TableSubscriptionComponent } from './table-subscription/table-subscription.component'
import { SelectPackageComponent } from './upgrade-drawer/select-package/select-package.component'
import { UpgradeDrawerComponent } from './upgrade-drawer/upgrade-drawer.component'

@NgModule({
  imports: [
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    SubscriptionRoutingModule
  ],
  exports: [UpgradeDrawerComponent],
  declarations: [
    SubscriptionComponent,
    TableSubscriptionComponent,
    UpgradeDrawerComponent,
    SelectPackageComponent
  ],
  providers: [],
  bootstrap: [SubscriptionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SubscriptionModule {}
