import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { RoleManagementService } from '../../../../api/roles-management.service'
import { UserManagementService } from '../../../../api/user-management.service'
import { MESSAGE_SUCCESS, TITLE_PAGE, TYPE_SITE } from '../../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'
import { Regexs } from '../../../../configs/regexs'
import { Roles } from '../../../../interfaces/roles-manage'
import { URL_PAGE } from './../../../../commons/consts/constants'
import { ValidationService } from './../../../../commons/services/validation.service'
import { RequestAccount, StatusAccount } from './../../../../interfaces/user-manage'

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  nzSpanLabel = 10
  nzSpanFormControl = 14
  isLoadingBtn = false
  statusUser = true
  createNewUserForm!: FormGroup
  roleList: Roles[] = []
  constructor(
    private fb: FormBuilder,
    private userManageService: UserManagementService,
    private router: Router,
    private validation: ValidationService,
    private httpObservable: HttpObservablesService,
    private roleService: RoleManagementService
  ) {}

  ngOnInit(): void {
    this.createNewUserForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.pattern(Regexs.INPUT_NAME)]],
      lastName: [null, [Validators.required, Validators.pattern(Regexs.INPUT_NAME)]],
      userName: [null, [Validators.required, Validators.pattern(Regexs.NAME)]],
      passWord: [null, [Validators.required]],
      confirmedPassword: [null, [Validators.required, this.confirmValidator]],
      workEmail: [null, [Validators.required, Validators.pattern(Regexs.EMAIL)]],
      phoneNumber: [null, [Validators.pattern(Regexs.PHONE_NUMBER)]],
      role: [null, [Validators.required]],
      statusUser: [true]
    })
    this.loadListRole(TYPE_SITE.TENANT)
  }
  get passWord() {
    return this.createNewUserForm.controls.passWord
  }
  get confirmPassword() {
    return this.createNewUserForm.controls.confirmPassword
  }

  confirmValidator = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true }
    } else if (control.value !== this.passWord.value) {
      return { confirm: true, error: true }
    }
    return {}
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.createNewUserForm.controls.confirmedPassword.updateValueAndValidity())
  }

  handleCancel() {
    this.router.navigateByUrl(URL_PAGE.ACCOUNT_MANAGEMENT)
  }

  submitForm() {
    const controls = this.createNewUserForm.controls
    if (this.createNewUserForm.invalid) {
      return this.validation.checkAllValidation(controls)
    } else {
      this.isLoadingBtn = true
      const data: RequestAccount = {
        firstName: controls.firstName.value,
        lastName: controls.lastName.value,
        username: controls.userName.value,
        password: controls.passWord.value,
        email: controls.workEmail.value,
        roleId: controls.role.value,
        status: controls.statusUser.value ? StatusAccount.ACTIVE : StatusAccount.DEACTIVE,
        phoneNumber: controls.phoneNumber.value
      }
      return this.userManageService.createUser(data).subscribe(
        () => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.ADD_ACCOUNT,
            title: TITLE_PAGE.ADD_ACCOUNT,
            callbackSuccess: () => this.router.navigateByUrl(URL_PAGE.ACCOUNT_MANAGEMENT)
          })
          this.isLoadingBtn = false
        },
        (err: { error: { message: string } }) => {
          this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.ADD_ACCOUNT })
          this.isLoadingBtn = false
        }
      )
    }
  }

  loadListRole(type: TYPE_SITE) {
    this.roleService.getListRoleByType(0, 10, type).subscribe(
      (res) => {
        this.roleList = res?.data?.roleList
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.ADD_ACCOUNT })
      }
    )
  }
}
