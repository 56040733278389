import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { URL_PAGE } from '../../../commons/consts'
import { HelpCenterComponent } from './help-center.component'

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.HELP_CENTER },
  {
    path: '',
    component: HelpCenterComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpCenterRoutingModule {}
