import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import APIs from '../commons/consts/API'
import { PAYMENT_STATUS } from '../commons/enums/common.enum'
import { SessionStorageService } from '../commons/services/session/session-storage.service'
import { environment } from '../environments/environment'
import { RequestPayment } from '../interfaces/subscription'

const SERVER_URL = environment.API_WS_SERVER_URL

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private http: HttpClient, private tokenStorage: SessionStorageService) {}

  registerPayment(body: RequestPayment): Observable<any> {
    let url = SERVER_URL + APIs.PAYMENT
    return this.http.post(url, body)
  }

  paymentListStatus(offset: number, limit: number, statusPayment: PAYMENT_STATUS): Observable<any> {
    let url =
      SERVER_URL + APIs.PAYMENT + `?limit=${limit}&offset=${offset}&statusPayment=${statusPayment}`
    return this.http.get(url)
  }

  paymentList(offset: number, limit: number): Observable<any> {
    let url = SERVER_URL + APIs.PAYMENT + `?limit=${limit}&offset=${offset}`
    return this.http.get(url)
  }

  getPaymentDetail(id: string): Observable<any> {
    let url = SERVER_URL + APIs.PAYMENT + `/${id}`
    return this.http.get(url)
  }
}
