import { Component, Input, OnInit } from '@angular/core'
import { FeatureList, RowStorage } from '../../../../interfaces/subscription'

@Component({
  selector: 'app-table-subscription',
  templateUrl: './table-subscription.component.html',
  styleUrls: ['./table-subscription.component.scss']
})
export class TableSubscriptionComponent implements OnInit {
  @Input() listData: FeatureList[] = []
  @Input() rowStorage!: RowStorage

  constructor() {}

  ngOnInit(): void {}
}
