<div class="wrapper_card">
  <div nz-row [nzGutter]="[24, 24]" *ngIf="listModule" nzJustify="center">
    <div
      *ngFor="let item of listModule"
      nz-col
      [nzXs]="24 / 1"
      [nzMd]="24 / 2"
      [nzLg]="24 / 3"
      [nzXXl]="24 / 4"
    >
      <div (click)="id = item.id">
        <app-card [class.focus-card]="id === item.id">
          <ng-container [ngTemplateOutlet]="badge_focus" *ngIf="id === item.id"></ng-container>
          <div class="heading">
            <h3 class="heading-title">{{ item.title }}</h3>
            <p class="heading-subtitle">{{ item.subtitle }}</p>
          </div>
          <nz-divider class="divider"></nz-divider>
          <div class="container_price">
            <span class="price-number">{{ item.price }}</span>
            <span class="price-suffix">vnd</span>
          </div>
          <div class="list-offer-container">
            <div class="title-offer">{{ item.titleOffer }}</div>
            <div class="list-offer" *ngFor="let offer of item.offerList">
              <ng-container *ngIf="offer.active; else icon_not_checked">
                <ng-container [ngTemplateOutlet]="icon_ticked"></ng-container>
              </ng-container>
              <span class="content-offer">{{ offer.content }}</span>
            </div>
          </div>
          <button nz-button [class.ant-btn-primary]="id === item.id">Get Started</button>
        </app-card>
      </div>
    </div>
  </div>
</div>
<ng-template #badge_focus>
  <div class="badge-focus">
    <img src="assets/images/christmas-stars-bottom.png" />
    <span class="text">Most Poppular</span>
  </div>
</ng-template>
<ng-template #icon_ticked>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    class="content-icon"
  >
    <path
      d="M18.3332 9.23812V10.0048C18.3321 11.8018 17.7503 13.5504 16.6743 14.9896C15.5983 16.4289 14.0859 17.4819 12.3626 17.9914C10.6394 18.5009 8.79755 18.4397 7.1119 17.8169C5.42624 17.1942 3.98705 16.0432 3.00897 14.5357C2.03089 13.0282 1.56633 11.2448 1.68457 9.45172C1.80281 7.65859 2.49751 5.95173 3.66507 4.58568C4.83263 3.21964 6.41049 2.26761 8.16333 1.87158C9.91617 1.47555 11.7501 1.65674 13.3915 2.38812M18.3332 3.33335L9.99984 11.675L7.49984 9.17502"
      stroke="#124EDB"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>
<ng-template #icon_not_checked>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    class="content-icon"
  >
    <g clip-path="url(#clip0_382_40606)">
      <path
        d="M18.3337 9.23806V10.0047C18.3326 11.8017 17.7507 13.5503 16.6748 14.9896C15.5988 16.4289 14.0864 17.4818 12.3631 17.9913C10.6399 18.5008 8.79804 18.4396 7.11238 17.8169C5.42673 17.1941 3.98754 16.0431 3.00946 14.5356C2.03138 13.0281 1.56682 11.2448 1.68506 9.45166C1.80329 7.65853 2.498 5.95167 3.66556 4.58562C4.83312 3.21958 6.41098 2.26755 8.16382 1.87152C9.91665 1.47549 11.7505 1.65668 13.392 2.38806"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M7 10H13.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_382_40606">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</ng-template>
