<div
  nz-row
  class="selected-card-user"
  *ngFor="let data of listData"
  nzAlign="middle"
  nzJustify="space-between"
>
  <div nz-col nzSpan="3">
    <img
      nz-image
      class="item-img"
      nzSrc="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      alt=""
    />
  </div>
  <div nz-col nzSpan="18">
    <div class="title">{{ data.username }}</div>
    <div class="description">
      <span class="description-email">{{ data.email }}</span>
      <nz-divider nzType="vertical"></nz-divider>
      <span class="description-role">{{ data.role }}</span>
    </div>
  </div>
  <div nz-col class="btn-remove" nzSpan="2">
    <img
      *ngIf="isShowBtnRemove"
      width="32px"
      height="32px"
      Src="assets/images/user-manage/btnclose.png"
      alt=""
      (click)="removeItem(data.id)"
    />
  </div>
</div>
