import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../app/ng-zorro-antd.module'

import { SharedModule } from '../../shared/shared.module'

import { TenantComponent } from './tenant.component'
import { TenantRoutingModule } from './tenant-routing.module'
import { BillingInvoiceModule } from '../../features/tenant/billing-invoice/billing-invoice.module'
import { SubscriptionModule } from '../../features/tenant/subscription/subscription.module'
import { UserManageModule } from '../../features/tenant/user-manage/user-manage.module'
import { PaymentModule } from '../../features/tenant/payment/payment.module'
import { ManageSubscriptionComponent } from '../../features/tenant/manage-subscription/manage-subscription.component'
import { HelpCenterModule } from '../../features/tenant/help-center/help-center.module'

@NgModule({
  declarations: [TenantComponent, ManageSubscriptionComponent],
  imports: [
    TenantRoutingModule,
    HttpClientModule,
    FormsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    UserManageModule,
    SubscriptionModule,
    BillingInvoiceModule,
    PaymentModule,
    HelpCenterModule
  ],
  providers: [],
  bootstrap: [TenantComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TenantModule {}
