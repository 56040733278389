<nz-input-group [nzSuffix]="suffixTemplate" [class.ant-input-affix-wrapper-disabled]="disabled">
  <ng-content></ng-content>
</nz-input-group>
<ng-template #suffixTemplate>
  <i
    class="custom-eye custom-anticon"
    [style.PointerEvents]="disabled ? 'none' : 'auto'"
    nz-icon
    [nzType]="passwordVisible ? 'icon:eye_invisible' : 'icon:eye'"
    (click)="passwordVisible = !passwordVisible"
  ></i>
</ng-template>
