/* eslint-disable no-unused-vars */

const VALIDATE = {
  default: [{ required: true, message: 'This field is required' }],
  counrtry: [{ required: true, message: 'Please choose the country' }],
  relation: [{ required: true, message: 'Please enter your relation' }],
  role: [{ required: true, message: 'Please enter your role' }],
  state: [{ required: true, message: 'Please choose the state' }],
  idNumber: [{ required: true, message: 'Please enter your ID number' }],
  email: [
    { required: true, message: 'Please enter your email' },
    { type: 'email' as const, message: 'This email wrong format' }
  ],
  identifier: [{ required: true, message: 'Please enter your identifier' }],
  reason: [{ required: true, message: 'Please enter your reason' }],
  document: [{ required: true, message: 'Please choose a document' }],
  expiredDate: [{ required: true, message: 'Expired date is invalid' }],
  employment: [{ required: true, message: 'Please select the employment status' }],
  employer: [{ required: true, message: 'Please enter your employer' }],
  occupation: [{ required: true, message: 'Please enter your occupation' }]
}

// time auto logout
const INACTIVE_USER_TIME_THRESHOLD = 1200000
const USER_ACTIVITY_THROTTLER_TIME = 10000

const DEFAULT_COUNTRY = 'United States'
const DEFAULT_COUNTRY_CODE = 'US'
const DEFAULT_COUNTRY_ID = '260'

const LoginScreen = {
  LOGIN: 1,
  FORGOT_PASSWORD: 2,
  DONT_HAVE_PASSWORD: 3,
  REGISTER: 4,
  SEND_OTP: 5
}

const DATE_FORMAT = {
  YYYY_DD_MM: 'YYYY-MM-DD',
  MM_DD_YYYY: 'MM/DD/YYYY',
  LIMIT_YEAR: 1800,
  MMM_DD_YYYY: 'MMM DD, YYYY',
  DD_MM_YYYY: 'DD/MM/YYYY',
  dd_MM_yyyy: 'dd/MM/yyyy'
}

export {
  VALIDATE,
  INACTIVE_USER_TIME_THRESHOLD,
  USER_ACTIVITY_THROTTLER_TIME,
  DEFAULT_COUNTRY,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_ID,
  LoginScreen,
  DATE_FORMAT
}
