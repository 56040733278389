import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NavigationEnd, Router } from '@angular/router'
import { StorageKey } from '../../commons/enums/storage-key.enum'
import { SessionStorageService } from '../../commons/services/session/session-storage.service'
import { StateService } from '../../commons/services/state.service'
import { ValidationService } from '../../commons/services/validation.service'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  url = ''
  codeVerify: string = ''
  visibleModalCode = true
  verifyCodeForm!: FormGroup

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private stateService: StateService,
    private validation: ValidationService
  ) {}
  ngOnInit(): void {
    this.verifyCodeForm = this.fb.group({
      codeVerify: ['', [Validators.required]]
    })
    this.visibleModalCode = !this.stateService.getState(StorageKey.IS_LOGIN)
    this.url = this.router.url
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.url = e.url
      }
    })
    this.sessionStorage.set(StorageKey.HAS_REGISTER, false)
  }

  submitFormVerify(): void {
    if (this.verifyCodeForm.valid) {
      if (this.verifyCodeForm.value.codeVerify === 'kingwork@123') {
        this.visibleModalCode = false
      } else {
        return
      }
    } else {
      this.validation.checkAllValidation(this.verifyCodeForm.controls)
    }
  }
}
