import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Regexs } from '../../../../configs/regexs'

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit {
  public visible = false
  public formInvite!: FormGroup
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formInvite = this.fb.group({
      inviteLink: [null, [Validators.required]],
      sendEmail: [null, [Validators.required, Validators.pattern(Regexs.EMAIL)]]
    })
  }

  handleCancel() {
    this.visible = false
    this.formInvite.reset()
  }

  onSubmit() {
    console.log(this.formInvite.value)
  }

  copyInputInvite(inputEl: HTMLInputElement) {
    inputEl.select()
    document.execCommand('copy')
    inputEl.setSelectionRange(0, 0)
  }
}
