import { AuthServices } from './../../../commons/services/authen/auth.service'
import { ActivatedRoute } from '@angular/router'
import { ValidationService } from './../../../commons/services/validation.service'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Regexs } from '../../../configs/regexs'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../commons/enums/common.enum'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetPassForm!: FormGroup
  public token: string = ''
  constructor(
    private fb: FormBuilder,
    private validation: ValidationService,
    private route: ActivatedRoute,
    private authService: AuthServices,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    this.resetPassForm = this.fb.group({
      password: [null, [Validators.required]],
      confirmedPassword: [null, [Validators.required, this.confirmValidator]]
    })
    this.getTokenUrl()
  }

  get password() {
    return this.resetPassForm.controls.password
  }
  get confirmedPassword() {
    return this.resetPassForm.controls.confirmed_password
  }

  getTokenUrl() {
    this.route.queryParams.subscribe((params) => {
      this.token = params.token
    })
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true }
    } else if (control.value !== this.password.value) {
      return { confirm: true, error: true }
    }
    return {}
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.resetPassForm.controls.confirmedPassword.updateValueAndValidity())
  }

  submitForm(): void {
    if (this.resetPassForm.valid) {
      const formValue = {
        token: this.token,
        password: this.password.value
      }
      this.authService.resetCustomerPassword(formValue).subscribe(
        () => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.RESET_PASSWORD_CUSTOMER,
            title: TITLE_PAGE.RESET_PASSWORD_CUSTOMER
          })
        },
        (err: { error: any }) => {
          this.httpObservable.handleError({
            error: err.error,
            title: TITLE_PAGE.RESET_PASSWORD_CUSTOMER
          })
        }
      )
    } else {
      this.validation.checkAllValidation(this.resetPassForm.controls)
    }
  }
}
