import { Component, OnInit } from '@angular/core'
import { TableService } from '../../../commons/services/table.service'

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  constructor(public table: TableService<any>) {}

  ngOnInit(): void {}
}
