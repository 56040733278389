<container-ui-choose title="All Done! Let’s Start Explore The Service ">
  <div class="wrapper-card">
    <app-card *ngFor="let item of listLink">
      <div class="card-left">
        <h2 class="card-title">{{ item.title }}</h2>
        <p class="card-description">{{ item.description }}</p>
      </div>
      <div class="card-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="14"
          viewBox="0 0 7 14"
          fill="none"
          color="transparent"
        >
          <path
            d="M0.940186 12.2797L5.28685 7.93306C5.80019 7.41973 5.80019 6.57973 5.28685 6.06639L0.940186 1.71973"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </app-card>
  </div>
  <button nz-button nzType="primary" class="btn-get-link">Get Started</button>
</container-ui-choose>
