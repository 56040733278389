import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { RoleManagementService } from '../../../../api/roles-management.service'
import { UserManagementService } from '../../../../api/user-management.service'
import { MESSAGE_SUCCESS, TITLE_PAGE, TYPE_SITE } from '../../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'
import { GeneralService } from '../../../../commons/services/general.service'
import { ValidationService } from '../../../../commons/services/validation.service'
import { Regexs } from '../../../../configs/regexs'
import { Roles } from '../../../../interfaces/roles-manage'
import { RequestAccount, ResponseAccount, StatusAccount } from '../../../../interfaces/user-manage'
import { URL_PAGE } from './../../../../commons/consts/constants'

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  nzSpanLabel = 10
  nzSpanFormControl = 14
  accountId = ''
  isLoadingBtn = false
  editAccountForm!: FormGroup
  roleList: Roles[] = []

  constructor(
    private fb: FormBuilder,
    private userManageService: UserManagementService,
    private router: Router,
    public general: GeneralService,
    private httpObservable: HttpObservablesService,
    private validation: ValidationService,
    private roleService: RoleManagementService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.generateForm()
    this.route.params.subscribe((params) => {
      this.accountId = params['id']
      this.getUserDetail(params['id'])
    })

    this.loadListRole(TYPE_SITE.TENANT)
  }

  generateForm(data?: ResponseAccount) {
    this.editAccountForm = this.fb.group({
      firstName: [
        data?.firstName || null,
        [Validators.required, Validators.pattern(Regexs.INPUT_NAME)]
      ],
      lastName: [
        data?.lastName || null,
        [Validators.required, Validators.pattern(Regexs.INPUT_NAME)]
      ],
      userName: [{ value: data?.username || null, disabled: true }, Validators.required],
      passWord: [{ value: data?.password || null, disabled: true }, Validators.required],
      workEmail: [
        { value: data?.email || null, disabled: true },
        [Validators.required, Validators.pattern(Regexs.EMAIL)]
      ],
      phoneNumber: [data?.phoneNumber || null, [Validators.pattern(Regexs.PHONE_NUMBER)]],
      role: [data?.roles[0].id || null, [Validators.required]]
    })
  }

  getUserDetail(id: string) {
    this.userManageService.getUserDetail(id).subscribe(
      (res: { data: ResponseAccount }) => {
        return this.generateForm(res.data)
      },
      (err: { error: { message: string } }) => {
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.EDIT_ACCOUNT })
      }
    )
  }

  handleCancel() {
    this.router.navigateByUrl(URL_PAGE.ACCOUNT_MANAGEMENT)
  }

  submitForm() {
    const controls = this.editAccountForm.controls
    if (this.editAccountForm.invalid) {
      return this.validation.checkAllValidation(controls)
    } else {
      this.isLoadingBtn = true
      const data: RequestAccount = {
        firstName: controls.firstName.value,
        lastName: controls.lastName.value,
        phoneNumber: controls.phoneNumber.value,
        roleId: controls.role.value,
        status: StatusAccount.ACTIVE
      }
      return this.userManageService.editUser(data, this.accountId).subscribe(
        () => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.EDIT_ACCOUNT,
            title: TITLE_PAGE.EDIT_ACCOUNT,
            callbackSuccess: () => this.router.navigateByUrl(URL_PAGE.ACCOUNT_MANAGEMENT)
          })
          this.isLoadingBtn = false
        },
        (err: { error: { message: string } }) => {
          this.httpObservable.handleError({
            error: err.error,
            title: TITLE_PAGE.EDIT_ACCOUNT
          })
          this.isLoadingBtn = false
        }
      )
    }
  }

  loadListRole(type: TYPE_SITE) {
    this.roleService.getListRoleByType(0, 10, type).subscribe(
      (res) => {
        this.roleList = res?.data?.roleList
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.ADD_ACCOUNT })
      }
    )
  }
}
