import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'container-ui-choose',
  templateUrl: './container-ui-choose.component.html',
  styleUrls: ['./container-ui-choose.component.scss']
})
export class ContainerUiChooseComponent implements OnInit {
  @Input() title: string = ''
  @Input() subtitle: string = ''
  public styleContainerHeadingPage = {
    flexDirection: 'column-reverse',
    textAlign: 'center',
    marginTop: '49.5px',
    marginBottom: '40px'
  }
  constructor() {}

  ngOnInit(): void {}
}
