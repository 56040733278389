import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { QuillModule } from 'ngx-quill'
import { NgZorroAntdModule } from '../../../app/ng-zorro-antd.module'
import { SharedModule } from '../../../shared/shared.module'
import { HelpCenterRoutingModule } from './help-center-routing.module'
import { HelpCenterComponent } from './help-center.component'
import { HistoryRequestComponent } from './history-request/history-request.component'
@NgModule({
  imports: [
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HelpCenterRoutingModule,
    SharedModule,
    QuillModule.forRoot()
  ],
  exports: [],
  declarations: [HelpCenterComponent, HistoryRequestComponent],
  providers: [],
  bootstrap: [HelpCenterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HelpCenterModule {}
