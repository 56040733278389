import { Component, OnInit } from '@angular/core'
import { PAYMENT_STATUS, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { GeneralService } from '../../../../commons/services/general.service'
import { TableService } from '../../../../commons/services/table.service'
import { DATE_FORMAT } from '../../../../configs/constants'
import { generateSTT } from '../../../../utils/function'

@Component({
  selector: 'app-history-request',
  templateUrl: './history-request.component.html',
  styleUrls: ['./history-request.component.scss']
})
export class HistoryRequestComponent implements OnInit {
  dd_MM_yyyy = DATE_FORMAT.dd_MM_yyyy
  generateSTT = generateSTT
  TITLE_PAGE = TITLE_PAGE
  isLoadingBtn = false
  PAYMENT_STATUS = PAYMENT_STATUS
  listOfDisplayData: any[] = [
    {
      code: 13213,
      name: 'dsadas',
      description: 'dsadsaad',
      createdAt: 3213214,
      status: 'Resolve'
    }
  ]
  listOfColumn: any = [
    {
      title: 'Request code',
      align: 'right'
    },
    {
      title: 'Name',
      align: 'left'
    },
    {
      title: 'Description',
      align: 'left'
    },
    {
      title: 'Creation Time',
      align: 'right '
    },
    {
      title: 'Status',
      align: 'left'
    }
  ]
  constructor(public tableService: TableService<any>, public general: GeneralService) {}

  ngOnInit(): void {
    this.tableService.loading = false
    this.getListHistory()
  }

  getListHistory() {}
}
