import { Component, ElementRef, HostListener, OnInit } from '@angular/core'

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {
  isFocused = false
  isClick = false
  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {}

  @HostListener('document:click', ['$event'])
  handleFocused(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.isFocused = true
    } else {
      this.isFocused = false
    }
  }
}
