import { TableService } from './../../../../commons/services/table.service'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UserManagementService } from '../../../../api/user-management.service'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'
import { GeneralService } from '../../../../commons/services/general.service'
import { StatusAccount } from '../../../../interfaces/user-manage'

@Component({
  selector: 'app-activation-user',
  templateUrl: './activation-user.component.html',
  styleUrls: ['./activation-user.component.scss']
})
export class ActivationUserComponent implements OnInit {
  public visible = false
  public isLoadingBtn = false
  @Output() emitReloadData = new EventEmitter()

  constructor(
    private accountManageService: UserManagementService,
    public generalService: GeneralService,
    private httpObservable: HttpObservablesService,
    private tableService: TableService<any>
  ) {}

  ngOnInit(): void {}

  handleCancel() {
    this.visible = false
  }

  callbackSuccess() {
    this.generalService.selectedData = []
    this.visible = false
    this.isLoadingBtn = false
    this.generalService.forceRender.next('get-account-deactive')
  }

  onSubmit() {
    const listIdAccount = Array.from(this.tableService.setOfCheckedId) as Array<string | undefined>
    const body = {
      status: StatusAccount.ACTIVE,
      accountIds: listIdAccount
    }
    this.isLoadingBtn = true
    this.accountManageService.updateStatusAccount(body).subscribe(
      () => {
        this.httpObservable.handleSuccess({
          messageSuccess: MESSAGE_SUCCESS.ACTIVE,
          title: TITLE_PAGE.ACTIVE_ACCOUNT,
          callbackSuccess: () => this.callbackSuccess()
        })
        this.emitReloadData.emit()
      },
      (err: { error: { message: string } }) => {
        this.isLoadingBtn = false
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.ACTIVE_ACCOUNT })
      }
    )
  }
}
