<container-ui-choose
  title="Almost done! Let select your ideal package to start."
  subtitle="Select Your Ideal Module"
>
  <div class="control-user">
    <div class="container-hori-tab">
      <div class="hori-tab">
        <div class="tab-item" [class.tab-active]="tabId === 1" (click)="activeTab(1)">Monthly</div>
        <div class="tab-item" [class.tab-active]="tabId === 2" (click)="activeTab(2)">
          Yearly Billing
        </div>
      </div>
      <div class="subtitle-tab">Save up to 20% with yearly billing</div>
    </div>

    <div class="container-slider-user">
      <div class="slider-user">
        <nz-slider [nzMin]="1" [nzMax]="100"></nz-slider>
        <span>50 users</span>
      </div>
      <div class="slider-user-subtitle">Choose Number of User For your Company</div>
    </div>
  </div>

  <app-card-module [listModule]="listModule"></app-card-module>
</container-ui-choose>
