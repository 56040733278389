import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-detail-ticket',
  templateUrl: './detail-ticket.component.html',
  styleUrls: ['./detail-ticket.component.scss']
})
export class DetailTicketComponent implements OnInit {
  @Input() visible: boolean = false
  @Input() handleResolveTicket!: (id: string) => void
  @Output() cancel = new EventEmitter(false)
  isLoadingBtn = false
  constructor() {}

  ngOnInit(): void {}

  handleCancel() {
    this.cancel.emit(false)
  }
}
