<app-container-form>
  <app-heading-page
    title="Get start with Kingwork for free"
    subtitle="Create A Account"
    [titleStyle]="{ fontSize: '24px', fontWeight: '500' }"
    [subtitleStyle]="{ fontSize: '14px' }"
    containerStyle="flexDirection: column-reverse; margin: 70px 0 40px 0"
  ></app-heading-page>
  <form nz-form nzLayout="vertical" [formGroup]="registerForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label nzFor="email">Email</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
        <input
          nz-input
          formControlName="email"
          type="email"
          id="email"
          placeholder="Enter your email address"
          (input)="onInputEmail($event)"
          [nzAutocomplete]="autoEmail"
        />
        <nz-autocomplete #autoEmail>
          <nz-auto-option *ngFor="let option of options" [nzValue]="option">{{
            option
          }}</nz-auto-option>
        </nz-autocomplete>
        <ng-template #emailErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">Please input your email!</ng-container>
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format email!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="password">Password</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
        <app-input-password #inputPassword>
          <input
            id="password"
            [type]="inputPassword.passwordVisible ? 'text' : 'password'"
            nz-input
            placeholder="Enter your password"
            formControlName="password"
            (ngModelChange)="validateConfirmPassword()"
          />
        </app-input-password>
        <ng-template #passwordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please input your password!</ng-container
          >
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your password must contain at least one capital letter and one numeric
            character and must be at least 6 characters!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="confirmedPassword">Confirmed Password</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="confirmPasswordErrorTpl">
        <app-input-password #inputConfirmedPassword>
          <input
            id="confirmedPassword"
            [type]="inputConfirmedPassword.passwordVisible ? 'text' : 'password'"
            nz-input
            placeholder="Enter your confirm password"
            formControlName="confirmedPassword"
          />
        </app-input-password>
        <ng-template #confirmPasswordErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')"
            >Please confirm your password!</ng-container
          >
          <ng-container *ngIf="control.hasError('confirm')"
            >Two passwords that you enter is inconsistent!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="form_checked">
      <nz-form-control nzErrorTip="You must agree Term & Condition and Privacy Policy">
        <label formControlName="agree" nz-checkbox
          >Agree to the
          <a routerLink="#" class="text-blue">Term & Condition</a>
          and
          <a routerLink="#" class="text-blue">Privacy Policy</a>
        </label>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary">Create New Account</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</app-container-form>
