import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { DATE_FORMAT } from '../../../configs/constants'

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  public date: null | Date = null
  public dateFormat = DATE_FORMAT.dd_MM_yyyy
  @Input() placeholder = 'Selected Date'
  @Output() onChangeDate: EventEmitter<Date> = new EventEmitter()
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent
  constructor() {}

  ngOnInit(): void {}

  onChange(result: Date): void {
    this.onChangeDate.emit(result)
  }

  ipen() {
    // this.endDatePicker.open()
  }
}
