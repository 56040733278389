import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { URL_PAGE } from '../../commons/consts/constants'
import { TITLE_PAGE } from '../../commons/enums/common.enum'
import { TenantGuard } from '../../commons/services/guard/tenant.guard'
import { ManageSubscriptionComponent } from '../../features/tenant/manage-subscription/manage-subscription.component'
import { TenantComponent } from './tenant.component'
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.ACCOUNT_MANAGEMENT },
  {
    path: '',
    component: TenantComponent,
    children: [
      {
        path: URL_PAGE.ACCOUNT_MANAGEMENT,
        data: {
          breadcrumb: TITLE_PAGE.ACCOUNT_MANAGEMENT
        },
        canActivate: [TenantGuard],
        loadChildren: () =>
          import('../../features/tenant/user-manage/user-manage.module').then(
            (m) => m.UserManageModule
          )
      },
      {
        path: URL_PAGE.SUBSCRIPTION,
        data: { breadcrumb: TITLE_PAGE.SUBSCRIPTION },
        canActivate: [TenantGuard],
        loadChildren: () =>
          import('../../features/tenant/subscription/subscription.module').then(
            (m) => m.SubscriptionModule
          )
      },
      {
        path: URL_PAGE.MANAGE_SUBSCRIPTION,
        data: { breadcrumb: TITLE_PAGE.MANAGE_SUBSCRIPTION },
        canActivate: [TenantGuard],
        component: ManageSubscriptionComponent
      },
      {
        path: URL_PAGE.PAYMENT,
        data: { breadcrumb: TITLE_PAGE.PAYMENTS },
        canActivate: [TenantGuard],
        loadChildren: () =>
          import('../../features/tenant/payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: URL_PAGE.BILLING_INVOICE,
        data: { breadcrumb: TITLE_PAGE.BILLING_INVOICING },
        canActivate: [TenantGuard],
        loadChildren: () =>
          import('../../features/tenant/billing-invoice/billing-invoice.module').then(
            (m) => m.BillingInvoiceModule
          )
      },
      {
        path: URL_PAGE.HELP_CENTER,
        data: { breadcrumb: TITLE_PAGE.HELP_CENTER },
        canActivate: [TenantGuard],
        loadChildren: () =>
          import('../../features/tenant/help-center/help-center.module').then(
            (m) => m.HelpCenterModule
          )
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TenantRoutingModule {}
