<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="titleDrawer"
  (nzOnClose)="handleCancel()"
  nzWidth="480px"
  [nzFooter]="footerTpl"
>
  <ng-container *nzDrawerContent>
    <p class="suggestions">
      You are trying to deactivate users.<br />Please enter password to continue
    </p>
    <div class="lbl">*{{ generalService.selectedData.length }} users selected</div>
    <app-selected-user-card [listData]="generalService.selectedData"></app-selected-user-card>
  </ng-container>
</nz-drawer>

<ng-template #titleDrawer>
  <app-header-drawer title="Deactivate Account" (cancel)="handleCancel()"></app-header-drawer>
</ng-template>
<ng-template #footerTpl>
  <div class="group-button">
    <button nz-button (click)="handleCancel()" [disabled]="isLoadingBtn">Cancel</button>
    <button
      nz-button
      nzType="primary"
      (click)="onSubmit()"
      [nzLoading]="isLoadingBtn"
      [disabled]="generalService.selectedData.length === 0"
    >
      Deactive Account
    </button>
  </div>
</ng-template>
