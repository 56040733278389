import { Component, OnDestroy, OnInit } from '@angular/core'
import { findIndex } from 'lodash'
import { ProductManagementService } from '../../../api/product-management.service'
import { FEATURE_LIST, PACKAGE_SELECT } from '../../../commons/consts'
import { StorageKey } from '../../../commons/enums/storage-key.enum'
import { GeneralService } from '../../../commons/services/general.service'
import { SessionStorageService } from '../../../commons/services/session/session-storage.service'
import { FeatureList, ResponseListPackage, RowStorage } from '../../../interfaces/subscription'
import { TITLE_PAGE } from './../../../commons/enums/common.enum'
import { HttpObservablesService } from './../../../commons/services/connection/http-observables.service'

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  public visible = false
  public featureList: FeatureList[] = []
  public rowStorage!: RowStorage
  public listPackage: ResponseListPackage[] = []
  public PACKAGE_SELECT = PACKAGE_SELECT
  public totalUserCurrent = 0
  public totalUser = 0
  public indexItemCurrent = 0

  public subscriptionClick!: ResponseListPackage

  public hasNextPlan = false
  constructor(
    public generalService: GeneralService,
    private productManagement: ProductManagementService,
    private httpObservable: HttpObservablesService,
    private sessionService: SessionStorageService
  ) {}
  ngOnDestroy(): void {
    this.sessionService.set(StorageKey.HAS_NEXT_PLAN, false)
  }

  ngOnInit(): void {
    this.hasNextPlan = this.sessionService.getBoolean(StorageKey.HAS_NEXT_PLAN)
    this.getListPackage()
  }

  getListPackage() {
    return this.productManagement.getListPackage().subscribe(
      (res) => {
        this.indexItemCurrent = findIndex<ResponseListPackage>(res.data, (item) => item.current)
        res.data.forEach((item: any) => {
          if (item.current) {
            this.totalUserCurrent = item.currentUser
            this.totalUser = item.totalUser
          }
        })
        this.featureList = FEATURE_LIST.map((element) => ({
          name: element.name,
          trial: res.data[0][element.name] || false,
          basic: res.data[1][element.name] || false,
          advance: res.data[2][element.name] || false
        }))
        this.rowStorage = {
          trial: res.data[0]['storage'],
          basic: res.data[1]['storage'],
          advance: res.data[2]['storage']
        }
        this.listPackage = res.data
      },
      (err: { error: any }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.SUBSCRIPTION
        })
      }
    )
  }

  showSelectPackage(itemPackage: ResponseListPackage) {
    this.visible = true
    this.generalService.selectedItem.next(itemPackage)
    this.subscriptionClick = itemPackage
  }

  cancelDrawerUpgrade(event: any) {
    this.visible = event
  }

  formatText = () => {
    return `${this.totalUserCurrent} / ${this.totalUser}`
  }

  checkHasUpgradePackage(indexItem: number, item: ResponseListPackage) {
    if (!this.hasNextPlan && indexItem > this.indexItemCurrent) {
      return true
    } else if (this.hasNextPlan && item.userPrice !== 0) {
      return true
    } else {
      return false
    }
  }
}
