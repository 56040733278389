<div class="container_tabs">
  <div
    class="tab"
    *ngFor="let item of listTab; trackBy: trackByFn"
    [class]="id === item.id ? 'active' : 'inactive'"
    (click)="activeTab(item.id)"
  >
    {{ item.title }}
    <nz-badge
      nzStandalone
      *ngIf="visibleCountNumber ? true : false"
      [nzShowZero]="visibleCountNumber ? true : false"
      [nzCount]="item.count"
      [nzOverflowCount]="999"
      [class]="id === item.id ? 'badge-active' : 'badge-inactive'"
    ></nz-badge>
  </div>
</div>

<ng-template #notShowCount></ng-template>
