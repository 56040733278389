<div class="modal-edit-new-user">
  <div class="edit-user">
    <form nz-form [formGroup]="editAccountForm" (ngSubmit)="submitForm()">
      <div class="title-edit-user">
        <div class="title-left">
          <app-heading-page
            [containerStyle]="{ gap: '0px' }"
            title="Edit User Information"
          ></app-heading-page>
        </div>
      </div>

      <div class="content-edit-user">
        <div class="content-user-info">
          <p class="content-title">User Information</p>
          <p class="content-description">Input basic information of user</p>
        </div>
        <div class="form-edit-user">
          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="firstName"
              >First Name</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
              nzErrorTip="Please input your first name!"
            >
              <input
                id="firstName"
                nz-input
                formControlName="firstName"
                placeholder="Enter first name"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="lastName"
              >Last Name</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
              nzErrorTip="Please input your last name!"
            >
              <input
                id="lastName"
                nz-input
                formControlName="lastName"
                placeholder="Enter last name"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="userName"
              >User Name</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzErrorTip="Please input your user name!"
              nzHasFeedback
            >
              <input
                id="userName"
                nz-input
                formControlName="userName"
                placeholder="Enter user name"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="password"
              >Password</nz-form-label
            >
            <nz-form-control [nzSpan]="nzSpanFormControl" nzErrorTip="Please input your password!">
              <app-input-password [disabled]="true" #inputPassword>
                <input
                  nz-input
                  [type]="inputPassword.passwordVisible ? 'text' : 'password'"
                  id="password"
                  placeholder="****************"
                  formControlName="passWord"
                />
              </app-input-password>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="workEmail"
              >Work Email</nz-form-label
            >
            <nz-form-control [nzErrorTip]="emailErrorTpl" [nzSpan]="nzSpanFormControl">
              <input
                id="workEmail"
                nz-input
                formControlName="workEmail"
                placeholder="Enter user’s work email"
              />
              <ng-template #emailErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')"
                  >Please input your email!</ng-container
                >
                <ng-container *ngIf="control.hasError('pattern')"
                  >Please input your format email!</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzFor="phoneNumber"
              >Phone Number</nz-form-label
            >
            <nz-form-control nzHasFeedback [nzSpan]="nzSpanFormControl">
              <input
                id="phoneNumber"
                nz-input
                formControlName="phoneNumber"
                placeholder="Enter user phone number"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired>Role</nz-form-label>
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
              nzErrorTip="Please select your role!"
            >
              <nz-select nzAllowClear nzPlaceHolder="Choose user role" formControlName="role">
                <nz-option
                  *ngFor="let role of roleList"
                  [nzValue]="role.id"
                  [nzLabel]="role.name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="line-content"></div>

        <div class="content-application-access">
          <p class="content-title">Applications Access</p>
          <p class="content-description">Choose applications for user accessibility</p>
          <div class="group-checbox-application">
            <span nz-checkbox>HRM - Web Application</span>
            <span nz-checkbox>HRM - Mobile Application</span>
          </div>
        </div>
      </div>

      <nz-form-item>
        <nz-form-control>
          <div class="group-button">
            <button nz-button (click)="handleCancel()" [disabled]="isLoadingBtn">Cancel</button>
            <button nz-button nzType="primary" [nzLoading]="isLoadingBtn">Edit User</button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
