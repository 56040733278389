export interface RequestAccount {
  firstName: string
  lastName: string
  username?: string
  password?: string
  phoneNumber: string
  email?: string
  roleId: number
  status?: StatusAccount
}

export interface ResponseAccount {
  firstName: string
  lastName: string
  username: string
  password: string
  email: string
  status: StatusAccount
  phoneNumber: string
  location: string
  roles: Roles[]
  tenantId: string
  createdAt: string
  id: string
}
export enum StatusAccount {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE'
}

export interface Roles {
  id: number
  name: string
}

export interface RequestAccountStatus {
  //password: string
  status: StatusAccount
  accountIds: Array<string | undefined>
}
