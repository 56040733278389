<container-ui-choose
  title="Choose Service That You Interesting"
  subtitle="Select Your Ideal Service"
>
  <!-- <app-card-service [listProduct]="listProduct"></app-card-service> -->
  <div nz-row [nzGutter]="[24, 24]" nzJustify="center" *ngIf="listProduct">
    <div
      *ngFor="let item of listProduct"
      nz-col
      [nzXs]="24 / 1"
      [nzMd]="24 / 2"
      [nzLg]="24 / 3"
      [nzXXl]="24 / 4"
      (click)="checkProduct(item)"
      [style.pointer-events]="item.disabled && 'none'"
    >
      <app-card [class.focus-card]="item.isCheck">
        <i *ngIf="item.isCheck" nz-icon nzType="custom_icon:ticked" class="icon_ticked"></i>
        <img [src]="item.avatar || defaultAvatar" class="avatar" />
        <div class="detail">
          <h3 class="title">{{ item.name }}</h3>
          <p
            class="description"
            nz-typography
            [nzEllipsisRows]="2"
            nzEllipsis
            [attr.title]="item.description"
          >
            {{ item.description }}
          </p>
        </div>
      </app-card>
    </div>
  </div>
  <button nz-button nzType="primary" class="btn_next" (click)="submitForm()">Next</button>
</container-ui-choose>
