import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { QuillEditorComponent } from 'ngx-quill'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { TicketManagementService } from '../../../api/ticket-management.service'
import { TabTableProps } from '../../../interfaces/common'
import { ListDetailFAQ, ListFAQ } from '../../../interfaces/ticket-mamanent'
@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {
  @ViewChild('editor') editor!: QuillEditorComponent
  formPhoneNumber!: FormGroup
  listDetailFAQ?: ListDetailFAQ
  listTab: TabTableProps[] = [
    {
      id: 1,
      title: 'FAQ'
    },
    {
      id: 2,
      title: 'History Request'
    }
  ]
  listFAQ$!: Observable<ListFAQ[]>
  constructor(private fb: FormBuilder, private ticketService: TicketManagementService) {}

  ngOnInit(): void {
    this.formPhoneNumber = this.fb.group({
      phoneNumber: [null, [Validators.required]]
    })
    this.getListFAQ()
  }

  getListFAQ() {
    this.listFAQ$ = this.ticketService.getListFAQ().pipe(
      map((res) => {
        if (res.data) {
          this.listDetailFAQ = res.data[0].list_data[0]
          res.data[0].list_data.push({
            name: 'VIll asd',
            note: '<ol><li>sdadasda</li></ol>',
            question_id: 2,
            status: 0
          })
          return res.data
        }
      })
    )
  }

  onChangeAlign(typeAlign: string) {
    this.editor.quillEditor.format('align', typeAlign)
  }

  submitRequestTicket() {}
}
