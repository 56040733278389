import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import jsPDF from 'jspdf'
import { BillingInvoiceService } from '../../../../api/billing-invoice.service'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'
import { GeneralService } from '../../../../commons/services/general.service'
import { TableService } from '../../../../commons/services/table.service'
import { ValidationService } from '../../../../commons/services/validation.service'
import { ResponsePayment } from '../../../../interfaces/subscription'
import { numberNoComma } from '../../../../utils/format'
import { blobToB64 } from '../../../../utils/function'

@Component({
  selector: 'app-send-invoice',
  templateUrl: './send-invoice.component.html',
  styleUrls: ['./send-invoice.component.scss']
})
export class SendInvoiceComponent implements OnInit, OnChanges {
  listSelectedEmail: string[] = []
  paymentIds: (string | number)[] = []
  numberNoComma = numberNoComma
  listEmailSuggest: string[] = []
  isLoadingBtn = false
  base64Pdf: string = ''
  @Input() visible = false
  @Output() cancel = new EventEmitter(false)
  public formSendInvoice!: FormGroup
  constructor(
    private fb: FormBuilder,
    private tableService: TableService<ResponsePayment>,
    private billingInvoiceService: BillingInvoiceService,
    private httpObservable: HttpObservablesService,
    private validation: ValidationService,
    private general: GeneralService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.paymentIds = Array.from(this.tableService.setOfCheckedId)
  }

  ngOnInit(): void {
    this.formSendInvoice = this.fb.group({
      emails: [null, [Validators.required]]
    })
  }

  sendInvoice() {
    this.isLoadingBtn = true
    this.generatePdf()
  }

  generatePdf() {
    this.billingInvoiceService.exportInvoice(this.paymentIds).subscribe((res) => {
      this.general.dataPDF = res
    })
    this.general.generatePDF({
      element: document.getElementById('htmlData')!,
      isSavePdf: false,
      callbackFn: this.convertBlobToBase64
    })
  }

  convertBlobToBase64 = (doc: jsPDF) => {
    blobToB64(doc.output('blob')).then((res) => {
      const value = res as string
      return this.sendMail(value.split(',')[1])
    })
  }

  sendMail(b64: string) {
    if (this.formSendInvoice.valid) {
      const body = {
        emails: this.listSelectedEmail,
        fileContent: b64
      }
      this.billingInvoiceService.sendInvoice(body).subscribe(
        () => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.SEND_INVOICE,
            title: TITLE_PAGE.BILLING_INVOICING
          })
          this.isLoadingBtn = false
        },
        (err: { error: any }) => {
          this.httpObservable.handleError({
            error: err.error,
            title: TITLE_PAGE.BILLING_INVOICING
          })
          this.isLoadingBtn = false
        }
      )
    } else {
      this.validation.checkAllValidation(this.formSendInvoice.controls)
    }
  }

  onSearch(value: string) {
    if (!value || value.indexOf('@') >= 0) {
      this.listEmailSuggest = []
    } else {
      this.listEmailSuggest = ['gmail.com', 'innotech-vn.com', 'outlook.com'].map(
        (domain) => `${value}@${domain}`
      )
    }
  }

  removeItem(id: string | number) {
    this.tableService.removeChecked(id)
    this.paymentIds = this.paymentIds.filter((item) => item !== id)
  }

  onCancel() {
    this.cancel.emit(false)
  }

  trackByFn(index: number): number {
    return index
  }
}
