import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../app/ng-zorro-antd.module'
import { SharedModule } from '../../shared/shared.module'
import { AuthRoutingModule } from './auth-routing.module'
import { AuthComponent } from './auth.component'
import { ContainerFormComponent } from './components/container-form/container-form.component'
import { ResourcesExportComponent } from './components/resources-export/resources-export.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { LoginComponent } from './login/login.component'
import { MandatoryInfoComponent } from './mandatory-info/mandatory-info.component'
import { RegisterComponent } from './register/register.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
@NgModule({
  imports: [
    AuthRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule
  ],
  exports: [],
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ContainerFormComponent,
    ResetPasswordComponent,
    MandatoryInfoComponent,
    ResourcesExportComponent
  ],
  providers: [],
  bootstrap: [AuthComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule {}
