import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { URL_PAGE } from '../../consts'
import { TYPE_SITE } from '../../enums/common.enum'
import { GeneralService } from '../general.service'
import { SessionStorageService } from '../session/session-storage.service'

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate {
  constructor(private router: Router, private sessionStorage: SessionStorageService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const typeSite = JSON.parse(this.sessionStorage.getDataUser() as string)?.roles[0].type
    if (typeSite === TYPE_SITE.TENANT) {
      return true
    }
    this.router.navigateByUrl(URL_PAGE.NOT_FOUND)
    return false
  }
}
