import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TabTableProps } from '../../../interfaces/common'

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  public id = 1
  @Input() listTab: TabTableProps[] = []
  @Input() visibleCountNumber = true
  @Output() changeTab: EventEmitter<number> = new EventEmitter()
  constructor() {}

  ngOnInit(): void {}
  activeTab(id: number) {
    this.id = id
    this.changeTab.emit(id)
  }

  trackByFn(index: number): number {
    return index
  }
}
