import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { URL_PAGE } from '../../commons/consts/constants'
import { TITLE_PAGE } from '../../commons/enums/common.enum'
import { AdminGuard } from '../../commons/services/guard/admin.guard'
import { TicketComponent } from '../../features/admin/ticket/ticket.component'
import { AdminComponent } from './admin.component'
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.TENANT_MANAGEMENT },
  {
    path: URL_PAGE.PRODUCT_MANAGEMENT,
    pathMatch: 'full',
    redirectTo: URL_PAGE.PRODUCT_MANAGEMENT + '/' + URL_PAGE.PACKAGES
  },
  { path: '**', redirectTo: URL_PAGE.NOT_FOUND },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: URL_PAGE.TENANT_MANAGEMENT,
        data: {
          breadcrumb: TITLE_PAGE.TENANT_MANAGEMENT
        },
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('../../features/admin/tenant-management/tenant-management.module').then(
            (m) => m.TenantManagementModule
          )
      },
      {
        path: URL_PAGE.ROLE_MANAGEMENT,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: TITLE_PAGE.ROLE_MANAGEMENT
        },
        loadChildren: () =>
          import('../../features/admin/role-management/role-management.module').then(
            (m) => m.RoleManagementModule
          )
      },
      {
        path: URL_PAGE.PRODUCT_MANAGEMENT,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: TITLE_PAGE.PRODUCT_MANAGEMENT
        },
        loadChildren: () =>
          import('../../features/admin/product-management/product-management.module').then(
            (m) => m.ProductManagementModule
          )
      },
      {
        path: URL_PAGE.BILLING_INVOICE_TENANT,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: TITLE_PAGE.BILLING_INVOICING
        },
        loadChildren: () =>
          import(
            '../../features/admin/billing-invoice-management/billing-invoice-management.module'
          ).then((m) => m.BillingInvoiceManagementModule)
      },
      {
        path: URL_PAGE.TICKET,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: TITLE_PAGE.TICKET
        },
        component: TicketComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
