import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgZorroAntdModule } from '../../../app/ng-zorro-antd.module'
import { SharedModule } from '../../../shared/shared.module'
import { SubscriptionModule } from './../subscription/subscription.module'
import { PaymentCompleteComponent } from './payment-complete/payment-complete.component'
import { PaymentRoutingModule } from './payment-routing.module'
import { PaymentComponent } from './payment.component'

@NgModule({
  imports: [
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    PaymentRoutingModule,
    SubscriptionModule
  ],
  exports: [],
  declarations: [PaymentComponent, PaymentCompleteComponent],
  providers: [],
  bootstrap: [PaymentComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PaymentModule {}
