<div class="select-package" *ngIf="selectedItem">
  <app-heading-page title="Select Package" subtitle="Choose your ideal plan"></app-heading-page>
  <nz-radio-group
    [(ngModel)]="period"
    (ngModelChange)="onChangeRadio($event)"
    nzButtonStyle="solid"
    class="radio-choose-time"
  >
    <label nz-radio-button [nzValue]="PACKAGE_TIME.MONTHLY">Monthly</label>
    <label nz-radio-button [nzValue]="PACKAGE_TIME.YEARLY">Annually</label>
  </nz-radio-group>
</div>
<div class="container-list-package">
  <app-card class="focus-card" *ngIf="selectedItem">
    <div nz-row class="card-top" nzJustify="space-between" nzAlign="middle">
      <label nz-radio [ngModel]="true" nz-col nzSpan="11" class="radio">{{
        selectedItem.name || selectedItem.packageHistory.name
      }}</label>
      <div class="card-price" nz-col nzSpan="11">
        <div class="card-price-number">
          {{ selectedItem.userPrice || selectedItem.packageHistory.userPrice | currency : 'VND' }}
        </div>
        <div class="card-price-prefix">user/month</div>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div class="card-bottom" nz-row nzJustify="space-between" nzAlign="middle">
      <div nz-col nzSpan="11" class="text-number-user">User</div>
      <nz-input-group
        [nzAddOnBefore]="addIconDecrease"
        [nzAddOnAfter]="addIconIncrease"
        nz-col
        nzSpan="9"
        class="input-group-number"
      >
        <nz-input-number
          [nzStep]="1"
          [nzMin]="selectedItem.totalUser"
          [ngModel]="totalUser"
          (ngModelChange)="onChangeTotalUser($event)"
        ></nz-input-number>
      </nz-input-group>
    </div>
  </app-card>
</div>
<ng-template #addIconDecrease>
  <button
    class="addon addon-decrease"
    (click)="decreaseUserNumber()"
    [disabled]="totalUser <= selectedItem.totalUser"
  >
    <i nz-icon nzType="minus" nzTheme="outline"></i>
  </button>
</ng-template>
<ng-template #addIconIncrease>
  <button class="addon addon-increase" (click)="increaseUserNumber()">
    <i nz-icon nzType="plus" nzTheme="outline"></i>
  </button>
</ng-template>
