<nz-date-picker
  #endDatePicker
  [(ngModel)]="date"
  [nzDateRender]="tplRender"
  [nzSuffixIcon]="customIconSuffixDate"
  [nzPlaceHolder]="placeholder"
  [nzRenderExtraFooter]="tplRenderFooter"
  [nzFormat]="dateFormat"
  (ngModelChange)="onChange($event)"
  (nzOnOpenChange)="ipen()"
  [nzShowToday]="false"
></nz-date-picker>
<ng-template #customIconSuffixDate>
  <i nz-icon nzType="down" nzTheme="outline"></i>
</ng-template>
<ng-template #tplRender let-current>
  <div class="ant-picker-cell-inner">
    {{ current.getDate() }}
  </div>
</ng-template>
<ng-template #tplRenderFooter>
  <div class="footer">
    <button nz-button class="btn-date-picker">1 Month Ago</button>
    <button nz-button class="btn-date-picker">3 Months Ago</button>
  </div>
</ng-template>
