import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import APIs from '../commons/consts/API'
import { environment } from '../environments/environment'

const SERVER_URL = environment.API_WS_SERVER_URL
@Injectable({
  providedIn: 'root'
})
export class TicketManagementService {
  constructor(private http: HttpClient) {}
  getListFAQ(): Observable<any> {
    return this.http.get(SERVER_URL + APIs.LIST_FAQ)
  }

  postRequestTicket(): Observable<any> {
    return this.http.get(SERVER_URL + APIs.LIST_FAQ)
  }
}
