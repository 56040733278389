import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { tap } from 'rxjs/operators'
import { ParamsHandleError, ParamsHandleSuccess } from '../../../interfaces/common'
import { APP_CONST } from '../../consts'
import { ERROR_CODE, TYPE_MESSAGE } from '../../enums/common.enum'
import { GeneralService } from '../general.service'

@Injectable({
  providedIn: 'root'
})
export class HttpObservablesService {
  constructor(private httpClient: HttpClient, private generalService: GeneralService) {}
  async postPublicUrl(requestURL: any, body: any) {
    body = this.generalService.setUsernameAuthenticationInfo(body)

    // let accessToken: any = this.stateService.getState('accessToken');
    let accessToken: any = localStorage.getItem('accessToken')
    console.log('accessToken: ', accessToken)

    let headers: any = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,POST,GET'
    }
    // if (environment.production) {
    //   if (environment.CAS_COGNITO) {
    //     headers = {
    //       'Content-Type': 'application/json;charset=UTF-8',
    //       Authorization: accessToken,
    //       'x-api-key': environment.COGNITO.x_api_key
    //     }
    //   } else {
    //     if (this.generalService.checkHasToken(accessToken)) {
    //       headers['Authorization'] = 'Bearer ' + accessToken
    //     } else if (headers.hasOwnProperty('Authorization')) {
    //       delete headers['Authorization']
    //     }
    //   }
    // }

    return await this.httpClient
      .post(requestURL, body, { headers })
      .pipe(
        tap(
          // Log the result or error
          (data) => console.log(data),
          (error) => console.log(error)
        )
      )
      .toPromise()
      .then(
        (data) => {
          // console.log("Successful ", data);
          return data
        },
        (error) => {
          // console.log("Error", error);
          return { error: [{ message: APP_CONST.SERVER_ERROR }] }
        }
      )
  }

  handleError({ error, title, callbackError }: ParamsHandleError) {
    this.generalService.callNotification(
      TYPE_MESSAGE.ERROR,
      title,
      error.status === ERROR_CODE.UNAUTHORIZED ? error.message : error.errors[0]
    )
    callbackError && callbackError()
  }

  handleSuccess({ messageSuccess, title, callbackSuccess }: ParamsHandleSuccess) {
    this.generalService.callNotification(TYPE_MESSAGE.SUCCESS, title, messageSuccess)
    callbackSuccess && callbackSuccess()
  }
}
