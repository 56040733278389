import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-waiting-email',
  templateUrl: './waiting-email.component.html',
  styleUrls: ['./waiting-email.component.scss']
})
export class WaitingEmailComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  goKingWork() {
    window.location.href = 'https://kingwork.vn'
  }
}
