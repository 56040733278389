<div class="container_list_filter">
  <nz-select nzAllowClear ngModel="role" [nzCustomTemplate]="customSelect" nzPlaceHolder="Role">
    <nz-option nzValue="role" nzLabel="Role"></nz-option>
    <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucydsa" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucydas" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucyd" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucys" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucyc" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucyss" nzLabel="Lucy"></nz-option>
    <nz-option nzValue="lucya" nzLabel="Lucy"></nz-option>
  </nz-select>
  <app-date-picker placeholder="Created Date"></app-date-picker>
  <app-date-picker placeholder="Last Login Date"></app-date-picker>
</div>

<ng-template #customSelect let-selected>
  <ng-container>
    <i nz-icon nzType="user" nzTheme="outline"></i>
    {{ selected.nzLabel }}
  </ng-container>
</ng-template>
