import { Component, OnInit } from '@angular/core'

export interface ListModule {
  id: number
  title: string
  subtitle: string
  price: string
  titleOffer: string
  offerList: OfferList[]
}

interface OfferList {
  active: boolean
  content: string
}
@Component({
  selector: 'app-choose-module',
  templateUrl: './choose-module.component.html',
  styleUrls: ['./choose-module.component.scss']
})
export class ChooseModuleComponent implements OnInit {
  public tabId = 1
  public listModule: ListModule[] = [
    {
      id: 1,
      title: 'Free',
      subtitle: 'Always free for 10 Users',
      price: '0',
      titleOffer: 'What we offer',
      offerList: [
        {
          active: true,
          content: 'Up to 10 Users'
        },
        {
          active: false,
          content: 'Unlimited features'
        },
        {
          active: false,
          content: '2 GB of Storage'
        }
      ]
    },
    {
      id: 2,
      title: 'Basic',
      subtitle: 'Free Trial for 30 days with unlimited features',
      price: '40.000',
      titleOffer: 'What we offer',
      offerList: [
        {
          active: true,
          content: 'Employee Personel'
        },
        {
          active: true,
          content: 'Team management'
        },
        {
          active: true,
          content: 'Timesheet'
        },
        {
          active: true,
          content: 'Leave off'
        },
        {
          active: true,
          content: '200 GB of Storage'
        }
      ]
    },
    {
      id: 3,
      title: 'Premium',
      subtitle: 'Free Trial for 30 days with unlimited features',
      price: '80.000',
      titleOffer: 'Everything from Basic plus',
      offerList: [
        {
          active: true,
          content: 'Onboarding'
        },
        {
          active: true,
          content: 'Offboarding'
        },
        {
          active: true,
          content: 'Asset Management'
        },
        {
          active: true,
          content: 'Project Management'
        },
        {
          active: true,
          content: 'Payroll'
        },
        {
          active: true,
          content: 'Unlimited Storage'
        },
        {
          active: true,
          content: '24/7 Premium support'
        }
      ]
    }
  ]
  constructor() {}

  ngOnInit(): void {}

  activeTab(tabId: number) {
    this.tabId = tabId
  }
}
