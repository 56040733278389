<div class="container_header">
  <app-heading-page
    title="Payment list"
    subtitle="Manage all of payment in your company efficiently."
  ></app-heading-page>
</div>
<nz-divider nzOrientation="left"></nz-divider>
<nz-table
  #nzTable
  nzShowSizeChanger
  [nzLoading]="tableService.loading"
  [nzPageSizeOptions]="[10, 20, 50, 100]"
  [nzData]="listOfDisplayData"
  (nzCurrentPageDataChange)="tableService.onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th
        *ngFor="let column of listOfColumn"
        [nzSortFn]="column.sorter"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
        [nzAlign]="column.align"
      >
        {{ column.title }}
      </th>
      <th nzWidth="10px" nzAlign="center"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of nzTable.data">
      <td (click)="showPaymentDetail(data)">{{ data.productName | uppercase }}</td>
      <td (click)="showPaymentDetail(data)">{{ data.name }}</td>
      <td (click)="showPaymentDetail(data)">
        <ng-container [ngSwitch]="data.status">
          <nz-badge
            nz-col
            nzText="Failed"
            nzStatus="error"
            *ngSwitchCase="PAYMENT_STATUS.FAIL"
          ></nz-badge>
          <nz-badge
            nz-col
            nzText="Successful"
            nzStatus="success"
            *ngSwitchCase="PAYMENT_STATUS.SUCCESS"
          ></nz-badge>
          <nz-badge
            nz-col
            nzText="Pending"
            nzStatus="warning"
            *ngSwitchCase="PAYMENT_STATUS.PENDING"
          ></nz-badge>
        </ng-container>
      </td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">{{ data.totalUser }}</td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">
        {{ data.startDate | date : formatDate }}
      </td>
      <td (click)="showPaymentDetail(data)" nzAlign="right">
        {{ data.endDate | date : formatDate }}
      </td>
      <td nzAlign="center">
        <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
          <i nz-icon nzType="more" nzTheme="outline" class="icon-more"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li
              nz-menu-item
              (click)="goPayment(data, $event)"
              [nzDisabled]="isDisabledMenuAction(data)"
            >
              <span nz-icon nzType="shopping-cart" nzTheme="outline"></span>
              Payment
            </li>
            <li
              nz-menu-item
              (click)="showSelectPackage(data, $event)"
              [nzDisabled]="isDisabledMenuAction(data)"
            >
              <span nz-icon nzType="to-top" nzTheme="outline"></span>
              Upgrade
            </li>
            <li nz-menu-item (click)="goAddUser()">
              <span nz-icon nzType="user-add" nzTheme="outline"></span>
              Add User
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>
</nz-table>
<!-- <div nz-col nzSpan="7">
  <app-heading-page
    title="Payment History"
    subtitle="View all your payment history"
  ></app-heading-page>
  <div class="list-payment-history">
    <div class="container-card" *ngFor="let item of listPaymentHistory">
      <div class="card-header">
        <div nz-row nzJustify="space-between">
          <p class="subtitle" nz-col>{{ item.updatedAt }}</p>
          <ng-container [ngSwitch]="item.status">
            <nz-badge
              nz-col
              nzText="Failed"
              nzStatus="error"
              *ngSwitchCase="PAYMENT_STATUS.FAIL"
            ></nz-badge>
            <nz-badge
              nz-col
              nzText="Success"
              nzStatus="success"
              *ngSwitchCase="PAYMENT_STATUS.SUCCESS"
            ></nz-badge>
            <nz-badge
              nz-col
              nzText="Pending"
              nzStatus="warning"
              *ngSwitchCase="PAYMENT_STATUS.PENDING"
            ></nz-badge>
          </ng-container>
        </div>
        <h4 class="title">#{{ item.id }}</h4>
      </div>
      <nz-divider style="margin: 12px 0px"></nz-divider>
      <div class="card-content">
        <div nz-row [nzGutter]="[0, 16]">
          <div nz-col nzSpan="12" class="column">
            <i nz-icon nzType="icon:package" class="icon"></i>
            <span>{{ item.packageHistory.name }}</span>
          </div>
          <div nz-col nzSpan="12" class="column">
            <i nz-icon nzType="icon:currency_dollar_circle" class="icon"></i>
            <span>{{ item.packageHistory.userPrice }}$</span>
          </div>
        </div>

        <div nz-row [nzGutter]="[0, 16]">
          <div nz-col nzSpan="12" class="column">
            <i nz-icon nzType="icon:user" class="icon icon-user"></i>
            <span>{{ item.totalUser }} Users</span>
          </div>
          <div nz-col nzSpan="12" class="column">
            <i nz-icon nzType="icon:card" class="icon"></i>
            <span>by Creditcard</span>
          </div>
        </div>
      </div>
    </div>
    <nz-pagination
      [nzPageSize]="limitDataPaymentHistory"
      [nzPageIndex]="paginationPaymentHistory.pageIndex"
      [nzTotal]="paginationPaymentHistory.total"
      (nzPageIndexChange)="handleChangePage($event)"
    ></nz-pagination>
  </div>
</div> -->

<app-upgrade-drawer
  [visible]="visibleUpgrade"
  [isPageSubscription]="false"
  (emitReloadData)="getListPayment()"
  (cancel)="visibleUpgrade = $event"
></app-upgrade-drawer>

<app-detail-payment-invoice
  titleHeader="Payment detail"
  [visible]="visiblePaymentDetail"
  (cancel)="visiblePaymentDetail = $event"
></app-detail-payment-invoice>
