import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { isBoolean } from 'lodash'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'
import { RequestResetCustomerPassword } from '../../../interfaces/auth'
import APIs from '../../consts/API'
import { StorageKey } from '../../enums/storage-key.enum'
import { CacheService } from '../cache/cache.service'
import { CommonService } from '../connection/common.service'
import { HttpObservablesService } from '../connection/http-observables.service'
import { SessionStorageService } from '../session/session-storage.service'
import { StateService } from '../state.service'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

const SERVER_URL = environment.API_WS_SERVER_URL
@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  constructor(
    protected httpObservablesService: HttpObservablesService,
    protected commonService: CommonService,
    private stateService: StateService,
    private cache: CacheService,
    private session: SessionStorageService,
    private http: HttpClient,
    private tokenStorage: SessionStorageService
  ) {}

  // async Login(bodyData: any) {
  //   let url = environment.API_WS_SERVER_URL + '/api' + APIs.AUTH_LOGIN
  //   return await this.httpObservablesService.postPublicUrl(url, bodyData)
  // }

  login(bodyData: any): Observable<any> {
    return this.http.post(SERVER_URL + APIs.AUTH_LOGIN, bodyData, httpOptions)
  }

  register(bodyData: any): Observable<any> {
    //return of({}) //this.http.post(SERVER_URL + APIs.AUTH_REGISTER, bodyData, httpOptions)
    return this.http.post(SERVER_URL + APIs.AUTH_REGISTER, bodyData, httpOptions)
  }

  validateRegister(bodyData: any): Observable<any> {
    return this.http.post(SERVER_URL + APIs.AUTH_REGISTER_VALIDATE, bodyData, httpOptions)
  }
  logout() {
    this.stateService.setState(StorageKey.IS_LOGIN, false)
    this.tokenStorage.remove(StorageKey.TOKEN_KEY)
    this.tokenStorage.remove(StorageKey.USER_KEY)
  }

  requestChangePassword(email: string): Observable<any> {
    return this.http.get(SERVER_URL + APIs.REQUEST_CHANGE_PASSWORD + `?email=${email}`)
  }

  resetCustomerPassword(body: RequestResetCustomerPassword): Observable<any> {
    return this.http.put(SERVER_URL + APIs.CHANGE_CUSTOMER_PASSWORD, body, httpOptions)
  }

  isLoggedIn(): boolean {
    const isBool = isBoolean(this.stateService.getState(StorageKey.IS_LOGIN))
    return isBool && this.stateService.getState(StorageKey.IS_LOGIN)
  }

  reAuthenticate(refreshToken: string): Observable<any> {
    return this.http.post(APIs.RE_AUTHENTICATE, { refreshToken })
  }

  // logoutTimeout(): Observable<any> {
  //   return this.proxy.get<any>(this.config.logoutTimeout)
  // }

  hasSession(): boolean {
    const session = this.session.get(StorageKey.LOGINED_SESSION)
    return !!session
  }

  setLogin(key: string): void {
    this.cache.set(key, true)
  }

  setSession(key: string): void {
    this.session.set(key, true)
  }

  clearSession(): void {
    this.session.remove(StorageKey.FIRST_LOGIN)
  }

  clearLogin(): void {
    this.cache.remove(StorageKey.FIRST_LOGIN)
  }

  isFirstTimeLogin(): boolean {
    return !!this.cache.get(StorageKey.FIRST_LOGIN)
  }

  redirect(path: string) {
    window.location.href = path
  }
}
