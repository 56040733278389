import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { SubScriptionService } from '../../../../api/subscription.service'
import { MESSAGE_SUCCESS, PACKAGE_TIME, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { StorageKey } from '../../../../commons/enums/storage-key.enum'
import { GeneralService } from '../../../../commons/services/general.service'
import { SessionStorageService } from '../../../../commons/services/session/session-storage.service'
import { URL_PAGE } from './../../../../commons/consts/constants'
import { HttpObservablesService } from './../../../../commons/services/connection/http-observables.service'
import { ResponseListPackage, ResponsePayment } from './../../../../interfaces/subscription'

@Component({
  selector: 'app-upgrade-drawer',
  templateUrl: './upgrade-drawer.component.html',
  styleUrls: ['./upgrade-drawer.component.scss']
})
export class UpgradeDrawerComponent implements OnInit {
  @Input() visible = false
  @Input() isPageSubscription = true
  public selectedItem!: ResponsePayment & ResponseListPackage
  public isLoadingBtn = false
  public totalUser = 1
  public period = PACKAGE_TIME.MONTHLY
  public hasNextPlan = false
  @Output() cancel = new EventEmitter()
  @Output() emitReloadData = new EventEmitter()

  constructor(
    private generalService: GeneralService,
    private subscriptionService: SubScriptionService,
    private httpObservable: HttpObservablesService,
    private router: Router,
    private sessionService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.generalService.selectedItem.subscribe((item: ResponsePayment & ResponseListPackage) => {
      item.totalUser === 0 ? (item.totalUser = 1) : item.totalUser
      this.period = item.period || PACKAGE_TIME.MONTHLY
      this.totalUser = item.totalUser
      this.selectedItem = { ...item }
    })

    this.hasNextPlan = this.sessionService.getBoolean(StorageKey.HAS_NEXT_PLAN)
  }

  handleCancel() {
    this.cancel.emit(false)
  }

  callbackSuccess() {
    this.handleCancel()
    const redirectPage = this.hasNextPlan ? URL_PAGE.MANAGE_SUBSCRIPTION : URL_PAGE.PAYMENT
    this.isPageSubscription ? this.router.navigateByUrl(redirectPage) : this.emitReloadData.emit()
    this.isLoadingBtn = false
  }

  onSubmit() {
    const hasNextPlan = this.sessionService.getBoolean(StorageKey.HAS_NEXT_PLAN)
    const body = {
      id: this.isPageSubscription ? undefined : this.selectedItem.id,
      packageId: this.isPageSubscription ? this.selectedItem.id : this.selectedItem.packageId,
      totalUser: this.totalUser,
      period: this.period,
      nextPlan: hasNextPlan
    }
    this.isLoadingBtn = true
    this.subscriptionService.upgradePackage(body).subscribe(
      () => {
        this.httpObservable.handleSuccess({
          messageSuccess: MESSAGE_SUCCESS.REGISTER_PACKAGE,
          title: TITLE_PAGE.REGISTER_PACKAGE,
          callbackSuccess: () => this.callbackSuccess()
        })
      },
      (err: { error: { message: string } }) => {
        this.isLoadingBtn = false
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.REGISTER_PACKAGE })
      }
    )
  }
}
