import { Component, OnInit } from '@angular/core'
import { URL_PAGE } from '../../../../commons/consts'

@Component({
  selector: 'app-container-form',
  templateUrl: './container-form.component.html',
  styleUrls: ['./container-form.component.scss']
})
export class ContainerFormComponent implements OnInit {
  URL_PAGE = URL_PAGE
  constructor() {}

  ngOnInit(): void {}
}
