import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { URL_PAGE } from '../../../commons/consts'
import { TITLE_PAGE } from '../../../commons/enums/common.enum'
import { AddUserComponent } from './add-user/add-user.component'
import { EditUserComponent } from './edit-user/edit-user.component'
import { UserManageComponent } from './user-manage.component'

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.ACCOUNT_MANAGEMENT },
  {
    path: '',
    component: UserManageComponent
  },
  {
    path: URL_PAGE.ADD_ACCOUNT,
    component: AddUserComponent,
    data: {
      breadcrumb: TITLE_PAGE.ADD_ACCOUNT
    }
  },
  {
    path: `${URL_PAGE.EDIT_ACCOUNT}/:id`,
    component: EditUserComponent,
    data: {
      breadcrumb: TITLE_PAGE.EDIT_ACCOUNT
    }
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManageRoutingModule {}
