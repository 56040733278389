<a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
  <i nz-icon nzType="more" nzTheme="outline"></i>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item routerLink="/edit-user">
      <span nz-icon nzType="menu_action:user_edit"></span>
      Edit User
    </li>
  </ul>
</nz-dropdown-menu>
