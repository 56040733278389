import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { URL_PAGE } from '../../../commons/consts'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../commons/enums/common.enum'
import { AuthServices } from '../../../commons/services/authen/auth.service'
import { GeneralService } from '../../../commons/services/general.service'
import { HttpObservablesService } from './../../../commons/services/connection/http-observables.service'

export interface ListProduct {
  id: string
  avatar: string
  name: string
  description: string
  isCheck: boolean
  disabled: boolean
}
@Component({
  selector: 'app-choose-service',
  templateUrl: './choose-service.component.html',
  styleUrls: ['./choose-service.component.scss']
})
export class ChooseServiceComponent implements OnInit {
  defaultAvatar = ''
  public listProduct: ListProduct[] = [
    {
      id: '1',
      avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      name: 'Human Resource Management',
      description: 'HRMS helps to manage human resources, business processes and data efficently.',
      isCheck: false,
      disabled: false
    },
    {
      id: '2',
      avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      name: 'Next Product 1',
      description:
        'Mi facilisis proin sagittis fusce habitant nisi, feugiat sed. Libero sed turpis elit pharetra praesent quam.',
      isCheck: false,
      disabled: true
    },
    {
      id: '3',
      avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      name: 'Next Product 2',
      description:
        'Non interdum nunc sed semper. Posuere praesent ullamcorper habitant nam convallis.',
      isCheck: false,
      disabled: true
    }
  ]
  constructor(
    private authService: AuthServices,
    private router: Router,
    private general: GeneralService,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    // this.productService.getListProduct().subscribe((res) => {
    //   this.listProduct = res.data
    // })
  }

  checkProduct(item: ListProduct) {
    this.listProduct.forEach((element) => {
      if (item.id === element.id) {
        element.isCheck = true
      } else {
        element.isCheck = false
      }
    })
  }

  submitForm() {
    let body = this.general.registerTenant
    body['productId'] = '1'
    this.authService.register(body).subscribe(
      (res: any) => {
        this.httpObservable.handleSuccess({
          messageSuccess: MESSAGE_SUCCESS.REGISTER,
          title: TITLE_PAGE.REGISTER
        })
        this.router.navigateByUrl(URL_PAGE.WAITING_EMAIL)
      },
      (err: { error: { message: any } }) => {
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.REGISTER })
      }
    )
  }
}
