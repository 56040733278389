<app-profile
  titleHeader="User Profile"
  [isStatusActive]="isStatusActive"
  [userDetail]="userDetail"
  [btnProfileTpl]="btnProfileTpl"
  [visibleDrawer]="visible"
  [goEditUser]="goEditUser"
  [userName]="userDetail ? userDetail.username : ''"
  (cancel)="handleCancel()"
>
  <ng-container content-profile *ngIf="userDetail">
    <div class="info-list">
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">First Name</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.firstName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Last Name</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.lastName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">User Name</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.username }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Work Email</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.email }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Phone Number</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.phoneNumber }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Role</div>
        <div nz-col class="info-list-content" nzSpan="14" *ngIf="userDetail.roles">
          {{ userDetail.roles[0].name }}
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Created Date</div>
        <div nz-col class="info-list-content" nzSpan="14">
          {{ userDetail.createdAt | date : formatDate }}
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Status</div>
        <div nz-col class="info-list-content" nzSpan="14">
          <nz-badge nzText="Active" nzStatus="success" *ngIf="isStatusActive"></nz-badge>
          <nz-badge nzText="Deactive" nzStatus="error" *ngIf="!isStatusActive"></nz-badge>
        </div>
      </div>
    </div>

    <nz-divider class="divider"></nz-divider>

    <div class="app-access" nz-row>
      <div nz-col nzSpan="10" class="info-list-title">Accessibility</div>
      <div nz-col class="info-list-content" nzSpan="14">
        <p class="">HRM - Web Application</p>
        <p>HRM - Mobile Application</p>
      </div>
    </div>
  </ng-container>
</app-profile>

<ng-template #btnProfileTpl>
  <button nz-button (click)="changeStatusAccount()" *ngIf="userDetail" [nzLoading]="isLoadingBtn">
    {{ isStatusActive ? 'Deactivate Account' : 'Active Account' }}
  </button>
  <button nz-button (click)="resetPassword()" *ngIf="isStatusActive" [disabled]="isLoadingBtn">
    Reset Password
  </button>
</ng-template>

<app-de-activate-user></app-de-activate-user>
