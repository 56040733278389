import { FeatureList } from '../../interfaces/subscription'
import { LIST_NAME_FEATURE } from '../enums/common.enum'

export const APP_CONST = {
  ENG_OPTION: { value: 'en', label: 'English' },
  BRA_OPTION: { value: 'br', label: 'Portuguese' },
  SERVER_ERROR: 'Internal Server Error'
}

export const PACKAGE_SELECT = {
  TRIAL: 'Trial',
  BASIC: 'Basic',
  PREMIUM: 'Exclusive'
}

export const ROLE_LIST = [
  { id: 1, name: 'Site Admin' },
  { id: 2, name: 'Product Admin' },
  { id: 3, name: 'Member' }
]

export const PAYMENT_SELECT = {
  ONLINE_PAYMENT: 'Online Payment',
  BANK_TRANSFER: 'Bank Transfer'
}

export const FEATURE_LIST: FeatureList[] = [
  { name: LIST_NAME_FEATURE.PERSONNEL, trial: false, basic: false, advance: false },
  { name: LIST_NAME_FEATURE.TIMESHEET, trial: false, basic: false, advance: false },
  { name: LIST_NAME_FEATURE.LEAVES, trial: false, basic: false, advance: false },
  { name: LIST_NAME_FEATURE.PAYROLL, trial: false, basic: false, advance: false },
  { name: LIST_NAME_FEATURE.EQUIPMENT, trial: false, basic: false, advance: false },
  { name: LIST_NAME_FEATURE.PROJECT, trial: false, basic: false, advance: false },
  { name: LIST_NAME_FEATURE.SUPPORT, trial: false, basic: false, advance: false }
]

export const BANK_LIST = {
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD'
}

export const URL_PAGE = {
  DASHBOARD: 'dashboard',

  /* Tenant */
  ACCOUNT_MANAGEMENT: 'account-management',
  ADD_ACCOUNT: 'add-account',
  EDIT_ACCOUNT: 'edit-account',

  SUBSCRIPTION: 'subscription',
  MANAGE_SUBSCRIPTION: 'manage-subscription',
  PAYMENT: 'payment',
  PAYMENT_DETAIL: 'payment-detail',
  BILLING_INVOICE: 'billing-invoice',
  HELP_CENTER: 'help-center',

  /* Admin */
  TENANT_MANAGEMENT: 'tenant-management',
  PRODUCT_MANAGEMENT: 'product-management',
  PACKAGES: 'packages',
  PROMOTION: 'product-management/promotion',
  TICKET: 'ticket',
  ROLE_MANAGEMENT: 'role-management',
  BILLING_INVOICE_TENANT: 'billing-invoice-tenant',

  /* Other */
  LOGIN: 'login',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  MANDATORY_INFO: 'mandatory-info',
  NOT_FOUND: 'not-found',
  CHOOSE_SERVICE: 'choose-service',
  WAITING_EMAIL: 'waiting-email'
}
