<app-heading-page title="Ticket" subtitle="Manage all Ticket"></app-heading-page>
<div nz-row nzJustify="space-between" class="container-toolbar">
  <app-tabs [listTab]="listTab" #tab nz-col></app-tabs>
  <app-input-search nz-col></app-input-search>
</div>
<nz-table
  #nzTable
  nzShowSizeChanger
  [nzLoading]="false"
  [nzPageSizeOptions]="[10, 20, 50, 100]"
  [nzData]="listOfDisplayData"
  (nzCurrentPageDataChange)="tableService.onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th nzWidth="50px" nzAlign="center">No</th>
      <th
        *ngFor="let column of listOfColumn"
        [nzSortFn]="column.sorter"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
        [nzAlign]="column.align"
      >
        {{ column.title }}
      </th>
      <th nzWidth="50px" nzAlign="center"></th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let data of nzTable.data | search : tableService.searchValue; let indexItem = index"
    >
      <td nzAlign="center" (click)="showDetailTicket(data.id)">{{ generateSTT(indexItem) }}</td>
      <td (click)="showDetailTicket(data.id)">
        {{ data.requesterName }}
      </td>
      <td (click)="showDetailTicket(data.id)" nzAlign="right">
        {{ data.date | date : dd_MM_yyyy }}
      </td>
      <td (click)="showDetailTicket(data.id)" nzAlign="right">{{ data.phoneNumber }}</td>
      <td (click)="showDetailTicket(data.id)">
        {{ data.subject }}
      </td>
      <td (click)="showDetailTicket(data.id)">
        {{ data.description }}
      </td>
      <td nzAlign="center">
        <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
          <i nz-icon nzType="more" nzTheme="outline" class="icon-more"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li
              *ngIf="tab.id === 1"
              nz-menu-item
              nz-popconfirm
              nzPopconfirmTitle="Are you sure resolve this?"
              nzPopconfirmPlacement="bottom"
              (nzOnConfirm)="handleResolveTicket(data.id)"
            >
              <i nz-icon nzType="menu_action:user_edit" class="custom-anticon"></i>
              Resolve
            </li>
            <li nz-menu-item (click)="showDetailTicket(data.id)">
              <span nz-icon nzType="info-circle" nzTheme="outline"></span>
              Detail Ticket
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-detail-ticket
  [visible]="visibleDetailTicket"
  [handleResolveTicket]="handleResolveTicket"
  (cancel)="visibleDetailTicket = false"
></app-detail-ticket>
