<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="titleDrawer"
  (nzOnClose)="handleCancel()"
  nzWidth="480px"
  [nzFooter]="footerTpl"
>
  <ng-container *nzDrawerContent>
    <p class="suggestions">
      You are trying to activate users.<br />Please enter password to continue
    </p>

    <!-- <form nz-form [formGroup]="formActivate" nzLayout="vertical">
      <div nz-col>
        <nz-form-control nzErrorTip="Please input your confirm password!">
          <app-input-password #inputPassword>
            <input
              nz-input
              [type]="inputPassword.passwordVisible ? 'text' : 'password'"
              formControlName="password"
              placeholder="*************"
            />
          </app-input-password>
        </nz-form-control>
      </div>
    </form> -->
    <div class="lbl">*{{ generalService.selectedData.length }} users selected</div>
    <app-selected-user-card [listData]="generalService.selectedData"></app-selected-user-card>
  </ng-container>
</nz-drawer>

<ng-template #titleDrawer>
  <app-header-drawer
    title="Activate Account"
    (cancel)="handleCancel()"
    [disabled]="isLoadingBtn"
  ></app-header-drawer>
</ng-template>
<ng-template #footerTpl>
  <div class="group-button">
    <button nz-button (click)="handleCancel()">Cancel</button>
    <button
      nz-button
      nzType="primary"
      (click)="onSubmit()"
      [disabled]="generalService.selectedData.length === 0"
      [nzLoading]="isLoadingBtn"
    >
      Activate Account
    </button>
  </div>
</ng-template>
