import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { ActivationUserComponent } from '../../activation-user/activation-user.component'
import { DeActivateUserComponent } from '../../de-activate-user/de-activate-user.component'

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { ResponseAccount } from '../../../../../interfaces/user-manage'
import { TabTableProps } from '../../../../../interfaces/common'
import { TableService } from '../../../../../commons/services/table.service'

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnChanges {
  @ViewChild(ActivationUserComponent) userActivationComp!: ActivationUserComponent
  @ViewChild(DeActivateUserComponent) userDeActivationComp!: DeActivateUserComponent
  @Output() changeTab = new EventEmitter<number>()
  @Output() emitReloadData = new EventEmitter()
  @Input() countActive = 0
  @Input() countDeactive = 0

  public isBtnResponsiveMobile = false
  public tabId!: number
  public isShowListFilter = false
  public listTab: TabTableProps[] = []

  constructor(
    public table: TableService<ResponseAccount>,
    private responsive: BreakpointObserver
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.listTab = [
      {
        id: 1,
        title: 'Active',
        count: this.countActive
      },
      {
        id: 2,
        title: 'Inactive',
        count: this.countDeactive
      }
    ]
  }

  ngOnInit(): void {
    this.handleResponsive()
  }

  handleResponsive() {
    this.responsive.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.isBtnResponsiveMobile = false
      if (result.matches) {
        this.isBtnResponsiveMobile = true
      }
    })
  }

  showActivation() {
    this.userActivationComp.visible = true
  }
  showDeActivation() {
    this.userDeActivationComp.visible = true
  }

  acceptData() {
    this.emitReloadData.emit()
  }

  onChangeTab(id: number) {
    this.tabId = id
    this.changeTab.emit(id)
  }
}
