import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UserManagementService } from '../../../../api/user-management.service'
import { MESSAGE_SUCCESS, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'
import { GeneralService } from '../../../../commons/services/general.service'
import { TableService } from '../../../../commons/services/table.service'
import { StatusAccount } from './../../../../interfaces/user-manage'

@Component({
  selector: 'app-de-activate-user',
  templateUrl: './de-activate-user.component.html',
  styleUrls: ['./de-activate-user.component.scss']
})
export class DeActivateUserComponent implements OnInit {
  public visible = false
  public isLoadingBtn = false
  @Output() emitReloadData = new EventEmitter()

  constructor(
    public generalService: GeneralService,
    private accountManageService: UserManagementService,
    private httpObservable: HttpObservablesService,
    private tableService: TableService<any>
  ) {}

  ngOnInit(): void {}

  handleCancel() {
    this.visible = false
  }

  callbackSuccess() {
    this.visible = false
    this.generalService.selectedData = []
    this.isLoadingBtn = false
  }

  onSubmit() {
    const listIdAccount = Array.from(this.tableService.setOfCheckedId) as Array<string | undefined>
    const body = {
      status: StatusAccount.DEACTIVE,
      accountIds: listIdAccount
    }
    this.isLoadingBtn = true
    this.accountManageService.updateStatusAccount(body).subscribe(
      () => {
        this.httpObservable.handleSuccess({
          messageSuccess: MESSAGE_SUCCESS.DEACTIVATE,
          title: TITLE_PAGE.DEACTIVATE_ACCOUNT,
          callbackSuccess: () => this.callbackSuccess()
        })
        this.emitReloadData.emit()
      },
      (err: { error: { message: string } }) => {
        this.httpObservable.handleError({
          error: err.error,
          title: TITLE_PAGE.DEACTIVATE_ACCOUNT
        })
        this.isLoadingBtn = false
      }
    )
  }
}
