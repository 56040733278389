import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-choose-link',
  templateUrl: './choose-link.component.html',
  styleUrls: ['./choose-link.component.scss']
})
export class ChooseLinkComponent implements OnInit {
  listLink = [
    {
      title: 'HRMS',
      description:
        'Main system that allow all of your company employee access to various function of HR process.'
    },
    {
      title: 'Admin Cloud',
      description: 'The admin portal helps you manage your services and your members.'
    }
  ]
  constructor() {}

  ngOnInit(): void {}
}
