import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'table-cell-avatar',
  templateUrl: './table-cell-avatar.component.html',
  styleUrls: ['./table-cell-avatar.component.scss']
})
export class TableCellAvatarComponent implements OnInit {
  @Input() title = ''
  constructor() {}

  ngOnInit(): void {}
}
