import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { URL_PAGE } from '../commons/consts'
import { AuthGuard } from '../commons/services/guard/auth.guard'
import { ChooseLinkComponent } from '../features/auth/choose-link/choose-link.component'
import { WaitingEmailComponent } from '../features/auth/waiting-email/waiting-email.component'
import { NotFoundComponent } from '../shared/components/not-found/not-found.component'

// const routes: Routes = GeneralService.isAdminLogin ? ROUTES_ADMIN : ROUTES_TENANT
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: URL_PAGE.LOGIN
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('../layout/admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('../layout/tenant/tenant.module').then((m) => m.TenantModule)
  },
  {
    path: 'choose-link',
    component: ChooseLinkComponent
  },
  {
    path: URL_PAGE.WAITING_EMAIL,
    component: WaitingEmailComponent
  },
  {
    path: URL_PAGE.NOT_FOUND,
    component: NotFoundComponent
  },
  {
    path: '',
    loadChildren: () => import('../features/auth/auth.module').then((m) => m.AuthModule)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
