import { ValidationService } from './../../../../commons/services/validation.service'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GeneralService } from '../../../../commons/services/general.service'
import { Regexs } from '../../../../configs/regexs'

@Component({
  selector: 'app-reset-password-user',
  templateUrl: './reset-password-user.component.html',
  styleUrls: ['./reset-password-user.component.scss']
})
export class ResetPasswordUserComponent implements OnInit {
  resetPassUserForm!: FormGroup
  @Output() cancel = new EventEmitter(false)
  @Input() visible = false
  constructor(
    private fb: FormBuilder,
    public generalService: GeneralService,
    private validation: ValidationService
  ) {}

  ngOnInit(): void {
    this.resetPassUserForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(Regexs.EMAIL)]]
    })
  }

  get email() {
    return this.resetPassUserForm.controls.email
  }

  handleCancel() {
    this.cancel.emit(false)
  }

  onSubmit(): void {
    if (this.resetPassUserForm.valid) {
      console.log(this.email.value)
    } else {
      this.validation.checkAllValidation(this.resetPassUserForm.controls)
    }
  }
}
