import { MESSAGE_SUCCESS, TITLE_PAGE } from './../../../commons/enums/common.enum'
import { ValidationService } from './../../../commons/services/validation.service'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Regexs } from '../../../configs/regexs'
import { AuthServices } from '../../../commons/services/authen/auth.service'
import { GeneralService } from '../../../commons/services/general.service'
import { HttpObservablesService } from '../../../commons/services/connection/http-observables.service'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm!: FormGroup
  constructor(
    private fb: FormBuilder,
    private validation: ValidationService,
    private authService: AuthServices,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(Regexs.EMAIL)]]
    })
  }
  submitForm(): void {
    if (this.forgotForm.valid) {
      this.authService.requestChangePassword(this.forgotForm.controls.email.value).subscribe(
        (res) => {
          this.httpObservable.handleSuccess({
            messageSuccess: MESSAGE_SUCCESS.REQUEST_CHANGE_PASSWORD,
            title: TITLE_PAGE.RESET_PASSWORD_CUSTOMER
          })
        },
        (err: { error: { message: string } }) =>
          this.httpObservable.handleError({
            error: err.error,
            title: TITLE_PAGE.RESET_PASSWORD_CUSTOMER
          })
      )
    } else {
      this.validation.checkAllValidation(this.forgotForm.controls)
    }
  }
}
