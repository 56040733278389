<app-drawer titleHeader="Subscription" (cancel)="handleCancel()" [visible]="visible">
  <ng-container drawerContent>
    <app-select-package
      (changePackageTime)="period = $event"
      (changeTotalUser)="totalUser = $event"
      [period]="period"
      [totalUser]="totalUser"
    ></app-select-package>
  </ng-container>
  <ng-container drawerBtn>
    <ng-container>
      <button nz-button (click)="handleCancel()" [disabled]="isLoadingBtn">Cancel</button>
      <button nz-button nzType="primary" (click)="onSubmit()" [nzLoading]="isLoadingBtn">
        Submit
      </button>
    </ng-container>
  </ng-container>
</app-drawer>
