import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() titleHeader: string = ''
  @Input() isStatusActive: boolean = true
  @Input() userDetail: any
  @Input() userName: string = ''
  @Input() btnProfileTpl!: TemplateRef<any>
  @Input() goEditUser!: () => void
  @Input() visibleDrawer: boolean = false
  @Output() cancel = new EventEmitter(false)
  constructor() {}

  ngOnInit(): void {}

  onCancel() {
    this.cancel.emit(false)
  }
}
