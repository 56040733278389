<div class="container">
  <a routerLink="/"><img src="assets/images/logo.png" class="logo_kingwork" /></a>
  <app-heading-page
    [title]="title"
    [subtitle]="subtitle"
    [containerStyle]="styleContainerHeadingPage"
    [titleStyle]="{ fontSize: '24px', fontWeight: '500' }"
    [subtitleStyle]="{ fontSize: '14px' }"
  ></app-heading-page>
  <ng-content></ng-content>
</div>
