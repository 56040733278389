import { Component } from '@angular/core'
import { NzIconService } from 'ng-zorro-antd/icon'
import { icons } from '../assets/icons'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private iconRegister: NzIconService) {
    this.iconRegister.addIconLiteral('icon:currency_dollar_circle', icons.currency_dollar_circle)
    this.iconRegister.addIconLiteral('icon:ticked_circle', icons.ticked_circle)
    this.iconRegister.addIconLiteral('icon:package', icons.package)
    this.iconRegister.addIconLiteral('icon:user', icons.user_menu)
    this.iconRegister.addIconLiteral('icon:card', icons.card)
    this.iconRegister.addIconLiteral('icon:send', icons.send)
    this.iconRegister.addIconLiteral('icon:export', icons.export)
    this.iconRegister.addIconLiteral('icon:print', icons.print)
    this.iconRegister.addIconLiteral('icon:eye', icons.eye)
    this.iconRegister.addIconLiteral('icon:eye_invisible', icons.eye_invisible)
    this.iconRegister.addIconLiteral('icon:tenant_management', icons.tenant_management)
    this.iconRegister.addIconLiteral('icon:download', icons.download)
    this.iconRegister.addIconLiteral('icon:message_question', icons.message_question)
    this.iconRegister.addIconLiteral('icon:task', icons.task)
    this.iconRegister.addIconLiteral('icon:align_left', icons.align_left)
    this.iconRegister.addIconLiteral('icon:align_center', icons.align_center)
    this.iconRegister.addIconLiteral('icon:align_right', icons.align_right)
    this.iconRegister.addIconLiteral('icon:align_justify', icons.align_justify)
    this.iconRegister.addIconLiteral('menu_action:user_edit', icons.user_edit)
    this.iconRegister.addIconLiteral('menu_action:key', icons.key)
    this.iconRegister.addIconLiteral('custom_icon:ticked', icons.ticked)
    this.iconRegister.addIconLiteral('custom:card_circle', icons.card_circle)
    this.iconRegister.addIconLiteral('custom:logout_circle', icons.logout_circle)
    this.iconRegister.addIconLiteral('icon:profile_circle', icons.profile_circle)
    this.iconRegister.addIconLiteral('menu:dashboard', icons.dashboard_menu)
    this.iconRegister.addIconLiteral('menu:card_tick', icons.card_tick)
    this.iconRegister.addIconLiteral('menu:receipt', icons.receipt)
    this.iconRegister.addIconLiteral('menu:book', icons.book)
    this.iconRegister.addIconLiteral('menu:logout', icons.logout)
    this.iconRegister.addIconLiteral('menu:switch_horizontal', icons.switch_horizontal)
    this.iconRegister.addIconLiteral('header:arrow_right', icons.arrow_right)
  }
}
