<div class="modal-add-new-user">
  <div class="add-new-user">
    <form nz-form [formGroup]="createNewUserForm" (ngSubmit)="submitForm()">
      <div class="title-add-new-user">
        <div class="title-left">
          <app-heading-page
            title="Add New User"
            subtitle="Create new user in your system."
          ></app-heading-page>
        </div>
        <div class="container_active_user">
          <nz-switch formControlName="statusUser"></nz-switch>
          <div class="title-right">Active User</div>
        </div>
      </div>

      <div class="content-add-new-user">
        <div class="content-user-info">
          <p class="content-title">User Information</p>
          <p class="content-description">Input basic information of user</p>
        </div>
        <div class="form-add-new-user">
          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="firstName"
              >First Name</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
              [nzErrorTip]="firstNameErrorTpl"
            >
              <input
                id="firstName"
                nz-input
                formControlName="firstName"
                placeholder="Enter first name"
              />
              <ng-template #firstNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')"
                  >Please input your first name!</ng-container
                >
                <ng-container *ngIf="control.hasError('pattern')"
                  >Please input your format name!</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="lastName"
              >Last Name</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
              [nzErrorTip]="lastNameErrorTpl"
            >
              <input
                id="lastName"
                nz-input
                formControlName="lastName"
                placeholder="Enter last name"
              />
              <ng-template #lastNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')"
                  >Please input your last name!</ng-container
                >
                <ng-container *ngIf="control.hasError('pattern')"
                  >Please input your format name!</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="username"
              >User Name</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              [nzErrorTip]="userNameErrorTpl"
              nzHasFeedback
            >
              <input
                id="username"
                nz-input
                formControlName="userName"
                placeholder="Enter user name"
              />
              <ng-template #userNameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')"
                  >Please input your username!</ng-container
                >
                <ng-container *ngIf="control.hasError('pattern')"
                  >Please input your format name!</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="password"
              >Password</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              [nzErrorTip]="passWordErrorTpl"
              nzHasFeedback
            >
              <app-input-password #inputPassword>
                <input
                  nz-input
                  [type]="inputPassword.passwordVisible ? 'text' : 'password'"
                  id="password"
                  placeholder="****************"
                  formControlName="passWord"
                  (ngModelChange)="validateConfirmPassword()"
                />
                <ng-template #passWordErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')"
                    >Please input your password!</ng-container
                  >
                </ng-template>
              </app-input-password>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="confirmedPassword"
              >Confirm Password</nz-form-label
            >
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              [nzErrorTip]="confirmPasswordErrorTpl"
              nzHasFeedback
            >
              <app-input-password #inputConfirmedPassword>
                <input
                  nz-input
                  [type]="inputConfirmedPassword.passwordVisible ? 'text' : 'password'"
                  id="confirmedPassword"
                  placeholder="****************"
                  formControlName="confirmedPassword"
                />
                <ng-template #confirmPasswordErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')"
                    >Please confirm your password!</ng-container
                  >
                  <ng-container *ngIf="control.hasError('confirm')"
                    >Two passwords that you enter is inconsistent!</ng-container
                  >
                </ng-template>
              </app-input-password>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired nzFor="workEmail"
              >Work Email</nz-form-label
            >
            <nz-form-control
              [nzErrorTip]="emailErrorTpl"
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
            >
              <input
                id="workEmail"
                nz-input
                formControlName="workEmail"
                placeholder="Enter user’s work email"
              />
              <ng-template #emailErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')"
                  >Please input your email!</ng-container
                >
                <ng-container *ngIf="control.hasError('pattern')"
                  >Please input your format email!</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzFor="phoneNumber"
              >Phone Number</nz-form-label
            >
            <nz-form-control
              nzHasFeedback
              [nzSpan]="nzSpanFormControl"
              [nzErrorTip]="phoneNumberErrorTpl"
            >
              <input
                id="phoneNumber"
                nz-input
                formControlName="phoneNumber"
                placeholder="Enter user phone number"
              />
              <ng-template #phoneNumberErrorTpl let-control>
                <ng-container *ngIf="control.hasError('pattern')"
                  >Please input your format phone number!</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzNoColon [nzSpan]="nzSpanLabel" nzRequired>Role</nz-form-label>
            <nz-form-control
              [nzSpan]="nzSpanFormControl"
              nzHasFeedback
              nzErrorTip="Please select your role!"
            >
              <nz-select nzAllowClear nzPlaceHolder="Choose user role" formControlName="role">
                <nz-option
                  *ngFor="let role of roleList"
                  [nzValue]="role.id"
                  [nzLabel]="role.name"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="line-content"></div>

        <div class="content-application-access">
          <p class="content-title">Applications Access</p>
          <p class="content-description">Choose applications for user accessibility</p>
          <div class="group-checbox-application">
            <span nz-checkbox>HRM - Web Application</span>
            <span nz-checkbox>HRM - Mobile Application</span>
          </div>
        </div>
      </div>

      <nz-form-item>
        <nz-form-control>
          <div class="group-button">
            <button nz-button (click)="handleCancel()" [disabled]="isLoadingBtn">Cancel</button>
            <button nz-button nzType="primary" [nzLoading]="isLoadingBtn">Create User</button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
