import { Component, Input, OnInit } from '@angular/core'
import { ListModule } from '../../choose-module/choose-module.component'

@Component({
  selector: 'app-card-module',
  templateUrl: './card-module.component.html',
  styleUrls: ['./card-module.component.scss']
})
export class CardModuleComponent implements OnInit {
  id!: number
  @Input() listModule!: ListModule[]

  constructor() {}

  ngOnInit(): void {}
}
