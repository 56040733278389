export enum TYPE_MESSAGE {
  ERROR = 'error',
  SUCCESS = 'success'
}

export enum TITLE_PAGE {
  /* Tenant */
  DASHBOARD = 'Dashboard',
  /* Account Management*/
  ACCOUNT_MANAGEMENT = 'Account Management',
  ADD_ACCOUNT = 'Add Account',
  EDIT_ACCOUNT = 'Update Account',
  DEACTIVATE_ACCOUNT = 'Deactivate Account',
  ACTIVE_ACCOUNT = 'Active Account',

  /* Subscription & Payment */
  SUBSCRIPTION = 'Subscription',
  REGISTER_PACKAGE = 'Register Package',
  PAYMENTS = 'Payments',
  PAYMENT_DETAIL = 'Payment Detail',
  MANAGE_SUBSCRIPTION = 'Manage Subscriptions',

  BILLING_INVOICING = 'Billing & Invoicing',
  HELP_CENTER = 'Help Center',

  /* Admin */
  TENANT_MANAGEMENT = 'Tenant Management',
  PRODUCT_MANAGEMENT = 'Product Management',
  PACKAGES = 'Packages',
  PROMOTION = 'Promotion',
  TICKET = 'Ticket',

  /* Other */
  LOGIN = 'Login',
  REGISTER = 'Register',
  RESET_PASSWORD_CUSTOMER = 'Reset Password Customer',
  RESET_PASSWORD_ACCOUNT = 'Send Reset Email Successfully',
  ROLE_MANAGEMENT = 'Role Management',
  AUTH = 'Authentication'
}
export enum MESSAGE_SUCCESS {
  LOGIN = 'Login Successfully',
  REGISTER = 'Register Successfully',
  ADD_ACCOUNT = 'You have created account successfully.',
  EDIT_ACCOUNT = 'Your changes in user profile have been saved',
  DEACTIVATE = 'These user have been deactivate successfully',
  ACTIVE = 'These user have been active successfully',
  REGISTER_PACKAGE = 'Register package is success',
  REQUEST_CHANGE_PASSWORD = 'An email has been sent to user to set a new password, please ask user to check inbox and access to the link.',
  RESET_PASSWORD_CUSTOMER = 'Reset password is success',
  APPROVE_TENANT = 'Approved tenant is success',
  SEND_INVOICE = 'You have sent invoices successfully to selected mails.',
  ADD_TENANT = 'You have created tenant successfully.',
  EDIT_TENANT = 'Your changes in tenant profile have been saved.',
  EDIT_PACKAGES = 'Your changes in package have been saved.'
}

export enum PAYMENT_STATUS {
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}
export enum PACKAGE_TIME {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export enum LIST_NAME_FEATURE {
  PERSONNEL = 'personnel',
  TIMESHEET = 'timesheet',
  LEAVES = 'leaves',
  PAYROLL = 'payroll',
  EQUIPMENT = 'equipment',
  PROJECT = 'project',
  SUPPORT = 'support'
}

export enum TYPE_ACTION {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete'
}

export enum TYPE_SITE {
  TENANT = 'tenant',
  ITV = 'itv'
}

export enum ERROR_CODE {
  UNAUTHORIZED = 401
}
