import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  SERVER_API_URL = ''
  constructor() {}
  setServerAPIURL(serverAPIURL: any) {
    this.SERVER_API_URL = serverAPIURL
  }
  getServerAPIURL() {
    return this.SERVER_API_URL + '/'
  }
}
