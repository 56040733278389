<app-heading-page
  title="Request history"
  subtitle="View your request history here"
  [containerStyle]="{ marginTop: '40px' }"
></app-heading-page>
<nz-table
  #nzTable
  nzShowSizeChanger
  [nzLoading]="tableService.loading"
  [nzPageSizeOptions]="[10, 20, 50, 100]"
  [nzData]="listOfDisplayData"
  (nzCurrentPageDataChange)="tableService.onCurrentPageDataChange($event)"
>
  <thead>
    <tr>
      <th nzWidth="80px" nzAlign="center">No</th>
      <th
        *ngFor="let column of listOfColumn"
        [nzSortFn]="column.sorter"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
        [nzAlign]="column.align"
      >
        {{ column.title }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of nzTable.data; let indexItem = index">
      <td nzAlign="center">{{ generateSTT(indexItem) }}</td>
      <td nzAlign="right">{{ data?.packageHistory?.name }}</td>
      <td>
        {{ data.id }}
      </td>
      <td>{{ data.totalUser }}</td>
      <td nzAlign="right">
        {{ data.startDate | date : dd_MM_yyyy }}
      </td>
      <td>
        <div [ngSwitch]="data.status">
          <nz-badge
            *ngSwitchCase="PAYMENT_STATUS.SUCCESS"
            nzText="Resolve"
            nzStatus="success"
          ></nz-badge>
          <nz-badge
            *ngSwitchCase="PAYMENT_STATUS.PENDING"
            nzText="In Progress "
            nzStatus="warning"
          ></nz-badge>
          <nz-badge *ngSwitchCase="PAYMENT_STATUS.FAIL" nzText="Failed" nzStatus="error"></nz-badge>
          <ng-container *ngSwitchDefault></ng-container>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
