import { Component, OnInit } from '@angular/core'
import { TableService } from '../../../commons/services/table.service'
import { DATE_FORMAT } from '../../../configs/constants'
import { TabTableProps } from '../../../interfaces/common'
import { generateSTT } from '../../../utils/function'
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  visibleDetailTicket = false
  generateSTT = generateSTT
  dd_MM_yyyy = DATE_FORMAT.dd_MM_yyyy
  listTab: TabTableProps[] = [
    {
      id: 1,
      count: 10,
      title: 'New'
    },
    {
      id: 2,
      count: 2,
      title: 'Resolved'
    }
  ]
  listOfColumn: any = [
    {
      title: 'Requester Name'
    },
    {
      title: 'Date',
      align: 'right'
    },
    {
      title: 'Phone Number',
      align: 'right'
    },
    {
      title: 'Subject'
    },
    {
      title: 'Description'
    }
  ]
  listOfDisplayData = [
    {
      requesterName: 'Jenny Wilson',
      date: 3213213213,
      phoneNumber: '0799899899',
      subject: 'Login error',
      description: 'Carrier violation (30007)'
    },
    {
      requesterName: 'Jenny Wilson',
      date: 3213213213,
      phoneNumber: '0799899899',
      subject: 'Login error',
      description: 'Carrier violation (30007)'
    }
  ]
  constructor(public tableService: TableService<any>) {}

  ngOnInit(): void {}

  showDetailTicket(id: string) {
    this.visibleDetailTicket = true
  }

  handleResolveTicket(id: string) {}

  getListTicket() {}
}
