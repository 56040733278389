import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  @Input() title: string = ''
  @Input() content: string = ''
  @Input() iconType: string = ''
  @Input() widthModal: string = '440px'
  @Input() okText: string = 'Ok'
  @Input() cancelText: string = 'Cancel'
  @Output() onOk = new EventEmitter()
  @Output() onCancel = new EventEmitter()
  constructor(private modal: NzModalService) {}

  ngOnInit(): void {}

  showModal() {
    return this.modal.confirm({
      nzTitle: this.title,
      nzContent: this.content,
      nzIconType: this.iconType ? this.iconType : 'question-circle',
      nzWidth: this.widthModal,
      nzClosable: false,
      nzOkText: this.okText,
      nzCancelText: this.cancelText,
      nzOnOk: () => this.onOk.emit(),
      nzOnCancel: () => this.onCancel.emit(),
      nzCentered: true,
      nzClassName: this.content ? '' : 'custom-modal-confirm'
    })
  }
}
