<app-container-form>
  <h3 class="title-form">Request to find your account</h3>
  <form nz-form nzLayout="vertical" [formGroup]="forgotForm" (ngSubmit)="submitForm()">
    <nz-form-item>
      <nz-form-label nzFor="email">Email</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
        <input
          nz-input
          formControlName="email"
          type="email"
          id="email"
          placeholder="Enter your email address"
        />
        <ng-template #emailErrorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">Please input your email!</ng-container>
          <ng-container *ngIf="control.hasError('pattern')"
            >Please input your format email!</ng-container
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div class="sub_content">
      To recover your account, please enter your email, we will send an email to your admin
      requesting a password reset.
    </div>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary">Send Request</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</app-container-form>
