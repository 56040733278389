import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import APIs from '../commons/consts/API'
import { environment } from '../environments/environment'
import { RequestSendInvoice } from '../interfaces/subscription'

const SERVER_URL = environment.API_WS_SERVER_URL

@Injectable({ providedIn: 'root' })
export class BillingInvoiceService {
  constructor(private http: HttpClient) {}

  exportInvoice(paymentIds: (string | number)[]): Observable<any> {
    let url = SERVER_URL + APIs.PAYMENT + '/export-pdf-invoice'
    return this.http.post(url, { paymentIds: paymentIds })
  }

  sendInvoice(body: RequestSendInvoice): Observable<any> {
    let url = SERVER_URL + APIs.INVOICE + '/sendMail'
    return this.http.post(url, body, {
      responseType: 'blob'
    })
  }

  getBillingInvoiceAdmin(offset: number, limit: number): Observable<any> {
    let url = SERVER_URL + APIs.BILLING_INVOICE_ADMIN + `?limit=${limit}&offset=${offset}`
    return this.http.get(url)
  }
}
