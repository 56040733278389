<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzTheme="light"
    [nzWidth]="width_sidebar + 'px'"
    [(nzCollapsed)]="isCollapsed"
    nzBreakpoint="lg"
    [nzCollapsedWidth]="0"
    [nzTrigger]=""
  >
    <div class="sidebar-logo">
      <a routerLink="{{ URL_PAGE.ACCOUNT_MANAGEMENT }}">
        <img src="assets/images/logo.png" alt="logo" class="logo_kingwork" />
      </a>
    </div>
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item routerLinkActive="ant-menu-item-selected">
        <i nz-icon nzType="menu:dashboard"></i>
        {{ TITLE_PAGE.DASHBOARD }}
      </li>
      <li
        nz-menu-item
        routerLink="{{ URL_PAGE.ACCOUNT_MANAGEMENT }}"
        routerLinkActive="ant-menu-item-selected"
      >
        <i nz-icon nzType="icon:user" class="icon-user"></i>
        {{ TITLE_PAGE.ACCOUNT_MANAGEMENT }}
      </li>
      <li
        nz-menu-item
        routerLink="{{ URL_PAGE.SUBSCRIPTION }}"
        routerLinkActive="ant-menu-item-selected"
      >
        <i nz-icon nzType="menu:card_tick"></i>
        {{ TITLE_PAGE.SUBSCRIPTION }}
      </li>
      <li
        nz-menu-item
        routerLink="{{ URL_PAGE.PAYMENT }}"
        routerLinkActive="ant-menu-item-selected"
      >
        <i nz-icon nzType="menu:card_tick"></i>
        {{ TITLE_PAGE.PAYMENTS }}
      </li>
      <li
        nz-menu-item
        routerLink="{{ URL_PAGE.BILLING_INVOICE }}"
        routerLinkActive="ant-menu-item-selected"
      >
        <i nz-icon nzType="menu:receipt"></i>
        {{ TITLE_PAGE.BILLING_INVOICING }}
      </li>
      <li
        nz-menu-item
        routerLinkActive="ant-menu-item-selected"
        routerLink="{{ URL_PAGE.HELP_CENTER }}"
      >
        <i nz-icon nzType="menu:book"></i>
        {{ TITLE_PAGE.HELP_CENTER }}
      </li>
    </ul>
    <div
      class="menu_footer"
      [style.width]="width_sidebar - 16 + 'px'"
      [style.display]="isCollapsed ? 'none' : 'block'"
    >
      <button
        nz-button
        nzType="default"
        nzSize="large"
        nzShape="round"
        class="menu_footer--btn_switch"
      >
        <span nz-icon nzType="menu:switch_horizontal"></span>
        <a href="https://{{ domainTenant }}.kingwork.vn" target="_blank"> Switch To HRM </a>
      </button>
      <nz-divider></nz-divider>
      <a class="menu_footer--logout" (click)="modalConfirm.showModal()">
        <div>Log out</div>
        <i nz-icon nzType="menu:logout"></i>
      </a>
    </div>
  </nz-sider>
  <nz-layout class="right-layout">
    <nz-header class="layout-header app-header">
      <div>
        <nz-breadcrumb [nzSeparator]="iconBreadcrumb" [nzAutoGenerate]="true"></nz-breadcrumb>
        <ng-template #iconBreadcrumb>
          <i nz-icon nzType="header:arrow_right" nzTheme="outline"></i>
        </ng-template>
      </div>

      <!-- menu login profile -->
      <div class="avatar-profile" nz-dropdown [nzDropdownMenu]="menu">
        <nz-avatar nzIcon="user" nzSrc="assets/images/Vector.png" [nzSize]="38"></nz-avatar>
        <div class="name-tenant">
          {{ userName }}
        </div>
        <i nz-icon nzType="down" class="icon-down-profile"></i>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu style="width: 250px">
            <li nz-menu-item (click)="showProfileTenant()">
              <span nz-icon nzType="profile" nzTheme="outline"></span>
              <div>My profile</div>
            </li>
            <li nz-menu-item [routerLink]="URL_PAGE.FORGOT_PASSWORD">
              <span nz-icon nzType="user-switch" nzTheme="outline"></span>Change password
            </li>
            <li nz-menu-item [routerLink]="URL_PAGE.MANAGE_SUBSCRIPTION">
              <span nz-icon nzType="swap" nzTheme="outline"></span>Management subscription
            </li>
            <li nz-menu-item (click)="modalConfirm.showModal()">
              <span nz-icon nzType="logout" nzTheme="outline"></span>
              <div>Logout</div>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<app-modal-confirm
  title="Do you want to Log out?"
  okText="Log Out"
  iconType="custom:logout_circle"
  (onOk)="logOutUser()"
></app-modal-confirm>

<app-profile
  titleHeader="Business Profile"
  [userDetail]="userDetail"
  [btnProfileTpl]="btnLogoutProfile"
  [visibleDrawer]="visibleProfile"
  [goEditUser]="goEditUser"
  [userName]="userDetail ? userDetail.username : ''"
  (cancel)="visibleProfile = $event"
>
  <ng-container content-profile>
    <div class="info-list">
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">First Name</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.firstName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Last Name</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.lastName }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">User Name</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.username }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Work Email</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.email }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Phone Number</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.phoneNumber }}</div>
      </div>
    </div>
    <nz-divider class="divider"></nz-divider>
    <div class="info-list">
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Work Email</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.email }}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="10" class="info-list-title">Work Email</div>
        <div nz-col class="info-list-content" nzSpan="14">{{ userDetail.email }}</div>
      </div>
    </div>
  </ng-container>
</app-profile>

<ng-template #btnLogoutProfile
  ><button nz-button>
    <span nz-icon nzType="logout" nzTheme="outline"></span>Logout
  </button></ng-template
>
