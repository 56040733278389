import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { URL_PAGE } from '../../../commons/consts'
import { BillingInvoiceComponent } from './billing-invoice.component'

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: URL_PAGE.BILLING_INVOICE },
  {
    path: '',
    component: BillingInvoiceComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingInvoiceRoutingModule {}
