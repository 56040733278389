import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PaymentService } from '../../../../api/payment.service'
import { URL_PAGE } from '../../../../commons/consts'
import { PAYMENT_STATUS, TITLE_PAGE } from '../../../../commons/enums/common.enum'
import { HttpObservablesService } from '../../../../commons/services/connection/http-observables.service'

@Component({
  selector: 'app-payment-complete',
  templateUrl: './payment-complete.component.html',
  styleUrls: ['./payment-complete.component.scss']
})
export class PaymentCompleteComponent implements OnInit {
  URL_PAGE = URL_PAGE
  PAYMENT_STATUS = PAYMENT_STATUS
  paymentDetail: any
  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private httpObservable: HttpObservablesService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getPaymentDetail(params['id'])
    })
  }

  getPaymentDetail(id: string) {
    return this.paymentService.getPaymentDetail(id).subscribe(
      (res) => {
        return (this.paymentDetail = res.data)
      },
      (err: { error: { message: string } }) => {
        this.httpObservable.handleError({ error: err.error, title: TITLE_PAGE.PAYMENT_DETAIL })
      }
    )
  }
}
