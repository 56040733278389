<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="titleDrawer"
  (nzOnClose)="handleCancel()"
  [nzWidth]="width"
  [nzFooter]="visibleFooter ? footerTpl : ''"
>
  <ng-container *nzDrawerContent>
    <ng-content select="[drawerContent]"></ng-content>
  </ng-container>
</nz-drawer>

<ng-template #titleDrawer>
  <div nz-row>
    <div class="container-title" nz-col nzSpan="14">
      <i class="icon-arrow-right" (click)="handleCancel()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581"
            stroke="#252937"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.91699 10H16.942"
            stroke="#252937"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
      <h2 class="title">{{ titleHeader }}</h2>
    </div>
    <div nz-col nzSpan="10">
      <ng-content select="[drawerBtnHeader]"></ng-content>
    </div>
  </div>
</ng-template>
<ng-template #footerTpl>
  <div class="group-button">
    <ng-content select="[drawerBtn]"></ng-content>
  </div>
</ng-template>
