<app-drawer
  [titleHeader]="titleHeader"
  [visible]="visible"
  (cancel)="handleCancel()"
  [visibleFooter]="false"
>
  <ng-container drawerContent *ngIf="paymentDetail">
    <app-heading-page
      [title]="numberNoComma(paymentDetail.id)"
      subtitle="INVOICE NUMBER"
      containerStyle="flex-direction: column-reverse"
      titleStyle="fontSize: 24px"
      subtitleStyle="fontSize: 12px"
    ></app-heading-page>

    <nz-divider [nzDashed]="true"></nz-divider>

    <div class="container-content">
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Invoice Date</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.startDate | date : formatDate }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Billed From</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">Innotech Viet Nam</div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Billed To</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.customers.companyName }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Billing Address</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.customers.address }}
        </div>
      </div>
    </div>

    <nz-divider [nzDashed]="true"></nz-divider>

    <div class="container-content">
      <h4 class="heading">Order Detail</h4>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Billing Period</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.startDate | date : formatDate }}
          <span nz-icon nzType="swap-right" nzTheme="outline"></span>
          {{ paymentDetail.endDate | date : formatDate }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Period Plan</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.period | titlecase }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Package</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.packageHistory.name }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Price</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.packageHistory.userPrice | currency : 'VND' }} each user/month
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="rowLayout.colTitle" class="title">Number User</div>
        <div nz-col [nzSpan]="rowLayout.colDescription" class="description">
          {{ paymentDetail.totalUser }}
        </div>
      </div>
    </div>

    <nz-divider [nzDashed]="true"></nz-divider>

    <div class="container-content">
      <div nz-row>
        <div nz-col [nzSpan]="12" class="title">Subtotal</div>
        <div nz-col [nzSpan]="12" class="description">
          {{ paymentDetail.amount | currency : 'VND' }}
        </div>
      </div>
      <div nz-row>
        <div nz-col [nzSpan]="12" class="title">Discount</div>
        <div nz-col [nzSpan]="12" class="description">--</div>
      </div>
    </div>

    <nz-divider [nzDashed]="true"></nz-divider>

    <div class="container-content">
      <div nz-row>
        <div nz-col [nzSpan]="12" class="title">Total</div>
        <div nz-col [nzSpan]="12" class="description value-total">
          {{ paymentDetail.amount | currency : 'VND' }}
        </div>
      </div>
    </div>
  </ng-container>
</app-drawer>
